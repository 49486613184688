import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";

interface SelectSomethingProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
  title: string;
  values: Record<string, string>;
}

const SelectSomething: React.FC<SelectSomethingProps> = ({ color, value, setValue, title, values, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return Object.entries(values)
      .sort(([_, a], [__, b]) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .filter(([_, value]) => (searchQuery ? value.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true));
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={title} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectSomething;
