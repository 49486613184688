import { ReactCodeMirrorRef } from "@uiw/react-codemirror";

export const insertText = ({ state, view }: ReactCodeMirrorRef, text: string) => {
  if (state == null) {
    return;
  }

  if (view == null) {
    return;
  }

  view.dispatch({
    changes: {
      from: view.state.selection.main.from,
      to: view.state.selection.main.to,
      insert: text,
    },
    selection: {
      anchor: view.state.selection.main.from + text.length,
    },
  });

  view.focus();
};
