import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";

type NpcIndicator = "none" | "information" | "quest" | "quest_complete" | "vendor" | "crafting" | "bank" | "pawnbroker";

const npcIndicators: Record<NpcIndicator, string> = {
  none: "none",
  information: "information",
  quest: "quest",
  quest_complete: "quest complete",
  vendor: "vendor",
  crafting: "crafting",
  bank: "bank",
  pawnbroker: "pawnbroker",
};

interface SelectNpcIndicatorProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
  showLabel?: boolean;
}

const SelectNpcIndicator: React.FC<SelectNpcIndicatorProps> = ({
  color,
  value,
  setValue,
  showLabel,
  ...chakraProps
}) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return Object.entries(npcIndicators)
      .filter(([_, displayName]) =>
        searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
      )
      .sort(([_, a], [__, b]) => a.localeCompare(b));
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"NPC Indicator"}
        showLabel={showLabel}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectNpcIndicator;
