import React, { memo, useCallback, useEffect } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";

interface NodeData {
  continueCondition: string;
}

interface JoinNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeId: string;
  nodeData: NodeData;
  color?: string;
}

export const JoinNodeConfigurationModal: React.FC<JoinNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  nodeId,
  nodeData,
  color,
}) => {
  const continueCondition = nodeData?.continueCondition ?? "any";

  const { register, reset, handleSubmit } = useForm<NodeData>({
    defaultValues: {
      continueCondition,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ continueCondition }: NodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.continueCondition = continueCondition;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });

      onClose();
    },
    [onClose, reactFlow, nodeId]
  );

  useEffect(() => {
    reset({
      continueCondition,
    });
  }, [continueCondition]);

  const handleCancel = useCallback(() => {
    reset({
      continueCondition,
    });

    onClose();
  }, [onClose, reset, continueCondition]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Continue if...
                </Text>
              </FormLabel>
              <Select
                id={"continueCondition"}
                color={color}
                textTransform={"uppercase"}
                placeholder={"select continue condition"}
                {...register("continueCondition", { required: true })}
              >
                <option key={"any"} value={"any"}>
                  At Least One Split Flow Completes
                </option>
                <option key={"all"} value={"all"}>
                  All Split Flows Complete
                </option>
                <option key={"one"} value={"one"}>
                  One Split Flow Completes and Terminate Other Split Flows
                </option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const JoinNode: React.FC<NodeProps<NodeType>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  return (
    <FlowNodeWithChildren {...props}>
      <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
        Configure
      </Button>

      <JoinNodeConfigurationModal
        isOpen={isOpen}
        onClose={onClose}
        nodeId={nodeId}
        nodeData={nodeData as NodeData}
        color={color}
      />
    </FlowNodeWithChildren>
  );
};

export default memo(JoinNode);
