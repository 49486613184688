import { AssetsApi, EquipmentSlotWithFilter } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getEquipmentSlots() {
  try {
    const { data } = await assetsApi.getEquipmentSlots();

    // ignore type issue
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const equipmentSlots = Object.values<EquipmentSlotWithFilter[]>(data as any).flat();

    return equipmentSlots.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
