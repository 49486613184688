import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useRooms from "../../hooks/rooms/useRooms";

interface SelectRoomProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectRoom: React.FC<SelectRoomProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, rooms = [] } = useRooms();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return rooms
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ worldId, displayName }) => [worldId, `${displayName} (${worldId})`]);
    },
    [rooms]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Room"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectRoom;
