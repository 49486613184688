import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { PiEyeClosed } from "react-icons/pi";

export const scribbleBlink: Command = {
  category: "effect",
  icon: <Icon as={PiEyeClosed} />,
  name: "blink",
  tooltip: "tag with blink effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#blink}}", "{{/blink}}"),
};
