import { useQuery } from "@tanstack/react-query";
import { Tutorial } from "@worldwidewebb/client-assets";
import { getTutorials } from "../../api/tutorial/tutorial";

export default function useTutorial() {
  const {
    isLoading,
    isFetching,
    error,
    data: tutorials,
  } = useQuery<Tutorial[]>({
    queryKey: ["tutorial"],
    queryFn: () => getTutorials(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    tutorials,
  };
}
