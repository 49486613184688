import { memo } from "react";
import { Stack } from "@chakra-ui/react";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { InputError } from "../../base/InputError";
import SelectMapEntity from "../../base/SelectMapEntity";

export interface MapEntity extends NodeData {
  entityId: string;
  worldId: string;
}

function MapEntityNode(props: NodeProps<NodeType<MapEntity>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const { updateNodeData } = useUpdateNodeData<MapEntity>(nodeId);

  const entityId = nodeData?.entityId;

  return (
    <BaseNodeWithChildren {...props}>
      <Stack p={2}>
        <SelectMapEntity color={color} value={nodeData} setValue={updateNodeData} />

        {!entityId && <InputError errorMessage="Please select an entity" />}
      </Stack>
    </BaseNodeWithChildren>
  );
}

export default memo(MapEntityNode);
