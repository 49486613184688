import { Connection, HandleType, Node, useReactFlow } from "reactflow";
import { useCallback } from "react";
import { NodeHandle, NodeType, SourceHandle, TargetHandle } from "../models/nodeType";

const useNodeHandlesValidation = () => {
  const { getNode, getNodes, getEdges } = useReactFlow<NodeType>();

  const isValidConnectionsAtLimit = useCallback(
    ({ handleId, handleType, handleCategory }: NodeHandle) => {
      const edges = getEdges();

      const [edge] = edges.filter(
        (edge) => edge?.data?.isDragging === true && (edge.targetHandle === handleId || edge.sourceHandle === handleId)
      );

      if (edge != null) {
        return false;
      }

      if (handleType === "source") {
        const sourceHandleConnectionCount = edges.filter(
          ({ sourceHandle: sourceHandleId }) => sourceHandleId === handleId
        ).length;

        if (handleCategory === "data") {
          return false;
        }

        if (handleCategory === "questId") {
          return false;
        }

        if (handleCategory === "taskId") {
          return false;
        }

        if (handleCategory === "vendorId") {
          return false;
        }

        if (handleCategory === "entity") {
          return false;
        }

        if (handleCategory === "soundId") {
          return false;
        }

        if (handleCategory === "craftingStationId") {
          return false;
        }

        if (handleCategory === "playerActionAndRequirement") {
          return false;
        }

        return sourceHandleConnectionCount === 1;
      }

      if (handleType === "target") {
        const targetHandleConnectionCount = edges.filter(
          ({ targetHandle: targetHandleId }) => targetHandleId === handleId
        ).length;

        return targetHandleConnectionCount === 1;
      }

      return true;
    },
    [getEdges]
  );

  const isValidConnectionType = useCallback(
    (
      { handleCategory: sourceHandleCategory, handleName: sourceHandleName }: SourceHandle,
      { handleCategory: targetHandleCategory, handleName: targetHandleName }: TargetHandle
    ) => {
      if (sourceHandleName === targetHandleName) {
        return true;
      }

      if (sourceHandleCategory === "start") {
        switch (targetHandleCategory) {
          case "flow": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "flow") {
        switch (targetHandleCategory) {
          case "end":
          case "flow": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "data") {
        switch (targetHandleCategory) {
          case "data": {
            // exceptions
            const isAddHandleConnection = sourceHandleName === "number" && targetHandleName === "add";
            const isSubHandleConnection = sourceHandleName === "number" && targetHandleName === "sub";
            const isMulHandleConnection = sourceHandleName === "number" && targetHandleName === "mul";
            const isDivHandleConnection = sourceHandleName === "number" && targetHandleName === "div";

            // cleanup later
            const isCurrentCountConnection =
              sourceHandleName === "currentCount" &&
              (targetHandleName === "number" ||
                targetHandleName === "add" ||
                targetHandleName === "sub" ||
                targetHandleName === "mul" ||
                targetHandleName === "div");

            // cleanup later
            const isRequiredCountConnection =
              sourceHandleName === "requiredCount" &&
              (targetHandleName === "number" ||
                targetHandleName === "add" ||
                targetHandleName === "sub" ||
                targetHandleName === "mul" ||
                targetHandleName === "div");

            const isNpcTargetConnection = sourceHandleName === "npc" && targetHandleName === "npc_target";

            return (
              isAddHandleConnection ||
              isSubHandleConnection ||
              isMulHandleConnection ||
              isDivHandleConnection ||
              isNpcTargetConnection ||
              isCurrentCountConnection ||
              isRequiredCountConnection ||
              sourceHandleName === targetHandleName
            );
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "questId") {
        switch (targetHandleCategory) {
          case "questId": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "taskId") {
        switch (targetHandleCategory) {
          case "taskId": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "vendorId") {
        switch (targetHandleCategory) {
          case "vendorId": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "entity") {
        switch (targetHandleCategory) {
          case "entity": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "soundId") {
        switch (targetHandleCategory) {
          case "soundId": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "craftingStationId") {
        switch (targetHandleCategory) {
          case "craftingStationId": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      if (sourceHandleCategory === "playerActionAndRequirement") {
        switch (targetHandleCategory) {
          case "playerActionAndRequirement": {
            return true;
          }
          default: {
            return false;
          }
        }
      }

      return false;
    },
    []
  );

  const isValidConnection = useCallback(
    ({
      source: sourceNodeId,
      sourceHandle: sourceHandleId,
      target: targetNodeId,
      targetHandle: targetHandleId,
    }: Connection) => {
      if (sourceNodeId == null || sourceHandleId == null || targetNodeId == null || targetHandleId == null) {
        return false;
      }

      const sourceNode = getNode(sourceNodeId);
      const targetNode = getNode(targetNodeId);

      if (sourceNode == null || targetNode == null) {
        return false;
      }

      const sourceHandle = sourceNode.data.sourceHandles?.find(({ handleId }) => handleId === sourceHandleId);
      const targetHandle = targetNode.data.targetHandles?.find(({ handleId }) => handleId === targetHandleId);

      if (sourceHandle == null || targetHandle == null) {
        return false;
      }

      if (isValidConnectionsAtLimit(sourceHandle)) {
        return false;
      }

      if (isValidConnectionsAtLimit(targetHandle)) {
        return false;
      }

      return isValidConnectionType(sourceHandle, targetHandle);
    },
    [getNode, isValidConnectionsAtLimit, isValidConnectionType]
  );

  const getValidConnectionHandles = useCallback(
    (
      nodeId: string | null,
      initiatingConnectionHandleId: string | null,
      initiatingConnectionHandleType: HandleType | null
    ) => {
      const validConnectionHandles: NodeHandle[] = [];

      if (nodeId == null || initiatingConnectionHandleId == null || initiatingConnectionHandleType == null) {
        return validConnectionHandles;
      }

      const nodes = getNodes();
      const node = getNode(nodeId);

      if (node == null) {
        return validConnectionHandles;
      }

      let initiatingConnectionNode: Node<NodeType> | undefined = undefined;
      let initiatingConnectionNodeHandle: SourceHandle | TargetHandle | undefined = undefined;

      if (initiatingConnectionHandleType === "source") {
        initiatingConnectionNode = nodes.find(
          ({ data: { sourceHandles } }) =>
            sourceHandles?.find(({ handleId }) => handleId === initiatingConnectionHandleId) != null
        );

        initiatingConnectionNodeHandle = initiatingConnectionNode?.data.sourceHandles?.find(
          ({ handleId }) => handleId === initiatingConnectionHandleId
        );
      }

      if (initiatingConnectionHandleType === "target") {
        initiatingConnectionNode = nodes.find(
          ({ data: { targetHandles } }) =>
            targetHandles?.find(({ handleId }) => handleId === initiatingConnectionHandleId) != null
        );

        initiatingConnectionNodeHandle = initiatingConnectionNode?.data.targetHandles?.find(
          ({ handleId }) => handleId === initiatingConnectionHandleId
        );
      }

      if (initiatingConnectionNodeHandle == null) {
        return validConnectionHandles;
      }

      if (isValidConnectionsAtLimit(initiatingConnectionNodeHandle)) {
        return validConnectionHandles;
      }

      if (initiatingConnectionHandleType === "source") {
        if (node.data.sourceHandles?.find(({ handleId }) => handleId === initiatingConnectionHandleId) != null) {
          validConnectionHandles.push(initiatingConnectionNodeHandle);
        }
      }

      if (initiatingConnectionHandleType === "target") {
        if (node.data.targetHandles?.find(({ handleId }) => handleId === initiatingConnectionHandleId) != null) {
          validConnectionHandles.push(initiatingConnectionNodeHandle);
        }
      }

      if (initiatingConnectionHandleType === "source") {
        node.data.targetHandles?.forEach((targetHandle) => {
          if (
            isValidConnection({
              source: initiatingConnectionNode?.id ?? null,
              sourceHandle: initiatingConnectionHandleId,
              target: nodeId,
              targetHandle: targetHandle.handleId ?? null,
            })
          ) {
            validConnectionHandles.push(targetHandle);
          }
        });
      }

      if (initiatingConnectionHandleType === "target") {
        node.data.sourceHandles?.forEach((sourceHandle) => {
          if (
            isValidConnection({
              source: nodeId,
              sourceHandle: sourceHandle.handleId ?? null,
              target: initiatingConnectionNode?.id ?? null,
              targetHandle: initiatingConnectionHandleId,
            })
          ) {
            validConnectionHandles.push(sourceHandle);
          }
        });
      }

      return validConnectionHandles;
    },
    [getNodes, getNode, isValidConnection, isValidConnectionsAtLimit]
  );

  return {
    isValidConnection,
    isValidConnectionsAtLimit,
    getValidConnectionHandles,
  };
};

export default useNodeHandlesValidation;
