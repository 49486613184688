import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { TbWheel } from "react-icons/tb";

export const scribbleWheel: Command = {
  category: "effect",
  icon: <Icon as={TbWheel} />,
  name: "wheel",
  tooltip: "tag with wheel effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#wheel}}", "{{/wheel}}"),
};
