import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { EditorSelection } from "@codemirror/state";

export const insertSurroundingText = ({ state, view }: ReactCodeMirrorRef, beginText: string, endText: string) => {
  if (state == null) {
    return;
  }

  if (view == null) {
    return;
  }

  view.dispatch(
    view.state.changeByRange(({ from, to }) => ({
      changes: [
        { from, insert: beginText },
        { from: to, insert: endText },
      ],
      range: EditorSelection.range(from + beginText.length, to + endText.length),
    }))
  );

  view.focus();
};
