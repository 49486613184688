import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Checkbox,
  Heading,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useStoreApi } from "reactflow";
import { decodeTime } from "ulid";
import { QuestData, QuestWithId } from "../models/api/quest";
import {
  differenceInMinutes as calculateDifferenceInMinutes,
  differenceInHours as calculateDifferenceInHours,
} from "date-fns";
import { db } from "../db";
import { createMixpanelAnnotationIfOnProd } from "../api/mixpanel/mixpanel";
import useUpdateQuestMetaDataAndNodeData from "../hooks/quests/useUpdateQuestMetaDataAndNodeData";
import { getQuest } from "../api/quests/quests";

interface Quest {
  quest: QuestWithId | undefined;
  saveQuest: (
    questId: string,
    questData: QuestData,
    questPartial?: Partial<QuestWithId>,
    onSuccess?: () => void
  ) => void;
  loadQuest: (quest: QuestWithId) => void;
  isUpdating: boolean;
}

const QuestContext = createContext<Quest | null>(null);

interface QuestProviderProps extends PropsWithChildren {
  questId: string;
}

export function QuestProvider({ questId, children }: QuestProviderProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [latestQuest, setLatestQuest] = useState<QuestWithId | undefined>(undefined);
  const [remoteQuest, setRemoteQuest] = useState<QuestWithId | undefined>(undefined);

  const [timeDifference, setTimeDifference] = useState<string>("");

  const handleDiscardLatestQuest = useCallback(() => {
    if (remoteQuest == null) {
      return;
    }

    setLatestQuest(remoteQuest);

    storeLatestQuest(questId, remoteQuest).then(onClose);
  }, [remoteQuest, onClose]);

  const { isUpdating, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const saveQuest = useCallback(
    (questId: string, questData: QuestData, questPartial?: Partial<QuestWithId>, onSuccess?: () => void) => {
      updateQuestMetaDataAndNodeData(
        {
          questId,
          questData,
          partialQuest: { ...questPartial },
        },
        {
          onSuccess: (quest) => {
            setLatestQuest(quest);
            setRemoteQuest(quest);

            createMixpanelAnnotationIfOnProd(quest.deployVersion ?? "?.?.?", quest.name).catch((error) => {
              toast({
                title: "Creating of annotation unsuccessful",
                description: (error as Error).message,
                status: "error",
              });
            });

            onSuccess?.();
          },
          onError: (error) => {
            toast({
              title: "Saving of quest unsuccessful",
              description: (error as Error).message,
              status: "error",
            });
          },
        }
      );
    },
    [updateQuestMetaDataAndNodeData]
  );

  const loadQuest = useCallback((quest: QuestWithId) => {
    setLatestQuest(quest);
  }, []);

  useEffect(() => {
    fetchRemoteQuest(questId).then((remoteQuest) => {
      if (remoteQuest == null) {
        return;
      }

      setRemoteQuest(remoteQuest);

      fetchLatestQuest(questId).then((quest) => {
        if (quest == null) {
          setLatestQuest(remoteQuest);
        } else {
          setLatestQuest({
            ...remoteQuest,
            version: quest.version,
            data: quest.data,
          });
        }
      });
    });
  }, [questId]);

  useEffect(() => {
    if (remoteQuest == null) {
      return;
    }

    if (latestQuest == null) {
      return;
    }

    const latestQuestVersion = latestQuest.version;
    const remoteQuestVersion = remoteQuest.version;

    let latestQuestVersionTime = 0;
    let remoteQuestVersionTime = 0;

    try {
      latestQuestVersionTime = decodeTime(latestQuestVersion);
      remoteQuestVersionTime = decodeTime(remoteQuestVersion);
    } catch (error) {
      console.error(error);
    }

    if (remoteQuestVersionTime <= latestQuestVersionTime) {
      return;
    }

    const differenceInHours = calculateDifferenceInHours(remoteQuestVersionTime, latestQuestVersionTime);
    const differenceInMinutes = calculateDifferenceInMinutes(remoteQuestVersionTime, latestQuestVersionTime) % 60;

    let timeDifference = `${differenceInMinutes} minutes`;

    if (differenceInHours) {
      timeDifference = `${differenceInHours} hours ${timeDifference}`;
    }

    setTimeDifference(timeDifference);

    onOpen();
  }, [latestQuest, remoteQuest, onOpen]);

  const store = useStoreApi();

  useEffect(() => {
    const interval = setInterval(() => {
      if (latestQuest == null) {
        return;
      }

      storeLatestQuest(questId, {
        ...latestQuest,
        // TODO: rename later
        data: {
          nodes: store.getState().getNodes(),
          edges: store.getState().edges,
        },
      });
    }, 30_000);

    return () => clearInterval(interval);
  }, [questId, latestQuest, store]);

  return (
    <>
      <QuestContext.Provider
        value={{
          quest: latestQuest ?? remoteQuest,
          saveQuest,
          loadQuest,
          isUpdating,
        }}
      >
        {children}
      </QuestContext.Provider>

      <DiscardLatestQuestAlert
        isOpen={isOpen}
        onClose={onClose}
        onDiscardLatestQuest={handleDiscardLatestQuest}
        timeDifference={timeDifference}
      />
    </>
  );
}

async function fetchLatestQuest(questId: string): Promise<QuestWithId | undefined> {
  try {
    return await db.quests.get(questId);
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

async function storeLatestQuest(questId: string, quest: QuestWithId) {
  try {
    await db.quests.put(quest, questId);
  } catch (error) {
    console.error(error);
  }
}

async function fetchRemoteQuest(questId: string): Promise<QuestWithId | undefined> {
  try {
    return await getQuest(questId);
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export function useQuestProvider() {
  const context = useContext(QuestContext);

  if (context == null) {
    throw new Error("useQuestProvider used outside of QuestProvider");
  }

  return context;
}

interface DiscardLatestQuestAlertProps {
  isOpen: boolean;
  onClose: () => void;
  onDiscardLatestQuest: () => void;
  timeDifference: string;
}

function DiscardLatestQuestAlert({
  isOpen,
  onClose,
  onDiscardLatestQuest,
  timeDifference,
}: DiscardLatestQuestAlertProps) {
  const [isUnderstood, setIsUnderstood] = useState<boolean>(false);

  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={leastDestructiveRef}>
      <AlertDialogOverlay />

      <AlertDialogContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <AlertDialogHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"} casing={"uppercase"}>
              Load cloud version?
            </Text>
          </Heading>
        </AlertDialogHeader>

        <AlertDialogBody>
          <Stack>
            <Text color={"white"} pb={2}>
              There is a cloud version that's {timeDifference} newer
            </Text>

            <Center pt={2}>
              <Checkbox isChecked={isUnderstood} onChange={({ target: { checked } }) => setIsUnderstood(checked)}>
                <Text color={"white"}>Overwrite my local version</Text>
              </Checkbox>
            </Center>
          </Stack>
        </AlertDialogBody>

        <AlertDialogFooter gap={2}>
          <Button ref={leastDestructiveRef} onClick={onClose} variant={"outline"}>
            No
          </Button>
          <Button onClick={onDiscardLatestQuest} variant={"outline"} disabled={!isUnderstood}>
            Yes
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
