import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { NodeProps, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { NodeType, SourceHandle } from "../../../models/nodeType";
import { FieldErrors, useFieldArray, useForm, UseFormRegister } from "react-hook-form";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { ulid } from "ulid";
import { MdClose, MdDragHandle } from "react-icons/md";
import { useDnD } from "../../../hooks/useDnD";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { InputError } from "../../base/InputError";
import { DialogChoiceWithId } from "@worldwidewebb/shared-messages/quests";
import { FlowNodeWithChildren } from "./FlowNode";

interface FormData {
  prompt: string;
  choices: DialogChoiceWithId[];
}

interface ChoiceComponentProps {
  index: number;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
  color?: string;
}

const ChoiceComponent: React.FC<ChoiceComponentProps> = ({
  index,
  updateIndex,
  removeIndex,
  register,
  errors,
  color,
}) => {
  const { handlerId, isDragging, ref, refPreview } = useDnD("choices", index, updateIndex);

  let fieldErrors = undefined;

  if (errors?.choices?.at) {
    fieldErrors = errors.choices.at(index);
  }

  return (
    <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
      <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
        <Icon as={MdDragHandle} />
      </Center>
      <Stack flexGrow={1}>
        <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
          <IconButton
            size={"xs"}
            color={"white"}
            variant={"ghost"}
            icon={<Icon as={MdClose} />}
            aria-label={"delete choice"}
            onClick={() => removeIndex(index)}
          />
        </Flex>
        <Stack p={2}>
          <FormControl>
            <Input
              id={`choices.${index}.choice`}
              placeholder={"choice text"}
              {...register(`choices.${index}.choice`, {
                required: "This is required",
              })}
            />
            {fieldErrors?.choice && <InputError errorMessage={fieldErrors?.choice.message} />}
          </FormControl>
        </Stack>
      </Stack>
    </Flex>
  );
};

interface AiDecisionNodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  prompt: string;
  onUpdatePrompt: (prompt: string) => void;
  choices: DialogChoiceWithId[];
  onUpdateChoices: (choices: DialogChoiceWithId[]) => void;
  color?: string;
}

export const AiDecisionNodeModal: React.FC<AiDecisionNodeModalProps> = ({
  isOpen,
  onClose,
  prompt,
  onUpdatePrompt,
  choices,
  onUpdateChoices,
  color,
}) => {
  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        prompt,
        choices,
      }),
      [prompt, choices]
    ),
    mode: "onBlur",
  });

  useEffect(() => {
    reset({
      prompt,
      choices,
    });
  }, [prompt, choices]);

  const {
    fields: choiceFields,
    insert: insertChoice,
    remove: removeChoice,
    move: moveChoice,
  } = useFieldArray({
    name: "choices",
    control,
  });

  const handleUpdate = useCallback(
    ({ prompt, choices }: FormData) => {
      onUpdatePrompt(prompt);
      onUpdateChoices(choices);

      onClose();
    },
    [onClose, onUpdatePrompt, onUpdateChoices]
  );

  const handleInsertChoice = useCallback(
    (index: number) => {
      insertChoice(index, {
        choiceId: ulid(),
        choice: "",
      });
    },
    [insertChoice]
  );

  const handleRemoveChoice = useCallback(
    (index: number) => {
      removeChoice(index);
    },
    [removeChoice]
  );

  const handleMoveChoice = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveChoice(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveChoice]
  );

  const handleCancel = useCallback(() => {
    reset({
      prompt,
      choices,
    });

    onClose();
  }, [onClose, reset, prompt, choices]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Prompt
                </Text>
              </FormLabel>
              <Textarea {...register("prompt")} color={color} />
            </FormControl>

            <Stack>
              <Text casing={"uppercase"} color={color}>
                Choices
              </Text>
              <Stack>
                {choiceFields && choiceFields.length === 0 ? (
                  <Center>
                    <Text color={"white"}>No user choices (insert new)</Text>
                  </Center>
                ) : (
                  choiceFields.map(({ choiceId }, index) => (
                    <ChoiceComponent
                      key={choiceId}
                      index={index}
                      updateIndex={handleMoveChoice}
                      removeIndex={handleRemoveChoice}
                      register={register}
                      errors={errors}
                      color={color}
                    />
                  ))
                )}
              </Stack>

              <Flex justifyContent={"flex-end"}>
                <Button onClick={() => handleInsertChoice(choiceFields.length)} variant={"outline"}>
                  <Text color={color} textTransform={"uppercase"}>
                    Insert New
                  </Text>
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const AiDecisionNode: React.FC<NodeProps<NodeType>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    data: { color, nodeData, sourceHandles },
  } = props;

  const formData = nodeData as FormData | undefined;
  const prompt = formData?.prompt ?? "";
  const choices = formData?.choices ?? [];

  const reactFlow = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdateChoices = useCallback(
    (choices: DialogChoiceWithId[]) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.choices = choices;

        const nodeSources = nodeDataCloned.sourceHandles ?? [];

        const sources: SourceHandle[] = choices.map(({ choiceId }, index) => {
          const source = nodeSources.find(({ linkedNodeDataId }) => linkedNodeDataId === choiceId);

          if (source != null) {
            return source;
          }

          return {
            label: index.toString(),
            handleName: index.toString(),
            handleType: "source",
            handleCategory: "flow",
            linkedNodeDataId: choiceId,
          };
        });

        sources.forEach((source, index) => {
          source.label = index.toString();
          source.handleName = index.toString();

          if (nodeSources.at(index)?.handleName === "out") {
            source.handleId = nodeSources.at(index)?.handleId;
          }
        });

        if (sources.length === 0) {
          const [nodeSource] = nodeSources;

          sources.push({
            ...nodeSource,
            label: "OUT",
            handleName: "out",
            linkedNodeDataId: undefined,
          });
        }

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        updateNodeSourceHandles(sources);

        return nodes;
      });

      updateNodeInternals(nodeId);
    },
    [reactFlow, nodeId, updateNodeInternals, updateNodeSourceHandles]
  );

  const handleUpdatePrompt = useCallback(
    (prompt: string) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.prompt = prompt;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  useEffect(() => {
    if (sourceHandles?.length) {
      return;
    }

    updateNodeSourceHandles([
      {
        label: "OUT",
        handleName: "out",
        handleType: "source",
        handleCategory: "flow",
      },
    ]);
  }, [sourceHandles]);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <Stack>
          <Button w={"100%"} onClick={onOpen} variant={"outline"}>
            <Text color={color} textTransform={"uppercase"}>
              Configure
            </Text>
          </Button>
        </Stack>
      </FlowNodeWithChildren>

      <AiDecisionNodeModal
        isOpen={isOpen}
        onClose={onClose}
        prompt={prompt}
        onUpdatePrompt={handleUpdatePrompt}
        choices={choices}
        onUpdateChoices={handleUpdateChoices}
        color={color}
      />
    </>
  );
};

export default memo(AiDecisionNode);
