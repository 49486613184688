import React, { memo, useMemo } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import SelectGui from "../../base/SelectGui";
import { FlowNodeWithChildren } from "./FlowNode";

interface OpenUIElement extends NodeData {
  uiElement: string;
}

const OpenUIElementNode: React.FC<NodeProps<NodeType<OpenUIElement>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const uiElement = nodeData?.uiElement ?? "";

  const { getValues, setValue, handleSubmit } = useForm<OpenUIElement>({
    defaultValues: useMemo(
      () => ({
        uiElement,
      }),
      [uiElement]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<OpenUIElement>(nodeId);

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
        <SelectGui color={color} value={getValues("uiElement")} setValue={(value) => setValue("uiElement", value)} />
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(OpenUIElementNode);
