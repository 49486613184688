import { useQueryClient } from "@tanstack/react-query";
import RefreshButton, { RefreshButtonProps } from "../../ui/base/RefreshButton";

export interface QuestRefreshButtonProps extends Omit<RefreshButtonProps, "resourceName"> {
  questId: string;
}

function QuestRefreshButton({ questId, ...refreshButtonProps }: QuestRefreshButtonProps) {
  const queryClient = useQueryClient();

  function handleRefresh() {
    queryClient.invalidateQueries({
      queryKey: ["quest", questId],
    });
  }

  return <RefreshButton onClick={handleRefresh} resourceName={"quest"} {...refreshButtonProps} />;
}

export default QuestRefreshButton;
