import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { NodeType } from "../models/nodeType";
import { NodeTypeListItem } from "./NodeTypeListItem";

interface NodeTypeListProps {
  nodeTypes: NodeType[];
  hasSearchValue: boolean;
}

export const NodeTypeList: React.FC<NodeTypeListProps> = ({ nodeTypes, hasSearchValue }) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const nodeTypesGroupedByCategory = useMemo<Record<string, NodeType[]>>(
    () =>
      nodeTypes.reduce((nodeTypesGroupedByCategory: Record<string, NodeType[]>, nodeType: NodeType) => {
        const { nodeCategory } = nodeType;

        if (nodeCategory == null) {
          return nodeTypesGroupedByCategory;
        }

        nodeTypesGroupedByCategory[nodeCategory] ??= [];
        nodeTypesGroupedByCategory[nodeCategory].push(nodeType);

        return nodeTypesGroupedByCategory;
      }, {}),
    [nodeTypes]
  );

  const nodeTypeCategoryEntries = Object.entries(nodeTypesGroupedByCategory);

  const handleCollapseAll = useCallback(() => {
    setOpenIndexes([]);
  }, [nodeTypeCategoryEntries]);

  const handleExpandAll = useCallback(() => {
    setOpenIndexes(Array.from({ length: nodeTypeCategoryEntries.length }, (_, index) => index));
  }, [nodeTypeCategoryEntries]);

  const handleToggleOpenIndex = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes((openIndexes) => openIndexes.filter((openIndex) => openIndex !== index));
      } else {
        setOpenIndexes((openIndexes) => openIndexes.concat(index));
      }
    },
    [openIndexes]
  );

  useEffect(() => {
    if (hasSearchValue) {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
  }, [hasSearchValue]);

  return (
    <>
      <HStack>
        <Button flexGrow={1} bg={"indigo.600"} borderRadius={0} onClick={handleCollapseAll}>
          <Text color={"white"} casing={"uppercase"}>
            Collapse all
          </Text>
        </Button>
        <Button flexGrow={1} bg={"indigo.600"} borderRadius={0} onClick={handleExpandAll}>
          <Text color={"white"} casing={"uppercase"}>
            Expand all
          </Text>
        </Button>
      </HStack>

      <Stack
        spacing={0}
        sx={{
          "::-webkit-scrollbar": {
            width: 2,
          },
          "::-webkit-scrollbar-track": {
            bg: "mirage.900",
          },
          "::-webkit-scrollbar-thumb": {
            bg: "indigo.600",
          },
        }}
        overflowY={"auto"}
      >
        <Stack>
          <Accordion allowMultiple index={openIndexes}>
            {nodeTypeCategoryEntries &&
              nodeTypeCategoryEntries.map(([nodeCategory, nodeTypes], index) => (
                <AccordionItem key={nodeCategory} borderColor={"indigo.600"}>
                  <AccordionButton bg={"indigo.600"} onClick={() => handleToggleOpenIndex(index)}>
                    <Text casing={"uppercase"} color={"white"} fontWeight={500}>
                      {nodeCategory}
                    </Text>
                  </AccordionButton>
                  <AccordionPanel p={0}>
                    {nodeTypes &&
                      nodeTypes.map(({ label, color, nodeName, nodeDescription, isReady }, key) => (
                        <NodeTypeListItem
                          key={key}
                          label={label}
                          color={color}
                          nodeName={nodeName}
                          nodeDescription={nodeDescription}
                          isReady={isReady}
                        />
                      ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </Stack>
      </Stack>
    </>
  );
};
