import { memo, MutableRefObject, ReactElement, useRef, useState } from "react";
import { Menu, MenuButton, MenuList, Portal, useDisclosure, useEventListener } from "@chakra-ui/react";

interface ContextMenuProps {
  children: (ref: MutableRefObject<HTMLDivElement | null>) => ReactElement | null;
  renderMenu?: (x: number, y: number) => ReactElement | null;
}

function ContextMenu({ children, renderMenu }: ContextMenuProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [position, setPosition] = useState<[number, number]>([0, 0]);

  useEventListener("contextmenu", (event: MouseEvent) => {
    event.preventDefault();

    const { clientX, clientY, target } = event;

    if (!(target instanceof HTMLDivElement)) {
      return;
    }

    if (ref.current?.contains(target)) {
      setPosition([clientX, clientY]);

      onOpen();
    } else {
      onClose();
    }
  });

  const [x, y] = position;

  return (
    <>
      {children(ref)}

      {renderMenu && isOpen && (
        <Menu isOpen={isOpen} onClose={onClose}>
          <MenuButton position={"absolute"} left={x} top={y} aria-hidden={true} />

          <Portal>
            <MenuList py={0} bg={"whiteAlpha.200"} borderRadius={0} borderWidth={0}>
              {renderMenu(x, y)}
            </MenuList>
          </Portal>
        </Menu>
      )}
    </>
  );
}

export default memo(ContextMenu);
