import { Edge, Node, Position } from "reactflow";
import { EdgeType } from "../models/edgeType";
import { NodeType } from "../models/nodeType";
import dagre, { layout } from "@dagrejs/dagre";

const NODE_SEPARATION = 300;
const EDGE_SEPARATION = 300;
const RANK_SEPARATION = 200;

export type LayoutDirection = "TB" | "LR" | "RL" | "BT";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const positionMap: Record<string, Position> = {
  T: Position.Top,
  L: Position.Left,
  R: Position.Right,
  B: Position.Bottom,
};

export default function generateLayout(nodes: Node<NodeType>[], edges: Edge<EdgeType>[], direction: LayoutDirection) {
  if (nodes.length === 0) {
    return {
      updatedNodes: [],
      updatedEdges: [],
    };
  }

  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: NODE_SEPARATION,
    edgesep: EDGE_SEPARATION,
    ranksep: RANK_SEPARATION,
  });

  nodes.forEach(({ id, width, height }) => {
    dagreGraph.setNode(id, {
      width: width ?? 0,
      height: height ?? 0,
    });
  });

  edges.forEach(({ source, target }) => {
    dagreGraph.setEdge(source, target);
  });

  layout(dagreGraph);

  const updatedNodes = nodes.map((node) => {
    const { x, y } = dagreGraph.node(node.id);

    return {
      ...node,
      sourcePosition: positionMap[direction[1]],
      targetPosition: positionMap[direction[0]],
      position: { x, y },
      style: { opacity: 1 },
    };
  });

  const updatedEdges = edges.map((edge) => {
    return {
      ...edge,
      style: { opacity: 1 },
    };
  });

  return {
    updatedNodes,
    updatedEdges,
  };
}
