import { ChakraProps } from "@chakra-ui/react";
import { ItemNameAndQuantity } from "@worldwidewebb/quest-shared/dist/item";
import { memo, useMemo } from "react";
import { Edge, Node } from "reactflow";
import { QuestWithId } from "../../models/api/quest";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";
import ItemNameAndQuantityComponentGroup from "../items/ItemNameAndQuantityComponentGroup";

interface QuestOverviewProps extends ChakraProps {
  quest?: QuestWithId;
}

function QuestOverview({ quest }: QuestOverviewProps) {
  if (quest == null) {
    return null;
  }

  const { data: questData } = quest;

  if (questData == null) {
    return null;
  }

  const { nodes, edges } = questData;

  const requiredItems = useMemo(
    () =>
      aggregateItemNameAndQuantitySourceNodes(
        mapSourceNodesByConnectedTargetNodeHandleName(
          nodes,
          edges,
          "item_name_and_quantity",
          "create_quest_task",
          "item_name_and_quantity"
        )
      ),
    [nodes, edges]
  );

  const rewardedItems = useMemo(
    () =>
      aggregateItemNameAndQuantitySourceNodes(
        mapSourceNodesByConnectedTargetNodeHandleName(
          nodes,
          edges,
          "item_name_and_quantity",
          "create_quest_log",
          "item_name_and_quantity"
        )
      ),
    [nodes, edges]
  );

  const itemsGiven = useMemo(
    () =>
      aggregateItemNameAndQuantitySourceNodes(
        mapSourceNodesByConnectedTargetNodeHandleName(
          nodes,
          edges,
          "item_name_and_quantity",
          "give_item",
          "item_name_and_quantity"
        )
      ),
    [nodes, edges]
  );

  const itemsTaken = useMemo(
    () =>
      aggregateItemNameAndQuantitySourceNodes(
        mapSourceNodesByConnectedTargetNodeHandleName(
          nodes,
          edges,
          "item_name_and_quantity",
          "take_item",
          "item_name_and_quantity"
        )
      ),
    [nodes, edges]
  );

  const itemsSpawned = useMemo(
    () =>
      aggregateItemNameAndQuantitySourceNodes(
        mapSourceNodesByConnectedTargetNodeHandleName(
          nodes,
          edges,
          "item_name_and_quantity",
          "spawn_item",
          "item_name_and_quantity"
        )
      ),
    [nodes, edges]
  );

  return (
    <>
      <ItemNameAndQuantityComponentGroup color={"white"} itemNameAndQuantities={requiredItems}>
        Required Items
      </ItemNameAndQuantityComponentGroup>

      <ItemNameAndQuantityComponentGroup color={"white"} itemNameAndQuantities={rewardedItems}>
        Rewarded Items
      </ItemNameAndQuantityComponentGroup>

      <ItemNameAndQuantityComponentGroup color={"white"} itemNameAndQuantities={itemsGiven}>
        Items Given
      </ItemNameAndQuantityComponentGroup>

      <ItemNameAndQuantityComponentGroup color={"white"} itemNameAndQuantities={itemsTaken}>
        Items Taken
      </ItemNameAndQuantityComponentGroup>

      <ItemNameAndQuantityComponentGroup color={"white"} itemNameAndQuantities={itemsSpawned}>
        Items Spawned
      </ItemNameAndQuantityComponentGroup>
    </>
  );
}

// TODO: cleanup start

function mapSourceNodesByConnectedTargetNodeHandleName(
  nodes: Node<NodeType>[],
  edges: Edge<EdgeType>[],
  sourceNodeName: string,
  targetNodeName: string,
  targetHandleName: string
) {
  const targetNodeHandles = nodes
    .filter(({ data: { nodeName } }) => nodeName === targetNodeName)
    .flatMap(({ data: { targetHandles = [] } }) => targetHandles)
    .filter(({ handleName }) => handleName === targetHandleName);

  return targetNodeHandles.flatMap(({ handleId: targetHandleId }) =>
    nodes
      .filter(({ data: { nodeName } }) => nodeName === sourceNodeName)
      .filter(({ data: { sourceHandles = [] } }) =>
        sourceHandles.some(({ handleId: sourceHandleId }) =>
          edges
            .filter(({ sourceHandle }) => sourceHandle === sourceHandleId)
            .map(({ targetHandle }) => targetHandle)
            .includes(targetHandleId)
        )
      )
  );
}

function aggregateItemNameAndQuantitySourceNodes(nodes: Node<NodeType<ItemNameAndQuantity>>[]) {
  return nodes
    .map(({ data: { nodeData } }) => nodeData)
    .reduce((itemNameAndQuantityDictionary: Record<string, number>, { itemName, quantity }) => {
      itemNameAndQuantityDictionary[itemName] ??= 0;
      itemNameAndQuantityDictionary[itemName] += quantity;

      return itemNameAndQuantityDictionary;
    }, {});
}

// TODO: cleanup end

export default memo(QuestOverview);
