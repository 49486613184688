import { configureStore } from "@reduxjs/toolkit";
import quests from "./quests";

const store = configureStore({
  reducer: {
    quests,
  },
});

export default store;
