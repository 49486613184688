import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { SpeakerType } from "@worldwidewebb/quest-shared/dist/dialog";

const speakerTypes: Record<SpeakerType, string> = {
  none: "NONE",
  npc: "NPC",
  npc_input: "NPC INPUT",
  player: "PLAYER",
};

interface SelectSpeakerTypeProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectSpeakerType: React.FC<SelectSpeakerTypeProps> = ({ color, value, setValue, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return Object.entries(speakerTypes).filter(([_, displayName]) =>
      searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
    );
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Speaker Type"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectSpeakerType;
