import { ChakraProps, HStack } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { Node } from "reactflow";
import { QuestWithId } from "../../models/api/quest";
import { NodeType } from "../../models/nodeType";
import NpcSprite from "../npcs/NpcSprite";

interface QuestNodeBannerProps extends ChakraProps {
  quest?: QuestWithId;
}

function QuestNodeBanner({ quest }: QuestNodeBannerProps) {
  const questNpcIds = useMemo(() => {
    if (quest == null) {
      return [];
    }

    return Array.from(new Set(getQuestNpcs(quest.data?.nodes)));
  }, [quest]);

  const questNpcId = useMemo(() => {
    const questNpcId = quest?.questNpcId;

    if (questNpcId) {
      return questNpcId;
    }

    return questNpcIds.at(0);
  }, [quest, questNpcIds]);

  return (
    <HStack alignItems={"flex-end"}>
      {questNpcId && <NpcSprite npcId={questNpcId} boxSize={48} />}

      {questNpcIds
        .filter((npcId) => npcId !== questNpcId)
        .map((npcId) => (
          <NpcSprite key={npcId} npcId={npcId} />
        ))}
    </HStack>
  );
}

function getQuestNpcs(nodes: Node<NodeType>[] = []): string[] {
  return nodes.flatMap(({ data: { nodeCategory, nodeData = {} } }) => {
    if (nodeCategory === "Subgraph" || nodeCategory === "Subgraph Template") {
      return getQuestNpcs(nodeData.nodes);
    }

    return nodes
      .filter(({ data: { nodeData = {} } }) => nodeData["npcId"] != null && nodeData["npcId"] !== "last_used")
      .map(({ data: { nodeData = {} } }) => nodeData["npcId"]);
  });
}

export default memo(QuestNodeBanner);
