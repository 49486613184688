import { useQuery } from "@tanstack/react-query";
import { Skill } from "@worldwidewebb/client-skills";
import { getSkill } from "../../api/skills/skills";

export default function useSkill(skillId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: skill,
  } = useQuery<Skill>({
    queryKey: ["skill", skillId],
    queryFn: () => getSkill(skillId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    skill,
  };
}
