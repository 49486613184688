import React, { useEffect, useState } from "react";
import { ChakraProps } from "@chakra-ui/system";
import { Text } from "@chakra-ui/react";

interface TimerProps extends ChakraProps {
  time: Date;
}

const Timer: React.FC<TimerProps> = ({ color, time }) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const timeDifference = time.getTime() - new Date().getTime();

      setSeconds(Math.max(0, Math.floor(timeDifference / 1000) % 60));
      setMinutes(Math.max(0, Math.floor(timeDifference / (1000 * 60)) % 60));
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return (
    <Text color={color} minW={12} pl={1} textAlign={"left"}>
      {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
    </Text>
  );
};

export default Timer;
