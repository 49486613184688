import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QuestEditorCommitWithId } from "@worldwidewebb/client-quests";
import { updateQuestCommit as updateQuestCommitAsync } from "../../api/quests/questCommits";

export default function useUpdateQuestCommit(questId: string, questEditorCommitId: string) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: updateQuestCommit } = useMutation({
    mutationFn: (
      questEditorCommitPartial: Omit<
        QuestEditorCommitWithId,
        "questEditorCommitId" | "quest" | "questId" | "version" | "userId"
      >
    ) => updateQuestCommitAsync(questId, questEditorCommitId, questEditorCommitPartial),
    onSuccess: async ({ questId, questEditorCommitId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["questCommit", questId, questEditorCommitId],
      });

      toast({ title: "Quest commit update successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest commit update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestCommit,
  };
}
