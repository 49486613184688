import { useQuery } from "@tanstack/react-query";
import { EquipmentSlotWithFilter } from "@worldwidewebb/client-assets";
import { getEquipmentSlots } from "../../api/equipmentSlots/equipmentSlots";

export default function useEquipmentSlots() {
  const {
    isLoading,
    isFetching,
    error,
    data: equipmentSlots,
  } = useQuery<EquipmentSlotWithFilter[]>({
    queryKey: ["equipmentSlot"],
    queryFn: () => getEquipmentSlots(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    equipmentSlots,
  };
}
