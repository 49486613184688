import React, { memo, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { NodeProps, useUpdateNodeInternals } from "reactflow";
import { NodeData, NodeType, TargetHandle } from "../../../models/nodeType";
import {
  FieldArrayWithId,
  useFieldArray,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import {
  Button,
  Center,
  ChakraProps,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Icon,
  Textarea,
  IconButton,
  Checkbox,
  Box,
  HStack,
  Modal,
  ModalOverlay,
  ModalProps,
  ModalContent,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { Form } from "react-router-dom";
import SelectMod from "../../base/SelectMod";
import { useDnD } from "../../../hooks/useDnD";
import { MdClose, MdDragHandle } from "react-icons/md";
import SelectSomething from "../../base/SelectSomething";
import { EditorView } from "@codemirror/view";
import ReactCodeMirror from "@uiw/react-codemirror";
import { Mod, ModComponent, Mode, ModTarget } from "@worldwidewebb/quest-shared/dist/mod";

const modes: Mode[] = ["insert", "remove"];
const modesDictionary = Object.fromEntries(modes.map((mode) => [mode, mode]));

interface ModComponentFormProps extends ChakraProps {
  index: number;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  register: UseFormRegister<Mod>;
  getValues: UseFormGetValues<Mod>;
  setValue: UseFormSetValue<Mod>;
  watch: UseFormWatch<Mod>;
}

function ModComponentForm({
  color,
  index,
  updateIndex,
  removeIndex,
  register,
  getValues,
  setValue,
  watch,
}: ModComponentFormProps) {
  const { handlerId, isDragging, ref, refPreview } = useDnD("modComponents", index, updateIndex);

  const mode = watch(`modComponents.${index}.mode`);

  useEffect(() => {
    if (mode !== "insert") {
      setValue(`modComponents.${index}.arguments`, "[]");
    }
  }, [mode]);

  return (
    <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
      <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
        <Icon as={MdDragHandle} />
      </Center>

      <Stack flexGrow={1}>
        <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
          <IconButton
            aria-label={"delete modComponent"}
            icon={<Icon as={MdClose} />}
            size={"xs"}
            variant={"ghost"}
            onClick={() => removeIndex(index)}
            color={"black"}
          />
        </Flex>

        <Stack p={2}>
          <HStack>
            <FormControl>
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  Name
                </Text>
              </FormLabel>

              <Input
                color={color}
                borderColor={color}
                borderRadius={0}
                borderWidth={2}
                {...register(`modComponents.${index}.name`)}
              />
            </FormControl>

            <FormControl>
              <SelectSomething
                color={color}
                title={"Mode"}
                values={modesDictionary}
                value={getValues(`modComponents.${index}.mode`)}
                setValue={(value) => setValue(`modComponents.${index}.mode`, value as Mode)}
              />
            </FormControl>

            <FormControl>
              <Center>
                <Stack>
                  <Checkbox color={color} {...register(`modComponents.${index}.runOnClient`)}>
                    <Text color={color} casing={"uppercase"}>
                      Run On Client
                    </Text>
                  </Checkbox>
                  <Checkbox color={color} {...register(`modComponents.${index}.runOnServer`)}>
                    <Text color={color} casing={"uppercase"}>
                      Run On Server
                    </Text>
                  </Checkbox>
                </Stack>
              </Center>
            </FormControl>
          </HStack>

          {getValues(`modComponents.${index}.mode`) === "insert" && (
            <FormControl>
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  Arguments (requires array)
                </Text>
              </FormLabel>

              <Box cursor={"text"}>
                <ReactCodeMirror
                  theme={"dark"}
                  value={getValues(`modComponents.${index}.arguments`)}
                  onChange={(value) => setValue(`modComponents.${index}.arguments`, value)}
                  basicSetup={{ lineNumbers: false, highlightActiveLine: false, highlightActiveLineGutter: false }}
                  extensions={[EditorView.lineWrapping]}
                  height={"8rem"}
                />
              </Box>
            </FormControl>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
}

interface ModComponentsProps extends ChakraProps {
  modComponentFields: FieldArrayWithId<Mod, "modComponents", "id">[];
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  insertIndex: (index: number) => void;
  register: UseFormRegister<Mod>;
  getValues: UseFormGetValues<Mod>;
  setValue: UseFormSetValue<Mod>;
  watch: UseFormWatch<Mod>;
}

function ModComponents({
  color,
  modComponentFields,
  updateIndex,
  removeIndex,
  insertIndex,
  register,
  getValues,
  setValue,
  watch,
}: ModComponentsProps) {
  return (
    <>
      <Stack p={2} borderColor={color} borderRadius={0} borderWidth={2}>
        <Box p={2} bg={color}>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Components
            </Text>
          </Heading>
        </Box>

        <Stack>
          {modComponentFields.length === 0 ? (
            <Center p={3}>
              <Text color={"white"} casing={"uppercase"}>
                No components (insert new)
              </Text>
            </Center>
          ) : (
            modComponentFields.map(({ id }, index) => (
              <ModComponentForm
                key={id}
                color={color}
                index={index}
                updateIndex={updateIndex}
                removeIndex={removeIndex}
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            ))
          )}
        </Stack>

        <Flex justifyContent={"flex-end"}>
          <Button onClick={() => insertIndex(modComponentFields.length)} variant={"outline"}>
            <Text color={color} casing={"uppercase"}>
              Insert New
            </Text>
          </Button>
        </Flex>
      </Stack>
    </>
  );
}

interface ModActionFormProps extends ChakraProps {
  index: number;
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  register: UseFormRegister<Mod>;
  getValues: UseFormGetValues<Mod>;
  setValue: UseFormSetValue<Mod>;
  watch: UseFormWatch<Mod>;
}

function ModActionForm({
  color,
  index,
  updateIndex,
  removeIndex,
  register,
  getValues,
  setValue,
  watch,
}: ModActionFormProps) {
  const { handlerId, isDragging, ref, refPreview } = useDnD("modActions", index, updateIndex);

  return (
    <Flex borderColor={color} borderRadius={0} borderWidth={1} ref={refPreview} opacity={isDragging ? 0.25 : 1}>
      <Center p={1} pl={3} ref={ref} data-handler-id={handlerId} cursor={"move"}>
        <Icon as={MdDragHandle} />
      </Center>

      <Stack flexGrow={1}>
        <Flex p={2} ml={2} bg={color} alignItems={"center"} justifyContent={"flex-end"}>
          <IconButton
            aria-label={"delete modAction"}
            icon={<Icon as={MdClose} />}
            size={"xs"}
            variant={"ghost"}
            onClick={() => removeIndex(index)}
            color={"black"}
          />
        </Flex>

        <Stack p={2}>
          <HStack>
            <FormControl>
              <FormLabel>
                <Text color={color} casing={"uppercase"}>
                  Name
                </Text>
              </FormLabel>

              <Input
                color={color}
                borderColor={color}
                borderRadius={0}
                borderWidth={2}
                {...register(`modActions.${index}.name`)}
              />
            </FormControl>

            <FormControl>
              <Center>
                <Stack>
                  <Checkbox color={color} {...register(`modActions.${index}.runOnClient`)}>
                    <Text color={color} casing={"uppercase"}>
                      Run On Client
                    </Text>
                  </Checkbox>
                  <Checkbox color={color} {...register(`modActions.${index}.runOnServer`)}>
                    <Text color={color} casing={"uppercase"}>
                      Run On Server
                    </Text>
                  </Checkbox>
                </Stack>
              </Center>
            </FormControl>
          </HStack>

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Arguments (requires any)
              </Text>
            </FormLabel>

            <Box cursor={"text"}>
              <ReactCodeMirror
                theme={"dark"}
                value={getValues(`modActions.${index}.arguments`)}
                onChange={(value) => setValue(`modActions.${index}.arguments`, value)}
                basicSetup={{ lineNumbers: false, highlightActiveLine: false, highlightActiveLineGutter: false }}
                extensions={[EditorView.lineWrapping]}
                height={"8rem"}
              />
            </Box>
          </FormControl>
        </Stack>
      </Stack>
    </Flex>
  );
}

interface ModActionsProps extends ChakraProps {
  modActionFields: FieldArrayWithId<Mod, "modActions", "id">[];
  updateIndex: (oldIndex: number, newIndex: number) => void;
  removeIndex: (index: number) => void;
  insertIndex: (index: number) => void;
  register: UseFormRegister<Mod>;
  getValues: UseFormGetValues<Mod>;
  setValue: UseFormSetValue<Mod>;
  watch: UseFormWatch<Mod>;
}

function ModActions({
  color,
  modActionFields,
  updateIndex,
  removeIndex,
  insertIndex,
  register,
  getValues,
  setValue,
  watch,
}: ModActionsProps) {
  return (
    <>
      <Stack p={2} borderColor={color} borderRadius={0} borderWidth={2}>
        <Box p={2} bg={color}>
          <Heading size={"sm"}>
            <Text color={"white"} casing={"uppercase"}>
              Actions
            </Text>
          </Heading>
        </Box>

        <Stack>
          {modActionFields.length === 0 ? (
            <Center p={3}>
              <Text color={"white"} casing={"uppercase"}>
                No actions (insert new)
              </Text>
            </Center>
          ) : (
            modActionFields.map(({ id }, index) => (
              <ModActionForm
                key={id}
                color={color}
                index={index}
                updateIndex={updateIndex}
                removeIndex={removeIndex}
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            ))
          )}
        </Stack>

        <Flex justifyContent={"flex-end"}>
          <Button onClick={() => insertIndex(modActionFields.length)} variant={"outline"}>
            <Text color={color} casing={"uppercase"}>
              Insert New
            </Text>
          </Button>
        </Flex>
      </Stack>
    </>
  );
}

type ConfigurationModalProps = ChakraProps & ModalProps & PropsWithChildren;

function ConfigurationModal({ color, isOpen, onClose, children }: ConfigurationModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
      <ModalOverlay />

      <ModalContent bg={"theme.dark.background"} p={1}>
        <ModalHeader>
          <Heading size={"md"}>
            <Text color={color} casing={"uppercase"}>
              Configuration
            </Text>
          </Heading>
        </ModalHeader>

        <ModalCloseButton color={color} />

        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}

const ModNode: React.FC<NodeProps<NodeType<Mod>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const modTarget = nodeData?.modTarget ?? "npc";
  const modTargetIdSelectionEnabled = nodeData?.modTargetIdSelectionEnabled ?? false;
  const modTargetId = nodeData?.modTargetId ?? "";
  const newObjectName = nodeData?.newObjectName ?? "";
  const modComponents = nodeData?.modComponents ?? [];
  const modActions = nodeData?.modActions ?? [];

  const { control, register, getValues, setValue, watch, handleSubmit } = useForm<Mod>({
    defaultValues: useMemo(
      () => ({
        modTarget,
        modTargetIdSelectionEnabled,
        modTargetId,
        newObjectName,
        modComponents,
        modActions,
      }),
      [modTarget, modTargetIdSelectionEnabled, modTargetId, newObjectName, modComponents, modActions]
    ),
    mode: "onChange",
  });

  const {
    fields: modComponentFields,
    insert: insertModComponent,
    remove: removeModComponent,
    move: moveModComponent,
  } = useFieldArray({
    name: "modComponents",
    control,
  });

  const {
    fields: modActionFields,
    insert: insertModAction,
    remove: removeModAction,
    move: moveModAction,
  } = useFieldArray({
    name: "modActions",
    control,
  });

  const handleInsertModComponent = useCallback(
    (index: number) => {
      insertModComponent(index, {
        mode: "insert",
        name: "",
        runOnClient: true,
        runOnServer: true,
        arguments: "[]",
      });
    },
    [insertModComponent]
  );

  const handleRemoveModComponent = useCallback(
    (index: number) => {
      removeModComponent(index);
    },
    [removeModComponent]
  );

  const handleMoveModComponent = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveModComponent(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveModComponent]
  );

  const handleInsertModAction = useCallback(
    (index: number) => {
      insertModAction(index, {
        name: "",
        runOnClient: true,
        runOnServer: true,
        arguments: "null",
      });
    },
    [insertModAction]
  );

  const handleRemoveModAction = useCallback(
    (index: number) => {
      removeModAction(index);
    },
    [removeModAction]
  );

  const handleMoveModAction = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveModAction(oldIndex, isFinite(Number(newIndex)) ? Number(newIndex) : oldIndex);
    },
    [moveModAction]
  );

  const modTargets: string[] = ["entity", "npc"];

  const { updateNodeData } = useUpdateNodeData<Mod>(nodeId);
  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback((mod: Mod) => {
    updateNodeData(mod);
  }, []);

  const modTargetHandleEnabled = !watch("modTargetIdSelectionEnabled");
  const modTargetHandleName = watch("modTarget");

  const modTargetHandleExists = useMemo(
    () => targetHandles.some(({ handleName }) => handleName === modTargetHandleName),
    [targetHandles, modTargetHandleName]
  );

  const modTargetHandlesExist = useMemo(
    () => targetHandles.some(({ handleName }) => modTargets.includes(handleName)),
    [targetHandles, modTargets]
  );

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    if (!modTargetHandleEnabled && !modTargetHandlesExist) {
      return;
    }

    if (modTargetHandleEnabled && !modTargetHandlesExist && !modTargetHandleName) {
      return;
    }

    if (modTargetHandleEnabled && modTargetHandleExists) {
      return;
    }

    if (modTargetHandleEnabled && !modTargetHandleExists && modTargetHandleName) {
      const updatedTargetHandles: TargetHandle[] = [
        ...targetHandles.filter(({ handleName }) => !modTargets.includes(handleName)),
        {
          label: modTargetHandleName,
          handleName: modTargetHandleName,
          handleType: "target",
          handleCategory: "data",
        },
      ];

      updateNodeTargetHandles(updatedTargetHandles);
      updateNodeInternals(nodeId);

      return;
    }

    if (modTargetHandleEnabled && modTargetHandlesExist && !modTargetHandleName) {
      const updatedTargetHandles: TargetHandle[] = targetHandles.filter(
        ({ handleName }) => !modTargets.includes(handleName)
      );

      updateNodeTargetHandles(updatedTargetHandles);
      updateNodeInternals(nodeId);

      return;
    }

    if (!modTargetHandleEnabled && modTargetHandlesExist) {
      const updatedTargetHandles: TargetHandle[] = targetHandles.filter(
        ({ handleName }) => !modTargets.includes(handleName)
      );

      updateNodeTargetHandles(updatedTargetHandles);
      updateNodeInternals(nodeId);

      return;
    }
  }, [
    modTargetHandleEnabled,
    modTargetHandleExists,
    modTargetHandlesExist,
    modTargetHandleName,
    modTargets,
    updateNodeInternals,
    nodeId,
  ]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FlowNodeWithChildren {...props}>
      <Form
        className={"nodrag"}
        onSubmit={handleSubmit(handleUpdate)}
        onBlur={handleSubmit(handleUpdate)}
        onChange={handleSubmit(handleUpdate)}
      >
        <Stack>
          <SelectMod
            color={color}
            modTarget={watch("modTarget")}
            setModTarget={(value) => setValue("modTarget", value)}
            modTargetId={watch("modTargetId")}
            setModTargetId={(value) => setValue("modTargetId", value)}
            modTargetIdSelectionEnabled={watch("modTargetIdSelectionEnabled")}
            setModTargetIdSelectionEnabled={(value) => setValue("modTargetIdSelectionEnabled", value)}
          />

          <Divider />

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                New Object Name
              </Text>
            </FormLabel>

            <Input color={color} borderColor={color} borderRadius={0} borderWidth={2} {...register("newObjectName")} />
          </FormControl>

          <ConfigurationModal color={color} isOpen={isOpen} onClose={onClose}>
            <Stack>
              <ModComponents
                color={color}
                modComponentFields={modComponentFields}
                updateIndex={handleMoveModComponent}
                removeIndex={handleRemoveModComponent}
                insertIndex={handleInsertModComponent}
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />

              <ModActions
                color={color}
                modActionFields={modActionFields}
                updateIndex={handleMoveModAction}
                removeIndex={handleRemoveModAction}
                insertIndex={handleInsertModAction}
                register={register}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
              />
            </Stack>
          </ConfigurationModal>

          <Button variant={"outline"} onClick={onOpen}>
            <Text color={color} casing={"uppercase"}>
              Configuration
            </Text>
          </Button>
        </Stack>
      </Form>
    </FlowNodeWithChildren>
  );
};

export default memo(ModNode);
