import React, { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

interface NpcMoveToLocation extends NodeData {
  wait_for_npc: boolean;
}

function NpcMoveToLocationNode(props: NodeProps<NodeType<NpcMoveToLocation>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const wait_for_npc = nodeData?.wait_for_npc ?? false;

  const { register, handleSubmit } = useForm<NpcMoveToLocation>({
    defaultValues: useMemo(
      () => ({
        wait_for_npc,
      }),
      [wait_for_npc]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Wait For NPC
            </Text>
          </FormLabel>
          <Checkbox color={color} {...register("wait_for_npc")} />
        </FormControl>
      </form>
    </FlowNodeWithChildren>
  );
}

export default memo(NpcMoveToLocationNode);
