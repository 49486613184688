import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { GiBookCover } from "react-icons/gi";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const tagLore: Command = {
  category: "game entity",
  icon: <Icon as={GiBookCover} />,
  name: "lore",
  tooltip: "tag as lore",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#lore}}", "{{/lore}}"),
};
