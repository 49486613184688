import { ChakraProps, Spinner, Tag, Text } from "@chakra-ui/react";
import { memo } from "react";
import useQuest from "../../hooks/quests/useQuest";

interface QuestNameProps extends ChakraProps {
  questId: string;
}

function QuestName({ questId }: QuestNameProps) {
  const { isLoading, error, quest } = useQuest(questId);

  if (isLoading) {
    return <Spinner size={"sm"} color={"white"} />;
  }

  if (error || quest == null) {
    return <Spinner size={"sm"} color={"red.800"} />;
  }

  const { name } = quest;

  return (
    <Tag p={2}>
      <Text color={"white"} casing={"uppercase"}>
        {name}
      </Text>
    </Tag>
  );
}

export default memo(QuestName);
