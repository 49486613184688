import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectSkill from "../../base/SelectSkill";

interface SkillAndExperiencePoints extends NodeData {
  skillId: string;
  experiencePoints: number;
}

const SkillAndExperiencePointsNode: React.FC<NodeProps<NodeType<SkillAndExperiencePoints>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const skillId = nodeData?.skillId;
  const experiencePoints = nodeData?.experiencePoints;

  const { register, getValues, setValue, handleSubmit } = useForm<SkillAndExperiencePoints>({
    defaultValues: useMemo(
      () => ({
        skillId,
        experiencePoints,
      }),
      [skillId, experiencePoints]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    (skillAndExperiencePoints: SkillAndExperiencePoints) => {
      updateNodeData(skillAndExperiencePoints);
    },
    [updateNodeData]
  );

  return (
    <BaseNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack p={2}>
          <SelectSkill color={color} value={getValues("skillId")} setValue={(value) => setValue("skillId", value)} />

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Experience Points
              </Text>
            </FormLabel>
            <NumberInput step={1} defaultValue={0} min={0}>
              <NumberInputField color={color} {...register("experiencePoints")} />
              <NumberInputStepper>
                <NumberIncrementStepper color={color} />
                <NumberDecrementStepper color={color} />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Stack>
      </form>
    </BaseNodeWithChildren>
  );
};

export default memo(SkillAndExperiencePointsNode);
