import { useQuery } from "@tanstack/react-query";
import { ObservableEventSelector } from "@worldwidewebb/client-quests";
import { getObservableEvents } from "../../api/events/observableEvents";

export default function useObservableEvents() {
  const {
    isLoading,
    isFetching,
    error,
    data: observableEvents,
  } = useQuery<ObservableEventSelector[]>({
    queryKey: ["observableEvent"],
    queryFn: () => getObservableEvents(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    observableEvents,
  };
}
