import React, { useCallback } from "react";
import { Box, ChakraProps, Icon, IconButton } from "@chakra-ui/react";
import { EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from "reactflow";
import { useToken } from "@chakra-ui/system";
import { MdClose } from "react-icons/md";
import { NodeType } from "../../../../models/nodeType";
import { EdgeType } from "../../../../models/edgeType";

interface BaseEdgeProps extends EdgeProps, Pick<ChakraProps, "color"> {
  isDashed?: boolean;
  isDeletable?: boolean;
}

const BaseEdge: React.FC<BaseEdgeProps> = ({ isDashed, isDeletable = true, color: token, ...edgeProps }) => {
  const [edgePath, labelX, labelY] = getBezierPath(edgeProps);
  const [color] = useToken("colors", [token?.toString() ?? "white"]);
  const { id, style, markerStart, markerEnd, selected: isSelected } = edgeProps;

  const isDashedProps: React.CSSProperties = isDashed
    ? {
        animation: "dashdraw 1s linear infinite",
        strokeDasharray: "5",
      }
    : {};

  const { setEdges } = useReactFlow<NodeType, EdgeType>();

  const handleDeleteEdge = useCallback(() => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  }, [setEdges, id]);

  return (
    <>
      <g>
        <path
          style={{
            ...style,
            ...isDashedProps,
            filter: isSelected ? "drop-shadow(0px 0px 6px white) brightness(200%)" : undefined,
          }}
          d={edgePath}
          fill={"none"}
          stroke={isSelected ? "white" : color}
          strokeWidth={3}
          markerStart={markerStart}
          markerEnd={markerEnd}
        />
        <path
          id={id}
          style={{
            ...style,
          }}
          d={edgePath}
          fill={"none"}
          stroke={"transparent"}
          strokeWidth={20}
        />
      </g>

      {isDeletable && (
        <EdgeLabelRenderer>
          <Box
            className={"nodrag nopan"}
            position={"absolute"}
            transform={`translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`}
            pointerEvents={"all"}
          >
            <IconButton
              color={color}
              bg={"theme.dark.background"}
              borderColor={color}
              borderWidth={2}
              px={0}
              py={0}
              size={"sm"}
              icon={<Icon boxSize={6} as={MdClose} />}
              aria-label={"delete edge"}
              isRound={true}
              onClick={handleDeleteEdge}
            />
          </Box>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default BaseEdge;
