import { NodeData } from "../nodeType";

export const CHUNK_W = 512;
export const CHUNK_H = 512;

export interface Location extends NodeData {
  roomName: string;
  x: number;
  y: number;
  radius: number;
}
