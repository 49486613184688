import { useQuery } from "@tanstack/react-query";
import { WorldMetadataWithEnabled } from "@worldwidewebb/client-world";
import { getRooms } from "../../api/rooms/rooms";

export default function useRooms(searchQuery?: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: rooms,
  } = useQuery<WorldMetadataWithEnabled[]>({
    queryKey: ["room"],
    queryFn: () => getRooms(searchQuery),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    rooms,
  };
}
