import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType, TargetHandle } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";

interface FormData {
  mulInputCount: number;
  divInputCount: number;
}

const MultiplicativeOperatorNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {}, targetHandles = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const mulInputCount = formData?.mulInputCount ?? 2;
  const divInputCount = formData?.divInputCount ?? 0;

  const { handleSubmit, control, watch } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        mulInputCount,
        divInputCount,
      }),
      [mulInputCount, divInputCount]
    ),
    mode: "onChange",
  });

  const reactFlow = useReactFlow();
  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ mulInputCount, divInputCount }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.mulInputCount = mulInputCount;
        nodeData.divInputCount = divInputCount;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  const watchedMulInputCount = watch("mulInputCount");
  const watchedDivInputCount = watch("divInputCount");

  useEffect(() => {
    const currentMulInputCount = targetHandles.filter(({ handleName }) => handleName === "mul").length;

    if (currentMulInputCount === watchedMulInputCount) {
      return;
    }

    const targetHandlesExcludingMul = targetHandles.filter(({ handleName }) => handleName !== "mul");

    const targetHandlesIncludingMul = targetHandles.filter(({ handleName }) => handleName === "mul");

    if (currentMulInputCount < watchedMulInputCount) {
      const handlesToInsert = watchedMulInputCount - currentMulInputCount;

      targetHandlesIncludingMul.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "MUL",
            handleName: "mul",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentMulInputCount - watchedMulInputCount;

      targetHandlesIncludingMul.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesIncludingMul, ...targetHandlesExcludingMul]);
  }, [targetHandles, watchedMulInputCount]);

  useEffect(() => {
    const currentDivInputCount = targetHandles.filter(({ handleName }) => handleName === "div").length;

    if (currentDivInputCount === watchedDivInputCount) {
      return;
    }

    const targetHandlesExcludingDiv = targetHandles.filter(({ handleName }) => handleName !== "div");

    const targetHandlesIncludingDiv = targetHandles.filter(({ handleName }) => handleName === "div");

    if (currentDivInputCount < watchedDivInputCount) {
      const handlesToInsert = watchedDivInputCount - currentDivInputCount;

      targetHandlesIncludingDiv.push(
        ...[...Array(handlesToInsert)].map(() => {
          const targetHandle: TargetHandle = {
            label: "DIV",
            handleName: "div",
            handleType: "target",
            handleCategory: "data",
          };

          return targetHandle;
        })
      );
    } else {
      const handlesToRemove = currentDivInputCount - watchedDivInputCount;

      targetHandlesIncludingDiv.splice(-handlesToRemove);
    }

    updateNodeTargetHandles([...targetHandlesExcludingDiv, ...targetHandlesIncludingDiv]);
  }, [targetHandles, watchedDivInputCount]);

  return (
    <BaseNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Multiplication Port Count
            </Text>
          </FormLabel>
          <Controller
            name={"mulInputCount"}
            control={control}
            render={({ field: { ref, value, onChange, onBlur, name } }) => (
              <NumberInput
                value={value}
                defaultValue={2}
                name={name}
                step={1}
                min={0}
                max={16}
                ref={ref}
                onChange={(value) => onChange(Number(value))}
                onBlur={onBlur}
              >
                <NumberInputField color={color} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Division Port Count
            </Text>
          </FormLabel>
          <Controller
            name={"divInputCount"}
            control={control}
            render={({ field: { ref, value, onChange, onBlur, name } }) => (
              <NumberInput
                value={value}
                defaultValue={2}
                name={name}
                step={1}
                min={0}
                max={16}
                ref={ref}
                onChange={(value) => onChange(Number(value))}
                onBlur={onBlur}
              >
                <NumberInputField color={color} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </FormControl>
      </form>
    </BaseNodeWithChildren>
  );
};

export default memo(MultiplicativeOperatorNode);
