import React, { useRef } from "react";
import { Identifier } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";

export interface DnDItem {
  index: number;
}

export function useDnD<T extends HTMLElement>(
  refGroupId: string,
  index: number,
  updateIndex: (oldIndex: number, newIndex: number) => void
) {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DnDItem, void, { handlerId: Identifier | null }>({
    accept: refGroupId,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (ref.current == null) {
        return;
      }

      if (item.index === index) {
        return;
      }

      const clientOffset = monitor.getClientOffset();

      if (clientOffset == null) {
        return;
      }

      const { top: hoverTop, bottom: hoverBottom } = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBottom - hoverTop) / 2;
      const hoverClientY = clientOffset.y - hoverTop;

      if (item.index < index && hoverClientY < hoverMiddleY) {
        return;
      }

      if (item.index > index && hoverClientY > hoverMiddleY) {
        return;
      }

      updateIndex(item.index, index);

      item.index = index;
    },
  });

  const [{ isDragging }, drag, refPreview] = useDrag({
    type: refGroupId,
    item: () => ({
      index,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return {
    handlerId,
    isDragging,
    ref,
    refPreview,
  };
}
