import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { TbUserQuestion } from "react-icons/tb";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const tagNpc: Command = {
  category: "game entity",
  icon: <Icon as={TbUserQuestion} />,
  name: "npc",
  tooltip: "tag as npc",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#npc}}", "{{/npc}}"),
};
