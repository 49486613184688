import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteQuestEditorSession as deleteQuestEditorSessionAsync } from "../../api/sessions/sessions";

export default function useDeleteQuestEditorSession() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isDeleting, mutate: deleteQuestEditorSession } = useMutation({
    mutationFn: (questId: string) => deleteQuestEditorSessionAsync(questId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questEditorSession"],
      });

      toast({ title: "Quest editor session delete successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest editor session delete unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isDeleting,
    deleteQuestEditorSession,
  };
}
