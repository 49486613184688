import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { nodeCategories, NodeCategory } from "../../../models/nodeType";
import SearchBar from "../../base/SearchBar";
import { NodeTypeList } from "../../NodeTypeList";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  HStack,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { MdArrowRight } from "react-icons/md";
import useInitialNodeTypes from "../../../hooks/useInitialNodeTypes";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { useLoaderData } from "react-router";

type NodeCategorySelectionMode = "single" | "multiple" | null;

interface NodeCategorySelectionPopoverProps {
  nodeCategories: NodeCategory[];
  onUpdateSelectedNodeCategories: (selectedNodeCategories: NodeCategory[]) => void;
}

const NodeCategorySelectionPopover: React.FC<NodeCategorySelectionPopoverProps> = ({
  nodeCategories,
  onUpdateSelectedNodeCategories,
}) => {
  const [selectionMode, setSelectionMode] = useState<NodeCategorySelectionMode>(null);
  const [selectedNodeCategories, setSelectedNodeCategories] = useState<NodeCategory[]>([]);

  const handleClearSelection = useCallback(() => {
    setSelectionMode(null);
    setSelectedNodeCategories([]);
  }, []);

  const handleUpdateSelectionMode = useCallback(
    (updatedSelectionMode: NodeCategorySelectionMode) => {
      if (selectionMode === "multiple" && updatedSelectionMode === "single") {
        setSelectedNodeCategories((selectedNodeCategories) => selectedNodeCategories.slice(0, 1));
      }

      setSelectionMode(updatedSelectionMode);
    },
    [selectionMode]
  );

  const handleToggleSelectedNodeCategory = useCallback(
    (nodeCategory: NodeCategory) => {
      if (selectionMode === null) {
        setSelectionMode("multiple");
      }

      if (selectionMode === "single") {
        if (selectedNodeCategories.includes(nodeCategory)) {
          setSelectedNodeCategories([]);
        } else {
          setSelectedNodeCategories([nodeCategory]);
        }
      } else {
        if (selectedNodeCategories.includes(nodeCategory)) {
          setSelectedNodeCategories((selectedNodeCategories) =>
            selectedNodeCategories.filter((selectedNodeCategory) => selectedNodeCategory !== nodeCategory)
          );
        } else {
          setSelectedNodeCategories((selectedNodeCategories) => [...selectedNodeCategories, nodeCategory]);
        }
      }
    },
    [selectionMode, selectedNodeCategories]
  );

  useEffect(() => {
    onUpdateSelectedNodeCategories(selectedNodeCategories);
  }, [selectedNodeCategories]);

  return (
    <Popover placement={"right"}>
      <PopoverTrigger>
        <Button borderRadius={0} bg={"indigo.600"} rightIcon={<Icon color={"white"} as={MdArrowRight} />}>
          <Text casing={"uppercase"} color={"white"}>
            Categories
          </Text>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={"md"} mt={2} ml={4} bg={"theme.dark.background"} borderRadius={0} borderWidth={0}>
          <Stack>
            <Button w={"100%"} borderRadius={0} bg={"indigo.600"} onClick={handleClearSelection}>
              <Text casing={"uppercase"} color={"white"}>
                Clear selection
              </Text>
            </Button>
            <HStack>
              <Button
                w={"100%"}
                borderRadius={0}
                bg={selectionMode === "single" ? "indigo.600" : "transparent"}
                onClick={() => handleUpdateSelectionMode("single")}
              >
                <Text casing={"uppercase"} color={"white"}>
                  Single
                </Text>
              </Button>
              <Button
                w={"100%"}
                borderRadius={0}
                bg={selectionMode === "multiple" ? "indigo.600" : "transparent"}
                onClick={() => handleUpdateSelectionMode("multiple")}
              >
                <Text casing={"uppercase"} color={"white"}>
                  Multiple
                </Text>
              </Button>
            </HStack>
            <SimpleGrid columns={2} spacing={2} borderWidth={2} borderColor={"indigo.600"}>
              {nodeCategories &&
                nodeCategories.map((nodeCategory) => (
                  <Button
                    key={nodeCategory}
                    w={"100%"}
                    borderRadius={0}
                    bg={selectedNodeCategories.includes(nodeCategory) ? "indigo.600" : "transparent"}
                    onClick={() => handleToggleSelectedNodeCategory(nodeCategory)}
                  >
                    <Text casing={"uppercase"} color={"white"}>
                      {nodeCategory}
                    </Text>
                  </Button>
                ))}
            </SimpleGrid>
          </Stack>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const NodeSelector: React.FC = () => {
  const { subgraphs } = useLoaderData() as {
    subgraphs: QuestSubgraphWithId[];
  };
  const nodeTypes = useInitialNodeTypes(subgraphs);

  const [searchValue, setSearchValue] = useState<string>("");
  const [showIsReadyNodes, setShowIsReadyNodes] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const formControlRef = useRef<HTMLDivElement>(null);

  const [scrollableHeight, setScrollableHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const containerRefHeight = containerRef.current?.offsetHeight ?? 0;
    const filterContainerRefHeight = filterContainerRef.current?.offsetHeight ?? 0;
    const formControlRefHeight = formControlRef.current?.offsetHeight ?? 0;

    setScrollableHeight(containerRefHeight - filterContainerRefHeight - formControlRefHeight);

    return () => setScrollableHeight(0);
  }, []);

  const [selectedNodeCategories, setSelectedNodeCategories] = useState<NodeCategory[]>([]);

  const handleUpdateSelectedNodeCategories = useCallback((selectedNodeCategories: NodeCategory[]) => {
    setSelectedNodeCategories(selectedNodeCategories);
  }, []);

  const filteredNodeTypes = useMemo(() => {
    return nodeTypes
      .filter(({ nodeCategory }) =>
        nodeCategory != null && selectedNodeCategories.length !== 0
          ? selectedNodeCategories.includes(nodeCategory)
          : true
      )
      .filter(({ isReady }) => (showIsReadyNodes ? isReady : true))
      .filter(({ label }) => label?.toLowerCase().includes(searchValue.toLowerCase().trim()))
      .sort(({ label: a = "" }, { label: b = "" }) => a.localeCompare(b));
  }, [nodeTypes, selectedNodeCategories, showIsReadyNodes, searchValue]);

  return (
    <Card bg={"theme.dark.background"}>
      <Stack ref={containerRef} flexGrow={1}>
        <Stack ref={filterContainerRef}>
          <NodeCategorySelectionPopover
            nodeCategories={nodeCategories}
            onUpdateSelectedNodeCategories={handleUpdateSelectedNodeCategories}
          />
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={"Search for node types..."}
            variant={"block"}
          />
        </Stack>

        <Stack flexGrow={1} h={scrollableHeight}>
          {filteredNodeTypes.length !== 0 ? (
            <NodeTypeList nodeTypes={filteredNodeTypes} hasSearchValue={searchValue !== ""} />
          ) : (
            <Text p={4} align={"center"} color={"white"}>
              Search for something else...
            </Text>
          )}
        </Stack>

        <FormControl ref={formControlRef} p={2}>
          <Checkbox
            isChecked={showIsReadyNodes}
            onChange={({ target: { checked } }) => setShowIsReadyNodes(checked)}
            alignItems={"center"}
          >
            <Text casing={"uppercase"} color={"indigo.600"} fontWeight={700}>
              Hide nodes under development
            </Text>
          </Checkbox>
        </FormControl>
      </Stack>
    </Card>
  );
};

export default memo(NodeSelector);
