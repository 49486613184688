import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { CgSmartphoneShake } from "react-icons/cg";

export const scribbleWobble: Command = {
  category: "effect",
  icon: <Icon as={CgSmartphoneShake} />,
  name: "wobble",
  tooltip: "tag with wobble effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#wobble}}", "{{/wobble}}"),
};
