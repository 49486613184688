import { useQuery } from "@tanstack/react-query";
import { QuestEditorFolderWithId } from "@worldwidewebb/client-quests";
import { getQuestFolders } from "../../api/quests/questFolders";

export default function useQuestFolders() {
  const {
    isLoading,
    isFetching,
    error,
    data: questFolders,
  } = useQuery<QuestEditorFolderWithId[]>({
    queryKey: ["questFolder"],
    queryFn: () => getQuestFolders(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questFolders,
  };
}
