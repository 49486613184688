import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { FormControl, FormLabel, Text } from "@chakra-ui/react";

interface WaitForEntityInteraction extends NodeData {
  entityIndicator: string;
}

const WaitForEntityInteractionNode: React.FC<NodeProps<NodeType<WaitForEntityInteraction>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const entityIndicator = nodeData?.entityIndicator ?? "none";

  const { getValues, setValue, handleSubmit } = useForm<WaitForEntityInteraction>({
    defaultValues: useMemo(
      () => ({
        entityIndicator,
      }),
      [entityIndicator]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    ({ entityIndicator }: WaitForEntityInteraction) => {
      updateNodeData({ entityIndicator });
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Entity Indicator
            </Text>
          </FormLabel>
          <SelectNpcIndicator
            value={getValues("entityIndicator")}
            setValue={(value) => setValue("entityIndicator", value)}
            color={color}
            showLabel={false}
          />
        </FormControl>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(WaitForEntityInteractionNode);
