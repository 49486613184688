import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

export default function useRefreshQuestPointers() {
  const queryClient = useQueryClient();

  const refreshQuestPointers = useCallback(
    async (questId: string, userId: string) => {
      await queryClient.invalidateQueries({
        queryKey: ["questPointer", questId, userId],
      });
    },
    [queryClient]
  );

  return {
    refreshQuestPointers,
  };
}
