import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { MdRefresh } from "react-icons/md";
import useResetQuest from "../../hooks/quests/useResetQuest";

interface QuestResetButtonProps {
  questId: string;
  userId: string;
}

function QuestResetButton({ questId, userId }: QuestResetButtonProps) {
  const { isResetting, resetQuest } = useResetQuest(questId, userId);

  const handleResetQuest = useCallback(() => resetQuest(), [resetQuest]);

  return (
    <Tooltip label={"reset quest"}>
      <IconButton
        aria-label={"reset quest"}
        icon={<Icon as={MdRefresh} position={"absolute"} />}
        color={"green.600"}
        isLoading={isResetting}
        onClick={handleResetQuest}
      />
    </Tooltip>
  );
}

export default memo(QuestResetButton);
