import { AssetsApi, AvatarWithId } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getAvatar(avatarId?: string): Promise<AvatarWithId | null> {
  if (avatarId == null) {
    return null;
  }

  try {
    const { data: avatar } = await assetsApi.getAvatar(avatarId);

    return avatar;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
