import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useNpcs from "../../hooks/npcs/useNpcs";

interface SelectNpcProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
  showLabel?: boolean;
}

const SelectNpc: React.FC<SelectNpcProps> = ({ color, value, setValue, showLabel, ...chakraProps }) => {
  const { isLoading, error, npcs = [] } = useNpcs();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return npcs
        .filter(({ displayName }) =>
          searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
        )
        .map(({ npcId, displayName }) => [npcId, displayName]);
    },
    [npcs]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"NPC"}
        showLabel={showLabel}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectNpc;
