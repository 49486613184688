import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { Connection, Edge, HandleType, useReactFlow } from "reactflow";
import useNodeHandlesValidation from "../hooks/useNodeHandlesValidation";
import { NodeType } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";
import { spreadHandleId } from "../utils/handleId";
import { ulid } from "ulid";

interface ContextType {
  onConnect: (connection: Connection) => void;
  onEdgeUpdateStart: (edge: Edge) => void;
  onEdgeUpdateEnd: () => void;
  selectedHandleId: string | undefined;
  selectedHandleType: HandleType | null;
  setSelectedHandle: (nodeId: string | null, handleId: string | undefined, handleType: HandleType | null) => void;
}

const Context = createContext<ContextType | null>(null);

const ConnectionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [selectedHandleId, setSelectedHandleId] = useState<string | undefined>(undefined);
  const [selectedHandleType, setSelectedHandleType] = useState<HandleType | null>(null);
  const { setEdges } = useReactFlow<NodeType, EdgeType>();

  const onConnect = useCallback(
    ({ source, sourceHandle, target, targetHandle }: Connection) =>
      setEdges((currentEdges) => {
        if (!source || !target) return currentEdges;

        if (sourceHandle == null) {
          return currentEdges;
        }

        const { handleCategory } = spreadHandleId(sourceHandle);

        let type = "default";

        if (handleCategory === "start") {
          type = "StartEdge";
        }

        if (handleCategory === "end") {
          type = "EndEdge";
        }

        if (handleCategory === "flow") {
          type = "FlowEdge";
        }

        if (handleCategory === "data") {
          type = "DataEdge";
        }

        if (handleCategory === "hook") {
          type = "HookEdge";
        }

        if (handleCategory === "questId") {
          type = "QuestIdEdge";
        }

        if (handleCategory === "taskId") {
          type = "TaskIdEdge";
        }

        if (handleCategory === "vendorId") {
          type = "VendorIdEdge";
        }

        if (handleCategory === "entity") {
          type = "DataEdge";
        }

        if (handleCategory === "soundId") {
          type = "DataEdge";
        }

        if (handleCategory === "craftingStationId") {
          type = "CraftingStationIdEdge";
        }

        if (handleCategory === "playerActionAndRequirement") {
          type = "DataEdge";
        }

        setSelectedNodeId(null);
        setSelectedHandleId(undefined);
        setSelectedHandleType(null);

        return currentEdges.concat({
          id: ulid(),
          type,
          source,
          sourceHandle,
          target,
          targetHandle,
        });
      }),
    [setEdges]
  );

  const onEdgeUpdateStart = useCallback(({ id }: Edge) => {
    setEdges((edges) =>
      edges.map((edge) => {
        if (edge.data == null) {
          edge.data = {};
        }

        if (edge.id !== id) {
          return edge;
        }

        edge.data.isDragging = true;

        return edge;
      })
    );
  }, []);

  const onEdgeUpdateEnd = useCallback(() => {
    setTimeout(() => {
      setEdges((edges) =>
        edges.map((edge) => {
          if (edge.data == null) {
            edge.data = {};
          }

          edge.data.isDragging = false;

          return edge;
        })
      );
    }, 100);
  }, []);

  const { getValidConnectionHandles } = useNodeHandlesValidation();

  const setSelectedHandle = useCallback(
    (nodeId: string | null, handleId: string | undefined, handleType: HandleType | null) => {
      if (selectedHandleId === handleId) {
        setSelectedNodeId(null);
        setSelectedHandleId(undefined);
        setSelectedHandleType(null);
      } else if (selectedHandleId == null) {
        setSelectedNodeId(nodeId);
        setSelectedHandleId(handleId);
        setSelectedHandleType(handleType);
      } else {
        const validConnectionHandles = getValidConnectionHandles(nodeId, selectedHandleId, selectedHandleType).map(
          ({ handleId }) => handleId
        );

        const isValidConnection = validConnectionHandles.includes(handleId);

        if (!isValidConnection) {
          return;
        }

        onConnect({
          source: selectedHandleType === "source" ? selectedNodeId : nodeId,
          sourceHandle: selectedHandleType === "source" ? selectedHandleId : handleId ?? null,
          target: selectedHandleType === "target" ? selectedNodeId : nodeId,
          targetHandle: selectedHandleType === "target" ? selectedHandleId : handleId ?? null,
        });

        setSelectedNodeId(null);
        setSelectedHandleId(undefined);
        setSelectedHandleType(null);
      }
    },
    [selectedNodeId, selectedHandleId, selectedHandleType, getValidConnectionHandles, onConnect]
  );

  useEffect(() => {
    function clearSelectedHandle({ key }: KeyboardEvent) {
      if (key !== "Escape") {
        return;
      }

      setSelectedNodeId(null);
      setSelectedHandleId(undefined);
      setSelectedHandleType(null);
    }

    document.addEventListener("keydown", clearSelectedHandle);

    return () => document.removeEventListener("keydown", clearSelectedHandle);
  }, []);

  return (
    <Context.Provider
      value={{
        onConnect,
        onEdgeUpdateStart,
        onEdgeUpdateEnd,
        selectedHandleId,
        selectedHandleType,
        setSelectedHandle,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useConnectionProvider = () => {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("out of context");
  }

  return context;
};

export { ConnectionProvider, useConnectionProvider };
