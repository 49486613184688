import { Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import React, { memo, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import SelectTutorial from "../../base/SelectTutorial";
import { FlowNodeWithChildren } from "./FlowNode";

interface ShowTutorial extends NodeData {
  tutorialId: string;
  waitForPlayer: boolean;
}

const ShowTutorialNode: React.FC<NodeProps<NodeType<ShowTutorial>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const tutorialId = nodeData?.tutorialId ?? "";
  const waitForPlayer = nodeData?.waitForPlayer ?? false;

  const { register, getValues, setValue, handleSubmit } = useForm<ShowTutorial>({
    defaultValues: useMemo(
      () => ({
        tutorialId,
        waitForPlayer,
      }),
      [tutorialId, waitForPlayer]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<ShowTutorial>(nodeId);

  const handleUpdate = useCallback(
    ({ tutorialId, waitForPlayer }: ShowTutorial) => {
      updateNodeData({
        tutorialId, waitForPlayer
      });
    },
    [updateNodeData, setValue]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <SelectTutorial color={color} value={getValues("tutorialId")} setValue={(value) => setValue("tutorialId", value)} />
        
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Wait for player to finish tutorial
              </Text>
            </FormLabel>
            <Checkbox id={"waitForPlayer"} isRequired {...register("waitForPlayer")} color={color} />
          </FormControl>
        </Stack>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(ShowTutorialNode);
