import { useQuery } from "@tanstack/react-query";
import { Door } from "@worldwidewebb/client-world";
import { getDoors } from "../../api/doors/doors";

export default function useDoors(worldId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: doors,
  } = useQuery<Door[]>({
    queryKey: ["door", worldId],
    queryFn: () => getDoors(worldId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    doors,
  };
}
