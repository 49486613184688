import React, { memo } from "react";
import { Stack, StackProps } from "@chakra-ui/react";
import { QuestWithId } from "../models/api/quest";
import QuestListItemOverviewAggregate from "./QuestListItemOverviewAggregate";

interface QuestListItemOverviewProps extends StackProps {
  quest: QuestWithId;
}

const QuestListItemOverview: React.FC<QuestListItemOverviewProps> = ({ quest, ...stackProps }) => {
  return (
    <Stack position={"relative"} minH={9} {...stackProps}>
      <QuestListItemOverviewAggregate quest={quest} />
    </Stack>
  );
};

export default memo(QuestListItemOverview);
