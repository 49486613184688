import { useQuery } from "@tanstack/react-query";
import { World } from "@worldwidewebb/client-world";
import { getRoom } from "../../api/rooms/rooms";

export default function useRoom(roomId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: room,
  } = useQuery<World>({
    queryKey: ["room", roomId],
    queryFn: () => getRoom(roomId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    room,
    chunks: room?.chunks ?? [],
    worldMap: room?.worldMap,
  };
}
