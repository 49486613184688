import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType, TargetHandle } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import SelectNpcWithLastUsed from "../../base/SelectNpcWithLastUsed";

interface NpcFaceNpc extends NodeData {
  npcId: string;
  npcIdSelectionEnabled: boolean;
  targetNpcId: string;
  targetNpcSelectionEnabled: boolean;
}

const NpcFaceNpcNode: React.FC<NodeProps<NodeType<NpcFaceNpc>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const npcId = nodeData?.npcId ?? "last_used";
  const npcIdSelectionEnabled = nodeData?.npcIdSelectionEnabled ?? false;
  const targetNpcId = nodeData?.targetNpcId ?? "last_used";
  const targetNpcSelectionEnabled = nodeData?.targetNpcSelectionEnabled ?? false;

  const { register, getValues, setValue, handleSubmit, watch } = useForm<NpcFaceNpc>({
    defaultValues: useMemo(
      () => ({
        npcId,
        npcIdSelectionEnabled,
        targetNpcId,
        targetNpcSelectionEnabled,
      }),
      [npcId, npcIdSelectionEnabled, targetNpcId, targetNpcSelectionEnabled]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<NpcFaceNpc>(nodeId);

  const handleUpdate = useCallback(
    (nodeData: NpcFaceNpc) => {
      updateNodeData(nodeData);
    },
    [updateNodeData]
  );

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const npcIdSelectionEnabledWatched = watch("npcIdSelectionEnabled");

  useEffect(() => {
    if (!npcIdSelectionEnabledWatched && targetHandles.some(({ handleName }) => handleName === "npc")) {
      return;
    }

    if (npcIdSelectionEnabledWatched && targetHandles.every(({ handleName }) => handleName !== "npc")) {
      return;
    }

    const currentTargethandles = targetHandles.filter(
      ({ handleName }) => handleName !== "npc" && handleName !== "npc_target"
    );

    const updatedTargetHandlesIncludingNpc: TargetHandle[] = [];

    const updatedTargetHandlesIncludingNpcTarget = targetHandles.filter(
      ({ handleName }) => handleName === "npc_target"
    );

    if (!npcIdSelectionEnabledWatched) {
      updatedTargetHandlesIncludingNpc.push({
        label: "NPC",
        handleName: "npc",
        handleType: "target",
        handleCategory: "data",
      });
    }

    updateNodeTargetHandles([
      ...currentTargethandles,
      ...updatedTargetHandlesIncludingNpc,
      ...updatedTargetHandlesIncludingNpcTarget,
    ]);
  }, [updateNodeTargetHandles, targetHandles, npcIdSelectionEnabledWatched]);

  const targetNpcSelectionEnabledWatched = watch("targetNpcSelectionEnabled");

  useEffect(() => {
    if (!targetNpcSelectionEnabledWatched && targetHandles.some(({ handleName }) => handleName === "npc_target")) {
      return;
    }

    if (targetNpcSelectionEnabledWatched && targetHandles.every(({ handleName }) => handleName !== "npc_target")) {
      return;
    }

    const currentTargethandles = targetHandles.filter(
      ({ handleName }) => handleName !== "npc" && handleName !== "npc_target"
    );

    const updatedTargetHandlesIncludingNpc = targetHandles.filter(({ handleName }) => handleName === "npc");

    const updatedTargetHandlesIncludingNpcTarget: TargetHandle[] = [];

    if (!targetNpcSelectionEnabledWatched) {
      updatedTargetHandlesIncludingNpcTarget.push({
        label: "NPC To Face",
        handleName: "npc_target",
        handleType: "target",
        handleCategory: "data",
      });
    }

    updateNodeTargetHandles([
      ...currentTargethandles,
      ...updatedTargetHandlesIncludingNpc,
      ...updatedTargetHandlesIncludingNpcTarget,
    ]);
  }, [updateNodeTargetHandles, targetHandles, targetNpcSelectionEnabledWatched]);

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                NPC selection enabled
              </Text>
            </FormLabel>

            <Checkbox color={color} {...register("npcIdSelectionEnabled")} />
          </FormControl>

          {npcIdSelectionEnabledWatched && (
            <SelectNpcWithLastUsed
              value={getValues("npcId")}
              setValue={(value) => setValue("npcId", value)}
              color={color}
            />
          )}

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                NPC to face selection enabled
              </Text>
            </FormLabel>

            <Checkbox color={color} {...register("targetNpcSelectionEnabled")} />
          </FormControl>

          {targetNpcSelectionEnabledWatched && (
            <SelectNpcWithLastUsed
              value={getValues("targetNpcId")}
              setValue={(value) => setValue("targetNpcId", value)}
              color={color}
              title={"NPC To Face"}
            />
          )}
        </Stack>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(NpcFaceNpcNode);
