import React, { memo, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { PiSidebar } from "react-icons/pi";

export interface SidebarItem {
  icon: React.ElementType;
  label: string;
  content: React.ReactNode;
}

interface SidebarProps extends Omit<StackProps, "children"> {
  items: SidebarItem[];
  showContent?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ items, showContent = true, ...stackProps }) => {
  const [showSidebarContent, setShowSidebarContent] = useState<boolean>(showContent);

  return (
    <Stack {...stackProps}>
      <Flex bg={"indigo.600"}>
        <Tooltip label={"toggle sidebar content"} placement={"right"}>
          <IconButton
            color={stackProps.color}
            icon={<Icon as={PiSidebar} />}
            aria-label={"toggle sidebar content"}
            variant={showSidebarContent ? "solid" : "ghost"}
            borderRadius={0}
            onClick={() => setShowSidebarContent((showSidebarContent) => !showSidebarContent)}
          />
        </Tooltip>
      </Flex>

      <Tabs orientation={"vertical"} colorScheme={"gray"} flexGrow={1}>
        <TabList>
          {items.map(({ icon, label }, key) => (
            <Tab key={key} onClick={() => setShowSidebarContent(true)}>
              <Tooltip label={label} placement={"right"}>
                <Box>
                  <Icon color={stackProps.color} as={icon} />
                </Box>
              </Tooltip>
            </Tab>
          ))}
        </TabList>

        <AnimatePresence>
          {showSidebarContent && (
            <motion.div
              style={{ display: "flex" }}
              variants={{
                hide: { transition: { width: { delay: 0.25 } }, opacity: 0, width: 0 },
                show: { transition: { opacity: { delay: 0.25 } }, opacity: 1, width: "auto" },
              }}
              initial={"hide"}
              animate={"show"}
              exit={"hide"}
              transition={{ ease: "easeInOut", duration: 0.25 }}
            >
              <TabPanels display={"flex"}>
                {items.map(({ content }, key) => (
                  <TabPanel key={key} display={"flex"} p={0}>
                    {content}
                  </TabPanel>
                ))}
              </TabPanels>
            </motion.div>
          )}
        </AnimatePresence>
      </Tabs>
    </Stack>
  );
};

export default memo(Sidebar);
