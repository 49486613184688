import { ChakraProps } from "@chakra-ui/system";
import { getQuests } from "../../api/quests/quests";
import SearchPanel from "../../components/base/SearchPanel";
import { useReactFlowStoreProvider } from "../../context/ReactFlowStoreContext";
import { QuestWithId } from "../../models/api/quest";
import QuestSearchResult from "./QuestSearchResult";

function QuestSearchPanel({ color }: ChakraProps) {
  const { handleFitNode } = useReactFlowStoreProvider();

  return (
    <SearchPanel
      color={color}
      onSearchAsync={getQuests}
      onSelect={({ questId }: QuestWithId) => handleFitNode(questId)}
      render={({ name }) => <QuestSearchResult color={"white"}>{name}</QuestSearchResult>}
    >
      Quests
    </SearchPanel>
  );
}

export default QuestSearchPanel;
