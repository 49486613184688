import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

interface ContextType {
  selectedQuestId: string | null;
  changeSelectedQuest: (questId: string) => void;
}

const Context = createContext<ContextType | null>(null);

export function SelectedQuestProvider({ children }: PropsWithChildren) {
  const [selectedQuestId, setSelectedQuestId] = useState<string | null>(null);

  const changeSelectedQuest = useCallback(
    (questId: string) => setSelectedQuestId((selectedQuestId) => (selectedQuestId !== questId ? questId : null)),
    []
  );

  return <Context.Provider value={{ selectedQuestId, changeSelectedQuest }}>{children}</Context.Provider>;
}

export function useSelectedQuestProvider() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useSelectedQuestProvider used outside of SelectedQuestProvider");
  }

  return context;
}
