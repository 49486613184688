import { QuestPointer } from "@worldwidewebb/client-quests";
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useDeleteQuestPointer from "../hooks/quests/useDeleteQuestPointer";
import useQuestPointers from "../hooks/quests/useQuestPointers";
import useRefreshQuestPointers from "../hooks/quests/useRefreshQuestPointers";
import useUpdateQuestPointer from "../hooks/quests/useUpdateQuestPointer";
import useProfile from "../hooks/users/useProfile";

const REFRESH_INTERVAL_MS = 10_000;

export interface UserQuestPointerStatus {
  questPointerId?: string;
  questPointerColor?: string;
  isActive?: boolean;
  hasError?: boolean;
  isWaiting?: boolean;
  isCompleted?: boolean;
}

interface UserQuestPointer {
  questPointers: QuestPointer[];
  isLoading: boolean;
  hasError: boolean;
  questPointerDisplayName: string;
  questPointerUserId: string;
  setQuestPointerUserId: (userId: string) => void;
  selectedQuestPointerId: string | null;
  selectQuestPointer: (questPointerId: string) => void;
  updateQuestPointer: (nodeId: string) => void;
  deleteQuestPointer: (questPointerId: string) => void;
  getQuestPointerStatus: (nodeId: string) => UserQuestPointerStatus;
  showQuestPointers: boolean;
  openQuestPointers: () => void;
  hideQuestPointers: () => void;
}

const UserQuestPointerContext = createContext<UserQuestPointer | null>(null);

interface UserQuestPointerProviderProps extends PropsWithChildren {
  questId?: string;
}

export function UserQuestPointerProvider({ questId, children }: UserQuestPointerProviderProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [questPointerUserId, setQuestPointerUserId] = useState<string>("");

  const { profile } = useProfile(questPointerUserId);
  const questPointerDisplayName = profile?.displayName ?? "<unknown user>";

  const [showQuestPointers, setShowQuestPointers] = useState<boolean>(false);

  const [selectedQuestPointerId, setSelectedQuestPointerId] = useState<string | null>(null);

  const { isLoading, error, questPointers = [] } = useQuestPointers(questId, questPointerUserId);

  const selectQuestPointer = useCallback((questPointerId: string) => {
    setSelectedQuestPointerId((selectedQuestPointerId) =>
      selectedQuestPointerId !== questPointerId ? questPointerId : null
    );
  }, []);

  const { updateQuestPointer } = useUpdateQuestPointer();

  const updateSelectedQuestPointer = useCallback(
    (nodeId: string) => {
      if (selectedQuestPointerId == null) {
        return;
      }

      updateQuestPointer(
        { userId: questPointerUserId, questPointerId: selectedQuestPointerId, nodeId },
        {
          onSuccess: () => {
            setSelectedQuestPointerId(null);
          },
        }
      );
    },
    [updateQuestPointer, questPointerUserId, selectedQuestPointerId]
  );

  const { deleteQuestPointer } = useDeleteQuestPointer();

  const deleteSelectedQuestPointer = useCallback(
    (questPointerId: string) => {
      deleteQuestPointer(
        { userId: questPointerUserId, questPointerId },
        {
          onSuccess: () => {
            setSelectedQuestPointerId(null);
          },
        }
      );
    },
    [deleteQuestPointer, questPointerUserId]
  );

  const getQuestPointerStatus = useCallback(
    (nodeId: string): UserQuestPointerStatus => {
      const questPointer = questPointers.find((questPointer) => questPointer.nodeId === nodeId);
      const questPointerId = questPointer?.pointerId;

      const isActive = questPointer != null;
      const hasError = questPointer?.errored;
      const isWaiting = Number(questPointer?.waitReasons?.length) > 0;
      const isCompleted = questPointer?.completed;

      let questPointerColor = undefined;

      if (isActive) {
        questPointerColor = "green.600";
      }

      if (isWaiting) {
        questPointerColor = "green.200";
      }

      if (hasError) {
        questPointerColor = "red.800";
      }

      return {
        questPointerId,
        questPointerColor,
        isActive,
        hasError,
        isWaiting,
        isCompleted,
      };
    },
    [questPointers]
  );

  const { refreshQuestPointers } = useRefreshQuestPointers();

  const reloadQuestPointers = useCallback(() => {
    if (questId == null) {
      return;
    }

    if (questPointerUserId == null) {
      return;
    }

    refreshQuestPointers(questId, questPointerUserId);
  }, [refreshQuestPointers, questId, questPointerUserId]);

  const openQuestPointers = useCallback(() => {
    setShowQuestPointers(true);
  }, []);

  const hideQuestPointers = useCallback(() => {
    setShowQuestPointers(false);

    setSelectedQuestPointerId(null);
  }, []);

  useEffect(() => {
    if (!questPointerUserId) {
      return;
    }

    reloadQuestPointers();

    const interval = setInterval(reloadQuestPointers, REFRESH_INTERVAL_MS);

    return () => clearInterval(interval);
  }, [questId, questPointerUserId, reloadQuestPointers]);

  useEffect(() => {
    if (questPointerUserId) {
      return;
    }

    const userId = searchParams.get("userId");

    if (!userId) {
      return;
    }

    setQuestPointerUserId(userId);
  }, [questPointerUserId, searchParams]);

  useEffect(() => {
    setSearchParams({ ...searchParams, userId: questPointerUserId });
  }, [questPointerUserId]);

  return (
    <UserQuestPointerContext.Provider
      value={{
        questPointers,
        isLoading,
        hasError: Boolean(error),
        questPointerDisplayName,
        questPointerUserId,
        setQuestPointerUserId,
        selectedQuestPointerId,
        selectQuestPointer,
        updateQuestPointer: updateSelectedQuestPointer,
        deleteQuestPointer: deleteSelectedQuestPointer,
        getQuestPointerStatus,
        showQuestPointers,
        openQuestPointers,
        hideQuestPointers,
      }}
    >
      {children}
    </UserQuestPointerContext.Provider>
  );
}

export function useUserQuestPointerProvider() {
  const context = useContext(UserQuestPointerContext);

  if (context == null) {
    throw new Error("useUserQuestPointerProvider used outside of UserQuestPointerProvider");
  }

  return context;
}
