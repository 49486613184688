import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useForm } from "react-hook-form";
import { Stack } from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectAllegiance from "../../base/SelectAllegiance";
import { Allegiance } from "@worldwidewebb/quest-shared/dist/allegiance";

function AllegianceNode(props: NodeProps<NodeType<Allegiance>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const allegianceId = nodeData?.allegianceId ?? "";

  const { getValues, setValue, handleSubmit } = useForm<Allegiance>({
    defaultValues: useMemo(
      () => ({
        allegianceId,
      }),
      [allegianceId]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  return (
    <BaseNodeWithChildren {...props}>
      <form
        className={"nodrag"}
        onSubmit={handleSubmit(updateNodeData)}
        onBlur={handleSubmit(updateNodeData)}
        onChange={handleSubmit(updateNodeData)}
      >
        <Stack p={2}>
          <SelectAllegiance
            color={color}
            value={getValues("allegianceId")}
            setValue={(value) => setValue("allegianceId", value)}
          />
        </Stack>
      </form>
    </BaseNodeWithChildren>
  );
}

export default memo(AllegianceNode);
