import { KeyboardEvent, MutableRefObject, useCallback, useEffect } from "react";
import { useReactFlow } from "reactflow";
import { useCopyAndPaste } from "./useCopyAndPaste";

export const useCopyAndPasteShortcuts = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const { copy, paste } = useCopyAndPaste();
  const { getNodes, setNodes, getEdges, setEdges, fitView } = useReactFlow();

  const handleShortcutCopy = useCallback(
    async (event: Event) => {
      const { ctrlKey, metaKey, key, target } = event as unknown as KeyboardEvent;

      if (!((ctrlKey || metaKey) && key === "c")) {
        return;
      }

      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (target.closest("form")) {
        return;
      }

      await copy(getNodes(), getEdges());
    },
    [getNodes, getEdges, copy]
  );

  const handleShortcutPaste = useCallback(
    async (event: Event) => {
      const { ctrlKey, metaKey, key, target } = event as unknown as KeyboardEvent;

      if (!((ctrlKey || metaKey) && key === "v")) {
        return;
      }

      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (target.closest("form")) {
        return;
      }

      const { nodes: newNodes, edges: newEdges } = await paste();

      setNodes((nodes) => [...nodes.map((node) => ({ ...node, selected: false })), ...newNodes]);
      setEdges((edges) => [...edges.map((edge) => ({ ...edge, selected: false })), ...newEdges]);

      // TODO: investigate why this locks movement
      // setTimeout(() => fitView({ nodes: newNodes.map(({ id }) => ({ id })), duration: 1000, padding: 2 }), 500);
    },
    [setNodes, setEdges, paste]
  );

  useEffect(() => {
    ref.current?.addEventListener("keydown", handleShortcutCopy);
    ref.current?.addEventListener("keydown", handleShortcutPaste);

    return () => {
      ref.current?.removeEventListener("keydown", handleShortcutCopy);
      ref.current?.removeEventListener("keydown", handleShortcutPaste);
    };
  }, [handleShortcutCopy, handleShortcutPaste]);
};
