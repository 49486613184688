import { ChakraProps, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { memo } from "react";
import { LiaPlusSolid, LiaUndoSolid } from "react-icons/lia";
import { useSelectedQuestProvider } from "../../context/SelectedQuestProvider";

interface QuestSelectTargetProps extends ChakraProps {
  questId: string;
}

function QuestSelectTarget({ color, questId }: QuestSelectTargetProps) {
  const { selectedQuestId, changeSelectedQuest } = useSelectedQuestProvider();

  const icon = (
    <Icon as={selectedQuestId !== questId ? LiaPlusSolid : LiaUndoSolid} position={"absolute"} boxSize={12} />
  );

  return (
    <Tooltip
      label={selectedQuestId !== questId ? "add quest requirement" : "cancel"}
      bg={"theme.dark.background"}
      color={color}
      borderColor={color}
      borderWidth={2}
    >
      <IconButton
        aria-label={selectedQuestId !== questId ? "add quest requirement" : "cancel"}
        icon={icon}
        boxSize={14}
        variant={"ghost"}
        color={color}
        borderRadius={0}
        onClick={() => changeSelectedQuest(questId)}
      />
    </Tooltip>
  );
}

export default memo(QuestSelectTarget);
