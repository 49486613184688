import { useQuery } from "@tanstack/react-query";
import { AvatarWithId, Sprite } from "@worldwidewebb/client-assets";
import { NpcWithId } from "@worldwidewebb/client-npcs";
import { getNpc } from "../../api/npcs/npcs";
import { getAvatar } from "../../api/sprites/avatars";
import { getSprite } from "../../api/sprites/sprites";

export default function useNpc(npcId: string) {
  const {
    isLoading: isLoadingNpc,
    isFetching: isFetchingNpc,
    error: errorNpc,
    data: npc,
  } = useQuery<NpcWithId>({
    queryKey: ["npc", npcId],
    queryFn: () => getNpc(npcId),
  });

  const avatarId = npc?.avatarId;

  const {
    isLoading: isLoadingAvatar,
    isFetching: isFetchingAvatar,
    error: errorAvatar,
    data: avatar,
  } = useQuery<AvatarWithId | null>({
    queryKey: ["avatar", avatarId],
    queryFn: () => getAvatar(avatarId),
    enabled: !!avatarId,
  });

  const spriteId = avatar?.profileSpriteId;

  const {
    isLoading: isLoadingSprite,
    isFetching: isFetchingSprite,
    error: errorSprite,
    data: sprite,
  } = useQuery<Sprite | null>({
    queryKey: ["sprite", spriteId],
    queryFn: () => getSprite(spriteId),
    enabled: !!spriteId,
  });

  const isLoading = isLoadingNpc || isLoadingAvatar || isLoadingSprite;
  const isFetching = isFetchingNpc || isFetchingAvatar || isFetchingSprite;
  const error = errorNpc || errorAvatar || errorSprite;

  return {
    isLoading: isLoading || isFetching,
    error,
    npc,
    avatar,
    sprite,
  };
}
