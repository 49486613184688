import { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect } from "react";
import {
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
  getRectOfNodes,
  getTransformForBounds,
} from "reactflow";
import { toPng } from "html-to-image";
import { EdgeType } from "../models/edgeType";
import { NodeType } from "../models/nodeType";
import { downloadImage } from "../utils/downloadImage";

interface ReactFlowStore {
  nodes: Node<NodeType>[];
  setNodes: Dispatch<SetStateAction<Node<NodeType>[]>>;
  onNodesChange: (changes: NodeChange[]) => void;
  edges: Edge<EdgeType>[];
  setEdges: Dispatch<SetStateAction<Edge<EdgeType>[]>>;
  onEdgesChange: (changes: EdgeChange[]) => void;
  handleClickOfSelection: (event: React.MouseEvent, node: Node) => void;
  handleDownloadGraphAsImage: () => void;
  handleFitNode: (nodeId: string) => void;
}

const ReactFlowStoreContext = createContext<ReactFlowStore | null>(null);

interface ReactFlowStoreProviderProps extends PropsWithChildren {
  initialNodes: Node<NodeType>[];
  initialEdges: Edge<EdgeType>[];
}

export function ReactFlowStoreProvider({ children, initialNodes, initialEdges }: ReactFlowStoreProviderProps) {
  const [nodes, setNodes, onNodesChange] = useNodesState<NodeType>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeType>(initialEdges);

  const { screenToFlowPosition, fitView } = useReactFlow();

  const store = useStoreApi();

  const handleClickInSelection = useCallback(
    ({ target, clientX, clientY }: MouseEvent) => {
      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (!target.closest(".react-flow__nodesselection")) {
        return;
      }

      const { x: selectedX, y: selectedY } = screenToFlowPosition({ x: clientX, y: clientY });

      const selectedNode = store
        .getState()
        .getNodes()
        .find((node) => SelectedNodeSelector(node, selectedX, selectedY));

      if (selectedNode == null) {
        return;
      }

      setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: node.id === selectedNode.id,
        }))
      );

      setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );

      target.remove();
    },
    [store, setNodes, setEdges, screenToFlowPosition]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickInSelection);

    return () => window.removeEventListener("click", handleClickInSelection);
  }, [handleClickInSelection]);

  const handleClickOfSelection = useCallback(
    ({ ctrlKey, metaKey }: React.MouseEvent, { id: nodeId }: Node) => {
      if (ctrlKey || metaKey) {
        // corresponds to multiSelectionKeyCode
        return;
      }

      const selectedNodes = store
        .getState()
        .getNodes()
        .filter(({ selected }) => selected);

      if (selectedNodes.length <= 1) {
        return;
      }

      setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        }))
      );

      setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );
    },
    [store, setNodes, setEdges]
  );

  const handleDownloadGraphAsImage = useCallback(() => {
    const viewport = document.querySelector(".react-flow__viewport") as HTMLElement;

    if (viewport == null) {
      return;
    }

    const bounds = getRectOfNodes(store.getState().getNodes());
    const transform = getTransformForBounds(bounds, bounds.width, bounds.height, 0.5, 4);

    toPng(viewport, {
      backgroundColor: "transparent",
      width: bounds.width,
      height: bounds.height,
      style: {
        width: bounds.width.toString(),
        height: bounds.height.toString(),
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage);
  }, [store]);

  const handleFitNode = useCallback(
    (nodeId: string) => {
      fitView({ nodes: [{ id: nodeId }], duration: 1000 });
    },
    [fitView]
  );

  return (
    <ReactFlowStoreContext.Provider
      value={{
        nodes,
        setNodes,
        onNodesChange,
        edges,
        setEdges,
        onEdgesChange,
        handleClickOfSelection,
        handleDownloadGraphAsImage,
        handleFitNode,
      }}
    >
      {children}
    </ReactFlowStoreContext.Provider>
  );
}

export function useReactFlowStoreProvider() {
  const context = useContext(ReactFlowStoreContext);

  if (context == null) {
    throw new Error("useReactFlowStoreProvider used outside of ReactFlowStoreProvider");
  }

  return context;
}

function SelectedNodeSelector({ position: { x, y }, width, height }: Node, selectedX: number, selectedY: number) {
  return x < selectedX && selectedX < x + (width || 0) && y < selectedY && selectedY < y + (height || 0);
}
