import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { AiOutlineShake } from "react-icons/ai";

export const scribbleShake: Command = {
  category: "effect",
  icon: <Icon as={AiOutlineShake} />,
  name: "shake",
  tooltip: "tag with shake effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#shake}}", "{{/shake}}"),
};
