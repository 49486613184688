import React, { memo, useCallback, useEffect } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType, SourceHandle } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { FlowNodeWithChildren } from "./FlowNode";

interface NodeData {
  flowSourceCount: number;
}

interface SwitchNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeId: string;
  nodeData: NodeData;
  sourceHandles: SourceHandle[];
  color?: string;
}

export const SwitchNodeConfigurationModal: React.FC<SwitchNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  nodeId,
  nodeData,
  sourceHandles,
  color,
}) => {
  const flowSourceCount = nodeData?.flowSourceCount ?? 1;

  const { register, reset, handleSubmit } = useForm<NodeData>({
    defaultValues: {
      flowSourceCount,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ flowSourceCount }: NodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.flowSourceCount = flowSourceCount;

        const nodeSources = nodeDataCloned.sourceHandles ?? [];

        const sources: SourceHandle[] = Array.from({ length: flowSourceCount }, (_, index) => {
          const source = nodeSources.find(({ handleName }) => handleName === index.toString());

          if (source != null) {
            return source;
          }

          return {
            label: index.toString(),
            handleName: index.toString(),
            handleType: "source",
            handleCategory: "flow",
          };
        });

        if (sources.length === 0) {
          sources.push({
            label: "0",
            handleName: "0",
            handleType: "source",
            handleCategory: "flow",
          });
        }

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        updateNodeSourceHandles(sources);

        return nodes;
      });

      onClose();
    },
    [onClose, reactFlow, nodeId, updateNodeSourceHandles]
  );

  useEffect(() => {
    reset({
      flowSourceCount,
    });
  }, [flowSourceCount]);

  const handleCancel = useCallback(() => {
    reset({
      flowSourceCount,
    });

    onClose();
  }, [onClose, reset, flowSourceCount]);

  useEffect(() => {
    if (sourceHandles?.length) {
      return;
    }

    updateNodeSourceHandles([
      {
        label: "0",
        handleName: "0",
        handleType: "source",
        handleCategory: "flow",
      },
    ]);
  }, [sourceHandles]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Number of Outputs
                </Text>
              </FormLabel>
              <NumberInput defaultValue={1} step={1} min={1} max={16}>
                <NumberInputField
                  id={"flowSourceCount"}
                  {...register("flowSourceCount", { valueAsNumber: true, min: 1, max: 8 })}
                  color={color}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const SwitchNode: React.FC<NodeProps<NodeType>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    data: { color, nodeData = {}, sourceHandles = [] },
  } = props;

  return (
    <FlowNodeWithChildren {...props}>
      <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
        Configure
      </Button>

      <SwitchNodeConfigurationModal
        isOpen={isOpen}
        onClose={onClose}
        nodeId={nodeId}
        nodeData={nodeData as NodeData}
        sourceHandles={sourceHandles}
        color={color}
      />
    </FlowNodeWithChildren>
  );
};

export default memo(SwitchNode);
