import { Spinner, Tag, Text } from "@chakra-ui/react";
import { memo } from "react";
import useSkill from "../../hooks/skills/useSkill";

interface SkillWithLevelComponentProps {
  skillId: string;
  level: number;
}

function SkillWithLevelComponent({ skillId, level }: SkillWithLevelComponentProps) {
  const { isLoading, error, skill } = useSkill(skillId);

  if (isLoading) {
    return <Spinner size={"md"} color={"white"} />;
  }

  if (error || skill == null) {
    return <Spinner size={"md"} color={"red.800"} />;
  }

  const { displayName, category } = skill;

  return (
    <Tag p={2}>
      <Text color={"white"} casing={"uppercase"}>
        {displayName} - {category} ({level})
      </Text>
    </Tag>
  );
}

export default memo(SkillWithLevelComponent);
