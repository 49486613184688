import { useQuery } from "@tanstack/react-query";
import { ItemIntrinsicWithName } from "@worldwidewebb/client-assets";
import { getItems } from "../../api/items/items";

export default function useItems() {
  const {
    isLoading,
    isFetching,
    error,
    data: items,
  } = useQuery<ItemIntrinsicWithName[]>({
    queryKey: ["item"],
    queryFn: () => getItems(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    items,
  };
}
