import { useQuery } from "@tanstack/react-query";
import { getGuis, Gui } from "../../api/guis/guis";

export default function useGuis() {
  const {
    isLoading,
    isFetching,
    error,
    data: guis,
  } = useQuery<Gui[]>({
    queryKey: ["gui"],
    queryFn: () => getGuis(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    guis,
  };
}
