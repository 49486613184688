import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import { StartNodeWithChildren } from "./StartNode";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import SelectLocation from "../../base/SelectLocation";
import { Location } from "../../../models/api/location";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Controller, useForm } from "react-hook-form";
import SelectSprite from "../../base/SelectSprite";

interface FormData extends Location {
  spriteId: string;
  entityIndicator: string;
  entityIndexZ: number;
}

const StartWithSpriteObjectNode: React.FC<NodeProps<NodeType<FormData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const spriteId = nodeData?.spriteId ?? "";
  const entityIndicator = nodeData?.entityIndicator ?? "none";
  const entityIndexZ = nodeData?.entityIndexZ ?? 0;

  const roomName = nodeData?.roomName ?? "";
  const x = nodeData?.x ?? 0;
  const y = nodeData?.y ?? 0;
  const radius = nodeData?.radius ?? 10;

  const { getValues, setValue, control, handleSubmit } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        spriteId,
        entityIndicator,
        entityIndexZ,
      }),
      [spriteId, entityIndicator, entityIndexZ]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<FormData>(nodeId);

  const handleUpdate = useCallback(
    ({ spriteId, entityIndicator, entityIndexZ }: Omit<FormData, keyof Location>) => {
      updateNodeData({ spriteId, entityIndicator, entityIndexZ });
    },
    [updateNodeData]
  );

  const handleUpdateLocation = useCallback(
    ({ roomName, x, y, radius }: Location) => {
      updateNodeData({
        roomName,
        x,
        y,
        radius,
      });
    },
    [updateNodeData]
  );

  return (
    <StartNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <SelectSprite
            color={color}
            value={getValues("spriteId")}
            setValue={(value) => setValue("spriteId", value)}
          />

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Entity Indicator
              </Text>
            </FormLabel>
            <SelectNpcIndicator
              value={getValues("entityIndicator")}
              setValue={(value) => setValue("entityIndicator", value)}
              color={color}
              showLabel={false}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Entity Index Z
              </Text>
            </FormLabel>
            <Controller
              name={"entityIndexZ"}
              control={control}
              render={({ field: { ref, value, onChange, onBlur, name } }) => (
                <NumberInput
                  value={value}
                  defaultValue={0}
                  name={name}
                  step={1}
                  ref={ref}
                  onChange={(value) => onChange(Number(value))}
                  onBlur={onBlur}
                >
                  <NumberInputField color={color} borderColor={color} borderRadius={0} borderWidth={2} />
                  <NumberInputStepper>
                    <NumberIncrementStepper color={color} />
                    <NumberDecrementStepper color={color} />
                  </NumberInputStepper>
                </NumberInput>
              )}
            />
          </FormControl>

          <SelectLocation value={{ roomName, x, y, radius }} setValue={handleUpdateLocation} color={color} />
        </Stack>
      </form>
    </StartNodeWithChildren>
  );
};

export default memo(StartWithSpriteObjectNode);
