import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

interface ChangePointerPriority extends NodeData {
  priority: number;
}

function ChangePointerPriorityNode(props: NodeProps<NodeType<ChangePointerPriority>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const priority = nodeData?.priority ?? 0;

  const { control, handleSubmit } = useForm<ChangePointerPriority>({
    defaultValues: useMemo(
      () => ({
        priority,
      }),
      [priority]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<ChangePointerPriority>(nodeId);

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
        <FormControl>
          <FormLabel>
            <Text casing={"uppercase"} color={color}>
              Priority
            </Text>
          </FormLabel>
          <Controller
            name={"priority"}
            control={control}
            render={({ field: { ref, value, onChange, onBlur, name } }) => (
              <NumberInput
                value={value}
                defaultValue={0}
                name={name}
                step={1}
                ref={ref}
                onChange={(value) => onChange(Number(value))}
                onBlur={onBlur}
              >
                <NumberInputField color={color} borderColor={color} borderRadius={0} borderWidth={2} />
                <NumberInputStepper>
                  <NumberIncrementStepper color={color} />
                  <NumberDecrementStepper color={color} />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
        </FormControl>
      </form>
    </FlowNodeWithChildren>
  );
}

export default memo(ChangePointerPriorityNode);
