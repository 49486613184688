import React, { memo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { FlowNodeWithChildren } from "./FlowNode";

const NpcSetSpriteNode: React.FC<NodeProps<NodeType>> = (props) => {
  // TODO: TECH-3955 (assets service integration w/ sprites)

  return <FlowNodeWithChildren {...props}></FlowNodeWithChildren>;
};

export default memo(NpcSetSpriteNode);
