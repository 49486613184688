import { QuestEditorApi, QuestEditorCommitWithId } from "@worldwidewebb/client-quests";
import { decodeTime } from "ulid";
import { axiosInstance, buildQuestClientBasePath } from "..";
import { QuestWithId } from "../../models/api/quest";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuestCommits(questId: string): Promise<Omit<QuestEditorCommitWithId, "quest">[]> {
  try {
    const { data: commits } = await questEditorApi.getQuestEditorCommits(questId);

    return commits.sort(({ version: a }, { version: b }) => decodeTime(b) - decodeTime(a));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getQuestCommit(questId: string, questEditorCommitId: string): Promise<QuestEditorCommitWithId> {
  try {
    const { data: commit } = await questEditorApi.getQuestEditorCommit(questId, questEditorCommitId);

    return commit;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateQuestCommit(
  questId: string,
  questEditorCommitId: string,
  questEditorCommitPartial: Omit<
    QuestEditorCommitWithId,
    "questEditorCommitId" | "quest" | "questId" | "version" | "userId"
  >
): Promise<QuestEditorCommitWithId> {
  try {
    const { data: commit } = await questEditorApi.updateQuestEditorCommit(
      questId,
      questEditorCommitId,
      questEditorCommitPartial
    );

    return commit;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
