import { Box, Icon, useDisclosure, useToken } from "@chakra-ui/react";
import { NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { toPng } from "html-to-image";
import { memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BiSolidInfoCircle } from "react-icons/bi";
import { LiaHandPaperSolid, LiaHandPointer } from "react-icons/lia";
import { TiImageOutline } from "react-icons/ti";
import {
  applyEdgeChanges,
  applyNodeChanges,
  ControlButton,
  Edge,
  EdgeChange,
  getRectOfNodes,
  getTransformForBounds,
  Node,
  NodeChange,
  ReactFlow,
  ReactFlowProps,
  SelectionMode,
  useReactFlow,
} from "reactflow";
import { useUserSettingsProvider } from "../../../context/UserSettingsContext";
import { EdgeType } from "../../../models/edgeType";
import ControlSchemeInfo from "../../reactFlow/ControlSchemeInfo";
import { edgeTypes } from "../../reactFlow/edgeTypes";
import { nodeTypes } from "../../reactFlow/nodeTypes";
import StyledBackground from "../../reactFlow/StyledBackground";
import StyledControls from "../../reactFlow/StyledControls";
import StyledMiniMap from "../../reactFlow/StyledMiniMap";
import { useCopyAndPasteShortcuts } from "../../../hooks/useCopyAndPasteShortcuts";

const downloadImage = (dataUrl: string) => {
  const a = document.createElement("a");

  a.setAttribute("download", "quests.png");
  a.setAttribute("href", dataUrl);
  a.click();
  a.remove();
};

interface QuestCommitPreviewProps {
  initialNodes: Node<NodeType>[];
  initialEdges: Edge<EdgeType>[];
  initialNodeTypes: NodeType[];
}

function QuestCommitPreview({ initialNodes, initialEdges, initialNodeTypes }: QuestCommitPreviewProps) {
  const [nodes, setNodes] = useState<Node<NodeType>[]>(initialNodes);
  const [edges, setEdges] = useState<Edge<EdgeType>[]>(initialEdges);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useCopyAndPasteShortcuts(wrapperRef);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((changedNodes) => applyNodeChanges(changes, changedNodes));
  }, []);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((changedEdges) => applyEdgeChanges(changes, changedEdges));
  }, []);

  const reactFlow = useReactFlow();

  const { screenToFlowPosition } = useReactFlow();

  const handleClickNodeInSelectionRectangle = useCallback(
    (event: Event) => {
      const { target, clientX, clientY } = event as unknown as MouseEvent;

      if (!(target instanceof HTMLElement)) {
        return;
      }

      // noinspection SpellCheckingInspection
      if (!target.closest(".react-flow__nodesselection")) {
        return;
      }

      const { x: clickX, y: clickY } = screenToFlowPosition({ x: clientX, y: clientY });

      const selectedNodes = reactFlow?.getNodes().filter(({ selected }) => selected) ?? [];
      const [clickedNode] = selectedNodes.filter(
        ({ position: { x, y }, width, height }) =>
          x <= clickX && clickX < x + (width || 0) && y <= clickY && clickY < y + (height || 0)
      );

      if (clickedNode == null) {
        return;
      }

      reactFlow?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: clickedNode.id === node.id,
        }))
      );

      reactFlow?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );

      target.remove();
    },
    [reactFlow, screenToFlowPosition]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickNodeInSelectionRectangle);

    return () => {
      window.removeEventListener("click", handleClickNodeInSelectionRectangle);
    };
  }, [handleClickNodeInSelectionRectangle]);

  const handleClickNodeInSelection = useCallback(
    ({ ctrlKey, metaKey }: MouseEvent, { id: nodeId }: Node) => {
      // corresponds to multiSelectionKeyCode
      if (ctrlKey || metaKey) {
        return;
      }

      const selectedNodes = reactFlow?.getNodes().filter(({ selected }) => selected) ?? [];

      if (selectedNodes.length <= 1) {
        return;
      }

      reactFlow?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        }))
      );

      reactFlow?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );
    },
    [reactFlow]
  );

  const nodeColors = useToken(
    "colors",
    initialNodeTypes.map(({ color }) => color ?? "white")
  );

  const nodeColorDictionary = useMemo(
    () => Object.fromEntries(initialNodeTypes.map(({ color }, index) => [color ?? "white", nodeColors[index]])),
    [initialNodeTypes, nodeColors]
  );

  const getNodeColor = useCallback(
    (colorToken?: string) => {
      if (colorToken == null) {
        return "#FFF";
      }

      return nodeColorDictionary[colorToken];
    },
    [nodeColorDictionary]
  );

  const { controlScheme, toggleControlScheme } = useUserSettingsProvider();

  const navigationConfig: ReactFlowProps = useMemo(
    () =>
      controlScheme === "primary"
        ? {
            multiSelectionKeyCode: ["Meta", "Control"],
            panActivationKeyCode: "Shift",
            panOnDrag: [1],
            selectionKeyCode: null,
            selectionOnDrag: true,
          }
        : {
            panOnScroll: true,
            selectionKeyCode: ["Meta", "Control"],
          },
    [controlScheme]
  );

  const { onClose, onOpen, isOpen } = useDisclosure();

  const onDownloadImage = useCallback(() => {
    const nodesBounds = getRectOfNodes(reactFlow.getNodes());
    const transform = getTransformForBounds(nodesBounds, nodesBounds.width, nodesBounds.height, 0.5, 4);

    const viewport = document.querySelector(".react-flow__viewport") as HTMLElement;

    if (viewport == null) {
      return;
    }

    toPng(viewport, {
      backgroundColor: "transparent",
      width: nodesBounds.width,
      height: nodesBounds.height,
      style: {
        width: nodesBounds.width.toString(),
        height: nodesBounds.height.toString(),
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage);
  }, [getRectOfNodes, reactFlow]);

  return (
    <>
      <Box h={"70vh"} w={"100%"} ref={wrapperRef} tabIndex={0}>
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          proOptions={{ hideAttribution: true }}
          deleteKeyCode={[]}
          selectionMode={SelectionMode.Partial}
          onNodeClick={handleClickNodeInSelection}
          minZoom={0.125}
          maxZoom={1}
          {...navigationConfig}
        >
          <StyledBackground />
          <StyledControls position="top-right" showInteractive={false}>
            <ControlButton onClick={onDownloadImage}>
              <Icon as={TiImageOutline} />
            </ControlButton>
            <ControlButton onClick={onOpen}>
              <Icon as={BiSolidInfoCircle} />
            </ControlButton>
            <ControlButton onClick={toggleControlScheme}>
              <Icon as={controlScheme === "primary" ? LiaHandPointer : LiaHandPaperSolid} />
            </ControlButton>
          </StyledControls>
          <StyledMiniMap
            position="bottom-right"
            pannable={true}
            zoomable={true}
            nodeColor={(node: Node<NodeType>) => getNodeColor(node.data.color)}
          />
        </ReactFlow>

        <ControlSchemeInfo isOpen={isOpen} onClose={onClose} />
      </Box>
    </>
  );
}

export default memo(QuestCommitPreview);
