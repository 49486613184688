import { AssetsApi, ItemType } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getItemTypes(): Promise<ItemType[]> {
  try {
    const { data: itemTypes } = await assetsApi.getItemTypes();

    return itemTypes;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
