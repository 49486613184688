import { WorldApi } from "@worldwidewebb/client-world";
import { axiosInstance, buildApiClientBasePath } from "..";

const worldApi = new WorldApi(undefined, buildApiClientBasePath("world"), axiosInstance);

export async function getDoors(worldId: string) {
  try {
    const { data: doors } = await worldApi.getDoors(worldId);

    return doors.sort(({ doorId: a }, { doorId: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
