import React, { PropsWithChildren } from "react";

const nestProviders = (children: React.ReactNode, component: React.ReactElement) => {
  return React.cloneElement(component, {}, children);
};

interface MultiProviderProps extends PropsWithChildren {
  providers: React.ReactElement[];
}

const MultiProvider: React.FC<MultiProviderProps> = ({ providers, children }) => {
  return <>{providers.reduceRight(nestProviders, children)}</>;
};

export default MultiProvider;
