import { QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function createMixpanelAnnotation(version: string, displayName: string) {
  try {
    await questEditorApi.createMixpanelAnnotation(version, { displayName });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createMixpanelAnnotationIfOnProd(version: string, displayName: string) {
  if (window.WEBB_CONFIG && window.WEBB_CONFIG.ENV !== "prod") {
    return;
  }

  await createMixpanelAnnotation(version, displayName);
}
