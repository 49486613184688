import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";

type Direction = "up" | "down" | "left" | "right";

const directions: Direction[] = ["up", "down", "left", "right"];

interface SelectDirectionProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectDirection: React.FC<SelectDirectionProps> = ({ color, value, setValue, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return directions
      .filter((direction) => (searchQuery ? direction.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true))
      .sort((a, b) => a.localeCompare(b))
      .map((direction) => [direction, direction]);
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Direction"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectDirection;
