import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { insertText } from "../utils/insertText";

export const scribbleSetScale: Command = {
  category: "transform",
  icon: <Icon as={AiOutlinePlus} />,
  name: "scale",
  tooltip: "tag with scale effect",
  execute: (editorRef) => insertText(editorRef, "{{#scale}}2{{/scale}}"),
};
