import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  Center,
  ChakraProps,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { CHUNK_H, CHUNK_W } from "../../models/api/location";
import SelectRoom from "./SelectRoom";
import { MdLocationPin } from "react-icons/md";
import ImageFrame from "./ImageFrame";
import { ChunkFeature, ChunkWithId } from "@worldwidewebb/client-world";
import ReactFlow, { Node, NodeProps, ReactFlowProvider, SelectionMode, useNodesState, useReactFlow } from "reactflow";
import Background from "../../export/reactflow/components/base/Background";
import Controls from "../../export/reactflow/components/base/Controls";
import MiniMap from "../../export/reactflow/components/base/MiniMap";
import { ulid } from "ulid";
import { useToken } from "@chakra-ui/system";
import useRoom from "../../hooks/rooms/useRoom";
import { NodeType } from "../../models/nodeType";
import useEntities from "../../hooks/entities/useEntities";
import { MapEntity } from "../reactFlow/nodeTypes/MapEntityNode";
import Tag from "../../ui/base/chakra/Tag";
import useEntity from "../../hooks/entities/useEntity";

const BackgroundNode: React.FC<NodeProps> = ({ data: { chunks } }) => {
  return <LocationRender chunks={chunks} />;
};

const LocationNode: React.FC<NodeProps> = ({
  data: {
    color,
    nodeData: { featureType, featureName },
  },
}) => {
  return (
    <Box position={"relative"} bg={color} color={color} p={2}>
      <Tag position={"absolute"} top={-1} right={12} minW={"max-content"} color={color}>
        {featureType} - {featureName}
      </Tag>
    </Box>
  );
};

const nodeTypes = {
  LocationNode,
  BackgroundNode,
};

interface LocationCanvasProps extends ChakraProps {
  chunkFeatures: ChunkFeature[];
  chunks: ChunkWithId[];
  worldId: string;
  featureId: string;
  setFeatureId: (featureId: string) => void;
}

const LocationCanvas: React.FC<LocationCanvasProps> = ({ chunkFeatures, chunks, worldId, featureId, setFeatureId }) => {
  const [colorRed, colorGreen] = useToken("colors", ["red.800", "green.600"]);

  const nodeColors: Record<string, string> = {
    "red.800": colorRed,
    "green.600": colorGreen,
  };

  const coordinates = useMemo(
    () =>
      chunks.flatMap(({ layers, coordinates }) =>
        layers.map(({ dx, dy }) => ({ x: coordinates[0] * CHUNK_W + dx, y: coordinates[1] * CHUNK_H + dy }))
      ),
    [chunks]
  );

  const { w, h } = useMemo(() => {
    const minX = Math.min(...coordinates.map(({ x }) => x));
    const maxX = Math.min(...coordinates.map(({ x }) => x + CHUNK_W));

    const minY = Math.min(...coordinates.map(({ y }) => y));
    const maxY = Math.min(...coordinates.map(({ y }) => y + CHUNK_H));

    return {
      w: maxX - minX,
      h: maxY - minY,
    };
  }, [coordinates]);

  const radius = 16;

  const backgroundNode: Node = {
    id: ulid(),
    type: "BackgroundNode",
    data: {
      chunks,
    },
    position: { x: 0, y: 0 },
    style: {
      width: w,
      height: h,
      zIndex: 0,
    },
    selectable: false,
    draggable: false,
  };

  const featureNodes: Node[] = useMemo(
    () =>
      chunkFeatures.map((chunkFeature) => ({
        id: ulid(),
        type: "LocationNode",
        data: {
          color: chunkFeature.featureId === featureId ? "green.600" : "red.800",
          nodeData: {
            featureId: chunkFeature.featureId,
            featureType: chunkFeature.type,
            featureName: chunkFeature.name,
            worldId,
          },
        },
        position: { x: chunkFeature.coordinates[0], y: chunkFeature.coordinates[1] },
        style: {
          width: radius * 2,
          height: radius * 2,
          zIndex: 1,
        },
        selectable: true,
        draggable: false,
      })),
    [chunkFeatures, featureId, worldId]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([backgroundNode]);

  useEffect(() => {
    setNodes((nodes) => [...nodes.filter(({ type }) => type === "BackgroundNode"), ...featureNodes]);
  }, [featureNodes]);

  const handleNodeClick = useCallback(
    (node: Node<NodeType>) => {
      if (node.type === "BackgroundNode") {
        return;
      }

      const {
        data: {
          nodeData: { featureId: selectedFeatureId },
        },
      } = node;

      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.type === "BackgroundNode") {
            return node;
          }

          const {
            data: {
              nodeData: { featureId, worldId },
            },
          } = node;

          node.data.color = featureId === selectedFeatureId ? "green.600" : "red.800";

          return node;
        })
      );

      setFeatureId(selectedFeatureId);
    },
    [setFeatureId, worldId]
  );

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      proOptions={{ hideAttribution: true }}
      multiSelectionKeyCode={["Meta", "Control"]}
      deleteKeyCode={["Backspace", "Delete"]}
      selectionKeyCode={null}
      selectionMode={SelectionMode.Partial}
      panOnDrag={[1]}
      panActivationKeyCode={"Shift"}
      onNodeClick={(_, node) => handleNodeClick(node)}
      selectionOnDrag={true}
      minZoom={0.125}
      maxZoom={2}
    >
      <Background />
      <Controls position={"top-right"} showInteractive={false} />
      <MiniMap
        position={"bottom-right"}
        pannable={true}
        zoomable={true}
        nodeColor={({ type, data: { color } }) => (type === "LocationNode" ? nodeColors[color] : "transparent")}
      />
    </ReactFlow>
  );
};

interface LocationRenderProps extends BoxProps {
  chunks: ChunkWithId[];
}

const LocationRender: React.FC<LocationRenderProps> = ({ chunks, children }) => {
  return (
    <Box position={"relative"}>
      <>
        {chunks.flatMap(({ layers, coordinates }, index$1) =>
          layers.map((layer, index$2) => {
            const { imageData: src, frameTimes, dx, dy, dz } = layer;
            const top = `${coordinates[1] * CHUNK_H + dy}px`;
            const left = `${coordinates[0] * CHUNK_W + dx}px`;
            const zIndex = -dz;

            return (
              <ImageFrame
                key={`${index$1} ${index$2}`}
                src={src}
                frameTimes={frameTimes}
                position={"absolute"}
                top={top}
                left={left}
                zIndex={zIndex}
              />
            );
          })
        )}

        {children}
      </>
    </Box>
  );
};

interface FeaturePickerProps extends ChakraProps {
  isOpen: boolean;
  onClose: () => void;
  worldId: string;
  featureId: string;
  setFeatureId: (featureId: string) => void;
}

const FeaturePicker: React.FC<FeaturePickerProps> = ({ color, isOpen, onClose, worldId, featureId, setFeatureId }) => {
  const { chunks } = useRoom(worldId);
  const { isLoading, error, entities = [] } = useEntities(worldId);

  const [currentFeatureId, setCurrentFeatureId] = useState<string>(featureId);
  const { entity } = useEntity(worldId, currentFeatureId);

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(() => {
    setFeatureId(currentFeatureId);

    onClose();
  }, [currentFeatureId]);

  useEffect(() => {
    setTimeout(() => reactFlow.fitView(), 100);
  }, [reactFlow]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />

      <ModalContent bg={"theme.dark.background"}>
        <ModalHeader>
          <Text color={color} casing={"uppercase"}>
            Select an entity {entity && `(${entity.type} - ${entity.name})`}
          </Text>
        </ModalHeader>

        <ModalCloseButton color={color} />

        <ModalBody display={"flex"}>
          <Box
            flexGrow={1}
            borderColor={color}
            borderWidth={1}
            sx={{
              "::-webkit-scrollbar": {
                width: 2,
                height: 2,
                bg: "transparent",
              },
              "::-webkit-scrollbar-track": {
                bg: "transparent",
              },
              "::-webkit-scrollbar-thumb": {
                bg: color,
              },
              "::-webkit-scrollbar-corner": {
                bg: color,
              },
            }}
            overflow={"auto"}
          >
            {isLoading && (
              <Center w={"100%"} h={"100%"}>
                <Spinner color={color?.toString() ?? ""} />
              </Center>
            )}
            {!!error && (
              <Center w={"100%"} h={"100%"}>
                <Text color={color} casing={"uppercase"}>
                  Failed to load world information
                </Text>
              </Center>
            )}
            {!isLoading && !error && (
              <LocationCanvas
                color={color}
                chunkFeatures={entities}
                chunks={chunks}
                worldId={worldId}
                featureId={featureId}
                setFeatureId={setCurrentFeatureId}
              />
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onClose} variant={"outline"} borderColor={color} borderRadius={0}>
              <Text color={color} casing={"uppercase"}>
                Cancel
              </Text>
            </Button>
            <Button onClick={handleUpdate} variant={"outline"} borderColor={color} borderRadius={0}>
              <Text color={color} casing={"uppercase"}>
                Update
              </Text>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface SelectMapEntityProps extends ChakraProps {
  value: MapEntity | undefined;
  setValue: (value: MapEntity) => void;
}

const SelectMapEntity: React.FC<SelectMapEntityProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [worldId, setWorldId] = useState<string>(value?.worldId ?? "");
  const [entityId, setEntityId] = useState<string>(value?.entityId ?? "");

  useEffect(() => {
    setValue({
      entityId,
      worldId: worldId,
    });
  }, [entityId, worldId]);

  return (
    <ReactFlowProvider>
      <Stack {...chakraProps}>
        <HStack alignItems={"end"}>
          <SelectRoom
            value={worldId}
            setValue={(value) => setWorldId(value)}
            color={color}
            flexGrow={1}
            flexShrink={0}
          />

          {worldId !== "" && (
            <>
              <Tooltip
                bg={"theme.dark.background"}
                borderColor={color}
                borderRadius={0}
                borderWidth={1}
                color={color}
                label={"select entity"}
                placement={"right"}
              >
                <IconButton
                  borderColor={color}
                  borderRadius={0}
                  borderWidth={2}
                  color={color}
                  variant={"outline"}
                  icon={<Icon as={MdLocationPin} />}
                  aria-label={"select entity"}
                  onClick={onOpen}
                />
              </Tooltip>

              <FeaturePicker
                color={color}
                isOpen={isOpen}
                onClose={onClose}
                worldId={worldId}
                featureId={entityId}
                setFeatureId={setEntityId}
              />
            </>
          )}
        </HStack>
      </Stack>
    </ReactFlowProvider>
  );
};

export default SelectMapEntity;
