import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Flex, FormControl, Icon, IconButton, Input, Text, TextProps, Tooltip } from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";

interface TextEditForm {
  value: string;
}

interface TextEditProps extends TextProps {
  value: string;
  onUpdate?: (value: string) => void;
}

export const TextEdit: React.FC<TextEditProps> = ({ size = "md", color = "white", value, onUpdate, ...textProps }) => {
  const { reset, register, handleSubmit, setFocus, setValue } = useForm<TextEditForm>({
    mode: "onBlur",
  });

  const handleUpdate = useCallback(
    ({ value }: TextEditForm) => {
      if (onUpdate) {
        onUpdate(value);
      }

      setIsEditing(false);
    },
    [onUpdate]
  );

  const handleStartEditingConditionally = useCallback(({ detail: clickCount }: React.MouseEvent<HTMLElement>) => {
    if (clickCount !== 2) {
      return;
    }

    setIsEditing(true);
  }, []);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = useCallback(() => {
    setFocus("value");
  }, [setFocus]);

  const resetEditing = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (!isEditing) {
      resetEditing();
    } else {
      startEditing();
    }
  }, [isEditing]);

  useEffect(() => {
    setValue("value", value);
  }, [isEditing]);

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      {isEditing ? (
        <FormControl>
          <Input
            id={"value"}
            isRequired
            {...register("value")}
            onBlur={handleSubmit(handleUpdate)}
            size={size}
            color={color}
            {...textProps}
          />
        </FormControl>
      ) : (
        <Flex gap={2} alignItems={"center"}>
          <Tooltip label={"double click to edit"}>
            <Text mx={4} color={color} userSelect={"none"} onClick={handleStartEditingConditionally} {...textProps}>
              {value}
            </Text>
          </Tooltip>
          <Tooltip label={"edit"}>
            <IconButton
              size={size}
              color={color}
              icon={<Icon as={MdEdit} />}
              aria-label={"edit"}
              onClick={() => setIsEditing(true)}
            />
          </Tooltip>
        </Flex>
      )}
    </form>
  );
};
