import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { MdRedo } from "react-icons/md";
import { redo as handleRedo } from "@codemirror/commands";

export const redo: Command = {
  category: "general",
  icon: <Icon as={MdRedo} />,
  name: "redo",
  tooltip: "redo",
  execute: ({ view }) => view && handleRedo(view),
};
