import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QuestEditorFolderWithId } from "@worldwidewebb/client-quests";
import { createQuestFolder as createQuestFolderAsync } from "../../api/quests/questFolders";

export default function useCreateQuestFolder() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: createQuestFolder } = useMutation({
    mutationFn: (questEditorFolder: QuestEditorFolderWithId) => createQuestFolderAsync(questEditorFolder),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questFolder"],
      });

      toast({ title: "Quest folder create successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest folder create unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    createQuestFolder,
  };
}
