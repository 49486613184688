import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { ReactFlowProps } from "reactflow";

export type ControlScheme = "primary" | "secondary";

function getUserControlScheme(): ControlScheme {
  return (localStorage.getItem("controlScheme") as ControlScheme | null | undefined) ?? "primary";
}

function setUserControlScheme(controlScheme: ControlScheme) {
  localStorage.setItem("controlScheme", controlScheme);
}

const controlSchemes: Record<ControlScheme, ReactFlowProps> = {
  primary: {
    multiSelectionKeyCode: ["Meta", "Control"],
    panActivationKeyCode: "Shift",
    panOnDrag: [1],
    selectionKeyCode: null,
    selectionOnDrag: true,
  },
  secondary: {
    multiSelectionKeyCode: ["Meta", "Control"],
    panOnScroll: true,
    selectionKeyCode: ["Meta", "Control"],
  },
};

function getReactFlowProps(controlScheme: ControlScheme): ReactFlowProps {
  return controlSchemes[controlScheme];
}

interface User {
  controlScheme: ControlScheme;
  toggleControlScheme: () => void;
  reactFlowProps: ReactFlowProps;
}

const UserContext = createContext<User | null>(null);

export function UserProvider({ children }: PropsWithChildren) {
  const [controlScheme, setControlScheme] = useState<ControlScheme>(getUserControlScheme());

  const toggleControlScheme = useCallback(() => {
    setControlScheme((controlScheme) => {
      const updatedControlScheme = controlScheme === "primary" ? "secondary" : "primary";

      setUserControlScheme(updatedControlScheme);

      return updatedControlScheme;
    });
  }, []);

  const reactFlowProps = useMemo(() => getReactFlowProps(controlScheme), [controlScheme]);

  return (
    <UserContext.Provider value={{ controlScheme, toggleControlScheme, reactFlowProps }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserProvider() {
  const context = useContext(UserContext);

  if (context == null) {
    throw new Error("useUserProvider used outside of UserProvider");
  }

  return context;
}
