import { QuestEditorApi, QuestSubgraph, QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

const useQuests = () => {
  const getSubgraphs = useCallback(async () => {
    try {
      const response = await questEditorApi.getQuestEditorQuestSubgraphs();
      const { data: subgraphs } = response;

      return subgraphs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const getSubgraph = useCallback(async (subgraphId: string) => {
    try {
      const response = await questEditorApi.getQuestEditorQuestSubgraph(subgraphId);
      const { data: subgraph } = response;

      return subgraph;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 404) {
        return null;
      }

      console.error(error);
      throw error;
    }
  }, []);

  const setSubgraph = useCallback(async (subgraphId: string, subgraph: QuestSubgraph): Promise<QuestSubgraphWithId> => {
    try {
      const response = await questEditorApi.setQuestEditorQuestSubgraph(subgraphId, subgraph);
      const { data: subgraphWithId } = response;

      return subgraphWithId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  const deleteSubgraph = useCallback(async (subgraphId: string) => {
    try {
      await questEditorApi.deleteQuestEditorQuestSubgraph(subgraphId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, []);

  return {
    getSubgraphs,
    getSubgraph,
    setSubgraph,
    deleteSubgraph,
  };
};

export default useQuests;
