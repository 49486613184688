import { QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function generateVoices(questId: string): Promise<void> {
  try {
    await questEditorApi.generateVoicesForQuest(questId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
