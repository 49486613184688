import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { PiPulseLight } from "react-icons/pi";

export const scribblePulse: Command = {
  category: "effect",
  icon: <Icon as={PiPulseLight} />,
  name: "pulse",
  tooltip: "tag with pulse effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#pulse}}", "{{/pulse}}"),
};
