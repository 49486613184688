import {
  Button,
  Tooltip,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { QuestEditorSessionWithId } from "@worldwidewebb/client-quests";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router";
import useCreateQuestEditorSession from "../hooks/sessions/useCreateQuestEditorSession";
import useDeleteQuestEditorSession from "../hooks/sessions/useDeleteQuestEditorSession";
import useQuestEditorSessions from "../hooks/sessions/useQuestEditorSessions";
import useSessionProfile from "../hooks/users/useSessionProfile";
import useProfile from "../hooks/users/useProfile";
import Timer from "./base/Timer";

const UserSessionControl = () => {
  const { id: questId = "" } = useParams();
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen: true });

  const { sessionProfile } = useSessionProfile();
  const sessionUserId = sessionProfile?.userId;

  const { questEditorSessions = [] } = useQuestEditorSessions(questId);
  const [questEditorLock] = questEditorSessions;

  const { profile } = useProfile(questEditorLock?.userId);
  const questEditorLockProfileDisplayName = profile?.displayName;

  const { isCreating, createQuestEditorSession } = useCreateQuestEditorSession();
  const { isDeleting, deleteQuestEditorSession } = useDeleteQuestEditorSession();

  const [isLockedSessionDismissed, setIsLockedSessionDismissed] = useState<boolean>(false);

  const handleCreateSession = useCallback(() => {
    if (questId == null) {
      return;
    }

    createQuestEditorSession(questId);
  }, [questId, createQuestEditorSession]);

  const handleDeleteSession = useCallback(() => {
    if (questId == null) {
      return;
    }

    deleteQuestEditorSession(questId);
  }, [questId, deleteQuestEditorSession]);

  const handleClose = useCallback(() => {
    setIsLockedSessionDismissed(true);

    onClose();
  }, [onClose]);

  useEffect(() => {
    if (questEditorLock == null) {
      setIsLockedSessionDismissed(false);
    }

    if (questEditorLock != null && questEditorLock.userId !== sessionUserId) {
      !isLockedSessionDismissed && onOpen();
    }
  }, [questEditorLock, questEditorLock, sessionUserId, onOpen]);

  if (sessionUserId == null) {
    return null;
  }

  if (questEditorLock == null) {
    return (
      <Tooltip label={"create locked session"}>
        <Button onClick={handleCreateSession} isLoading={isCreating}>
          <Text color={"green.600"} casing={"uppercase"}>
            Create Locked Session
          </Text>
        </Button>
      </Tooltip>
    );
  }

  if (questEditorLock != null && questEditorLock.userId === sessionUserId) {
    return (
      <Tooltip label={"delete locked session"}>
        <Button onClick={handleDeleteSession} isLoading={isDeleting}>
          <Text color={"red.800"} casing={"uppercase"}>
            Delete Locked Session
          </Text>

          <Timer color={"red.800"} time={new Date(questEditorLock.expiresAt)} />
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <>
        <Button>
          <Text color={"yellow.600"} casing={"uppercase"}>
            Locked by {questEditorLockProfileDisplayName}
          </Text>

          <Timer color={"yellow.600"} time={new Date(questEditorLock.expiresAt)} />
        </Button>

        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />

          <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"yellow.600"}>
            <ModalHeader bg={"yellow.600"}>
              <Heading size={"md"}>
                <Text color={"white"}>Locked by {questEditorLockProfileDisplayName}</Text>
              </Heading>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody p={12}>
              <Text color={"white"}>Saving will fail because it is locked by {questEditorLockProfileDisplayName}</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
};

export default UserSessionControl;
