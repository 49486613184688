import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useSkills from "../../hooks/skills/useSkills";

interface SelectSkillProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectSkill: React.FC<SelectSkillProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, skills = [] } = useSkills();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return skills
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ displayName, skillId }) => [skillId, `${displayName} (${skillId})`]);
    },
    [skills]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Skill"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectSkill;
