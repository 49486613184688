import { WorldApi } from "@worldwidewebb/client-world";
import { axiosInstance, buildApiClientBasePath } from "..";

const worldApi = new WorldApi(undefined, buildApiClientBasePath("world"), axiosInstance);

export async function getEntities(worldId: string) {
  try {
    const { data: entities } = await worldApi.getEntities(worldId);

    return entities.sort(({ featureId: a }, { featureId: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
