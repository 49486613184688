import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { MdAccountCircle } from "react-icons/md";
import { insertText } from "../utils/insertText";

export const insertPlayerName: Command = {
  category: "game value",
  icon: <Icon as={MdAccountCircle} />,
  name: "player name",
  tooltip: "insert player username",
  execute: (editorRef) => insertText(editorRef, "{{playerName}}"),
};
