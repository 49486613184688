import { useMemo } from "react";
import useEntities from "./useEntities";

export default function useEntity(worldId: string, featureId: string) {
  const { entities = [] } = useEntities(worldId);

  const entity = useMemo(() => entities.find((entity) => entity.featureId === featureId), [featureId]);

  return {
    entity,
  };
}
