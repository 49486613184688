import { ConnectionLineComponentProps, getBezierPath } from "reactflow";

export const ConnectionLine = ({
  connectionLineStyle,
  fromX,
  fromY,
  fromPosition,
  toX,
  toY,
  toPosition,
}: ConnectionLineComponentProps) => {
  const [edgePath] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    targetPosition: toPosition,
  });
  return (
    <g>
      <path
        style={{ ...connectionLineStyle, animation: "dashdraw 1s linear infinite", strokeDasharray: "5" }}
        d={edgePath}
        fill={"none"}
        stroke={"white"}
        strokeWidth={3}
      />
    </g>
  );
};
