import { WorldApi } from "@worldwidewebb/client-world";
import { axiosInstance, buildApiClientBasePath } from "..";

const worldApi = new WorldApi(undefined, buildApiClientBasePath("world"), axiosInstance);

export async function getRooms(searchQuery?: string) {
  try {
    const { data: rooms } = await worldApi.getWorldMetadataByQuery(searchQuery);

    return rooms.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getRoom(roomName: string) {
  try {
    const { data: room } = await worldApi.getWorld(roomName, true);

    return room;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
