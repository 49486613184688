import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import SelectLocationIndicator from "../../base/SelectLocationIndicator";

interface FormData {
  locationIndicator: string;
  showQuestIndicator: boolean;
}

const WaitForPlayerLocationNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const showQuestIndicator = formData?.showQuestIndicator;
  const locationIndicator = formData?.locationIndicator ?? (showQuestIndicator ? "location" : "none");

  const { register, getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      locationIndicator,
      showQuestIndicator,
    },
    mode: "onChange",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ locationIndicator }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.locationIndicator = locationIndicator;
        nodeData.showQuestIndicator = locationIndicator !== "none";

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <Stack>
            <SelectLocationIndicator
              color={color}
              value={getValues("locationIndicator")}
              setValue={(value) => setValue("locationIndicator", value)}
            />
          </Stack>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(WaitForPlayerLocationNode);
