import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { memo, useCallback, useMemo } from "react";
import { ReactFlowProvider } from "reactflow";
import { mapQuestFromApi } from "../../../api/quests/quests";
import { useQuestProvider } from "../../../context/QuestContext";
import useQuestCommit from "../../../hooks/quests/useQuestCommit";
import useQuestSubgraphs from "../../../hooks/quests/useQuestSubgraphs";
import useInitialNodeTypes from "../../../hooks/useInitialNodeTypes";
import ConfirmModal from "../../modals/ConfirmModal";
import QuestCommitPreview from "./QuestCommitPreview";

interface QuestCommitPreviewModalProps {
  questId: string;
  questEditorCommitId: string;
  isOpen: boolean;
  onClose: () => void;
}

function QuestCommitPreviewModal({ questId, questEditorCommitId, isOpen, onClose }: QuestCommitPreviewModalProps) {
  const { questCommit } = useQuestCommit(questId, questEditorCommitId);

  const { loadQuest } = useQuestProvider();

  const handleLoadQuest = useCallback(() => {
    if (questCommit == null) {
      return;
    }

    loadQuest(mapQuestFromApi(questCommit.quest));

    onClose();
  }, [loadQuest, questCommit, onClose]);

  const questEditorData = questCommit?.quest.questEditorData;

  const questData = useMemo(
    () => (typeof questEditorData === "string" ? JSON.parse(questEditorData) : questEditorData),
    [questEditorData]
  );

  const nodes = questData?.nodes;
  const edges = questData?.edges;

  const { questSubgraphs = [] } = useQuestSubgraphs();
  const initialNodeTypes = useInitialNodeTypes(questSubgraphs);

  if (questCommit == null) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
      <ModalOverlay />

      <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <ModalHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"} casing={"uppercase"}>
              Preview ({questEditorCommitId})
            </Text>
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <ReactFlowProvider>
            <QuestCommitPreview initialNodes={nodes} initialEdges={edges} initialNodeTypes={initialNodeTypes} />
          </ReactFlowProvider>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button onClick={onClose}>
            <Text color={"white"} casing={"uppercase"}>
              Cancel
            </Text>
          </Button>
          <ConfirmModal color={"white"} title={"Restore"} onConfirm={() => handleLoadQuest()}>
            Restore this version and overwrite your local version?
          </ConfirmModal>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default memo(QuestCommitPreviewModal);
