import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useNpcs from "../../hooks/npcs/useNpcs";

interface SelectNpcWithLastUsedProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
  title?: string;
  showLabel?: boolean;
}

const SelectNpcWithLastUsed: React.FC<SelectNpcWithLastUsedProps> = ({
  color,
  value,
  setValue,
  title,
  showLabel,
  ...chakraProps
}) => {
  const { isLoading, error, npcs = [] } = useNpcs();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      const npcEntries: [string, string][] = npcs
        .filter(({ displayName }) =>
          searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
        )
        .map(({ npcId, displayName }) => [npcId, displayName]);

      npcEntries.unshift(["last_used", "last used"]);

      return npcEntries;
    },
    [npcs]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={title ?? "NPC"}
        showLabel={showLabel}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectNpcWithLastUsed;
