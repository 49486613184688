import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { Sprite } from "@worldwidewebb/client-assets";
import { getSprites } from "../../api/sprites/sprites";

interface SelectSpriteProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectSprite: React.FC<SelectSpriteProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let sprites: Sprite[] = [];

      try {
        sprites = await getSprites(searchQuery || value);
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return sprites.slice(0, 50).map(({ spriteId }) => [spriteId, spriteId]);
    },
    [toast, value]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"Sprite"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectSprite;
