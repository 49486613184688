import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateQuestMetaDataAndNodeData as updateQuestMetaDataAndNodeDataAsync } from "../../api/quests/quests";
import { QuestData, QuestWithId } from "../../models/api/quest";
import useExportRuntimeData from "../useExportRuntimeData";
import useInitialNodeTypes from "../useInitialNodeTypes";
import useQuestSubgraphs from "./useQuestSubgraphs";

interface UpdateQuestMetaDataAndNodeData {
  questId: string;
  partialQuest: Partial<QuestWithId>;
  questData: QuestData;
}

export default function useUpdateQuestMetaDataAndNodeData() {
  const { exportQuestNodes } = useExportRuntimeData();

  const queryClient = useQueryClient();
  const toast = useToast();

  const { questSubgraphs = [] } = useQuestSubgraphs();
  const initialNodeTypes = useInitialNodeTypes(questSubgraphs);

  const { isPending: isUpdating, mutate: updateQuestMetaDataAndNodeData } = useMutation({
    mutationFn: ({ questId, partialQuest, questData }: UpdateQuestMetaDataAndNodeData) =>
      updateQuestMetaDataAndNodeDataAsync(questId, partialQuest, questData, initialNodeTypes, exportQuestNodes),
    onSuccess: async ({ questId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["quest"],
      });

      await queryClient.invalidateQueries({
        queryKey: ["quest", questId],
      });

      await queryClient.invalidateQueries({
        queryKey: ["questCommits", questId],
      });

      toast({ title: "Quest update successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestMetaDataAndNodeData,
  };
}
