import { HandleType } from "reactflow";
import { ulid } from "ulid";
import { HandleCategory, NodeHandle } from "../models/nodeType";

export const createHandleId = ({ handleType, handleCategory }: NodeHandle): string => {
  return [handleType, handleCategory, ulid()].join(":");
};

export const spreadHandleId = (handleId: string): Pick<NodeHandle, "handleType" | "handleCategory"> => {
  const [handleType, handleCategory] = handleId.split(":");

  return {
    handleType: handleType as HandleType,
    handleCategory: handleCategory as HandleCategory,
  };
};
