import { useQuery } from "@tanstack/react-query";
import { NpcWithId } from "@worldwidewebb/client-npcs";
import { getNpcs } from "../../api/npcs/npcs";

export default function useNpcs() {
  const {
    isLoading,
    isFetching,
    error,
    data: npcs,
  } = useQuery<NpcWithId[]>({
    queryKey: ["npc"],
    queryFn: () => getNpcs(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    npcs,
  };
}
