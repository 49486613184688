import { Icon, IconButton, ChakraProps, Tooltip, useDisclosure } from "@chakra-ui/react";
import { memo } from "react";
import { LiaExpandSolid, LiaListAltSolid } from "react-icons/lia";
import { useSelectedQuestProvider } from "../../context/SelectedQuestProvider";
import useQuest from "../../hooks/quests/useQuest";
import useQuestOrNull from "../../hooks/quests/useQuestOrNull";
import QuestUpdateTargetModal from "./QuestUpdateTargetModal";

interface QuestUpdateTargetProps extends ChakraProps {
  questId: string;
}

function QuestUpdateTarget({ color, questId }: QuestUpdateTargetProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { selectedQuestId } = useSelectedQuestProvider();
  const { quest } = useQuestOrNull(selectedQuestId);

  if (quest == null) {
    return null;
  }

  return (
    <>
      <Tooltip
        label={"add as requirement"}
        bg={"theme.dark.background"}
        color={color}
        borderColor={color}
        borderWidth={2}
      >
        <IconButton
          aria-label={"add as requirement"}
          icon={<Icon as={LiaExpandSolid} position={"absolute"} boxSize={12} />}
          boxSize={14}
          variant={"ghost"}
          color={color}
          borderRadius={0}
          onClick={onOpen}
        />
      </Tooltip>

      <QuestUpdateTargetModal color={color} questId={questId} quest={quest} isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export default memo(QuestUpdateTarget);
