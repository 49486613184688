import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Checkbox, FormControl, FormLabel, Text } from "@chakra-ui/react";

interface StartCinematic extends NodeData {
  showCinemaBars: boolean;
}

function StartCinematicNode(props: NodeProps<NodeType<StartCinematic>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const showCinemaBars = nodeData?.showCinemaBars ?? false;

  const { register, handleSubmit } = useForm<StartCinematic>({
    defaultValues: useMemo(
      () => ({
        showCinemaBars,
      }),
      [showCinemaBars]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<StartCinematic>(nodeId);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Show Cinema Bars
              </Text>
            </FormLabel>
            <Checkbox color={color} {...register("showCinemaBars")} />
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
}

export default memo(StartCinematicNode);
