import React from "react";
import { BorderProps, Flex, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { Position } from "reactflow";
import { MdAdd, MdDelete } from "react-icons/md";

interface EditControlProps {
  position: Position;
  onDelete?: () => void;
  onInsertBefore?: () => void;
  onInsertAfter?: () => void;
}

export const EditControl: React.FC<EditControlProps> = ({ position, onDelete, onInsertBefore, onInsertAfter }) => {
  let outerDirection: "row" | "row-reverse" | "column" | "column-reverse" | undefined = undefined;
  let innerDirection: "row" | "row-reverse" | "column" | "column-reverse" | undefined = undefined;

  let deleteBorderRadii: BorderProps = {};
  let insertBeforeBorderRadii: BorderProps = {};
  let insertAfterBorderRadii: BorderProps = {};

  switch (position) {
    case Position.Left: {
      outerDirection = "row";
      innerDirection = "column";
      deleteBorderRadii = { borderLeftRadius: 0 };
      insertBeforeBorderRadii = { borderRightRadius: 0, borderBottomLeftRadius: 0 };
      insertAfterBorderRadii = { borderRightRadius: 0, borderTopLeftRadius: 0 };
      break;
    }
    case Position.Right: {
      outerDirection = "row-reverse";
      innerDirection = "column";
      deleteBorderRadii = { borderRightRadius: 0 };
      insertBeforeBorderRadii = { borderLeftRadius: 0, borderBottomRightRadius: 0 };
      insertAfterBorderRadii = { borderLeftRadius: 0, borderTopRightRadius: 0 };
      break;
    }
    case Position.Top: {
      outerDirection = "column";
      innerDirection = "row";
      deleteBorderRadii = { borderTopRadius: 0 };
      insertBeforeBorderRadii = { borderRightRadius: 0, borderBottomLeftRadius: 0 };
      insertAfterBorderRadii = { borderLeftRadius: 0, borderBottomRightRadius: 0 };
      break;
    }
    case Position.Bottom: {
      outerDirection = "column-reverse";
      innerDirection = "row";
      deleteBorderRadii = { borderBottomRadius: 0 };
      insertBeforeBorderRadii = { borderTopRadius: 0, borderBottomRightRadius: 0 };
      insertAfterBorderRadii = { borderTopRadius: 0, borderBottomLeftRadius: 0 };
      break;
    }
  }

  return (
    <Flex direction={outerDirection}>
      <Flex direction={innerDirection}>
        <Tooltip label={"insert"}>
          <IconButton
            size={"2xs"}
            color={"green.600"}
            variant={"ghost"}
            icon={<Icon as={MdAdd} />}
            aria-label={"insert before"}
            {...insertBeforeBorderRadii}
            onClick={onInsertBefore}
          />
        </Tooltip>
        <Tooltip label={"insert"}>
          <IconButton
            size={"2xs"}
            color={"green.600"}
            variant={"ghost"}
            icon={<Icon as={MdAdd} />}
            aria-label={"insert after"}
            {...insertAfterBorderRadii}
            onClick={onInsertAfter}
          />
        </Tooltip>
      </Flex>
      <Tooltip label={"delete"}>
        <IconButton
          size={"2xs"}
          color={"red.800"}
          variant={"ghost"}
          icon={<Icon as={MdDelete} />}
          aria-label={"delete"}
          {...deleteBorderRadii}
          onClick={onDelete}
        />
      </Tooltip>
    </Flex>
  );
};
