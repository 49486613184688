import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

interface AutoLayout {
  lastUpdated: string;
  updateLastUpdated: () => void;
}

const AutoLayoutContext = createContext<AutoLayout | null>(null);

const AutoLayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [lastUpdated, setLastUpdated] = useState<string>(new Date().toISOString());

  const updateLastUpdated = useCallback(() => {
    setLastUpdated(new Date().toISOString());
  }, []);

  return <AutoLayoutContext.Provider value={{ lastUpdated, updateLastUpdated }}>{children}</AutoLayoutContext.Provider>;
};

const useAutoLayoutProvider = () => {
  const context = useContext(AutoLayoutContext);

  if (context == null) {
    throw new Error("useAutoLayoutProvider used outside of AutoLayoutProvider");
  }

  return context;
};

export { AutoLayoutProvider, useAutoLayoutProvider };
