import Dexie, { EntityTable, Table } from "dexie";
import { QuestData, QuestWithId } from "./models/api/quest";

const db = new Dexie("quests") as Dexie & {
  quests: EntityTable<QuestWithId, "questId">;
  questsOverview: Table<Pick<QuestData, "nodes" | "edges">>;
};

db.version(1).stores({
  quests: "questId",
  questsOverview: "",
});

export { db };

export async function getCachedQuest(questId: string) {
  return await db.quests.get(questId);
}

export async function putCachedQuest(questId: string, quest: QuestWithId) {
  await db.quests.put(quest, questId);
}

export async function getCachedQuests() {
  return await db.questsOverview.get("questsOverview");
}

export async function putCachedQuests({ nodes, edges }: QuestData) {
  await db.questsOverview.put({ nodes, edges }, "questsOverview");
}
