import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import SelectSkill from "../../base/SelectSkill";
import { FlowNodeWithChildren } from "./FlowNode";

interface SkillData extends NodeData {
  skillId: string;
}

const PlayerGrantSkillLevelNode: React.FC<NodeProps<NodeType<SkillData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData: skill },
  } = props;

  const { updateNodeData } = useUpdateNodeData<SkillData>(nodeId);

  const handleUpdateSkill = useCallback(
    (skillId: string) => {
      updateNodeData({
        skillId,
      });
    },
    [updateNodeData]
  );

  const skillId = skill?.skillId ?? "";

  return (
    <FlowNodeWithChildren {...props}>
      <SelectSkill color={color} value={skillId} setValue={handleUpdateSkill} />
    </FlowNodeWithChildren>
  );
};

export default memo(PlayerGrantSkillLevelNode);
