import { useCallback } from "react";
import { Edge, Node, useReactFlow } from "reactflow";
import { useNodeNotificationsProvider } from "../context/NodeNotificationsProvider";
import { EdgeType } from "../models/edgeType";
import NodeError from "../models/nodes/NodeError";
import { NodeData, NodeType } from "../models/nodeType";
import { exportRuntimeData } from "../utils/exportRuntimeData";

export default function useExportRuntimeData() {
  const { insertNotification } = useNodeNotificationsProvider();

  const { fitView } = useReactFlow();

  const exportQuestNodes = useCallback(
    (nodes: Node<NodeType>[], edges: Edge<EdgeType>[], initialNodeTypes: NodeType<NodeData>[] = []) => {
      try {
        return exportRuntimeData(nodes, edges, initialNodeTypes);
      } catch (error) {
        if (error instanceof NodeError) {
          const { nodeId } = error;

          fitView({ nodes: [{ id: nodeId }] });
        }

        throw error;
      }
    },
    [insertNotification, fitView]
  );

  return {
    exportQuestNodes,
  };
}
