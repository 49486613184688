import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { MdCircle } from "react-icons/md";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { convertGameMakerColourToHex } from "../../../utils/convertGameMakerColourToHex";

export const colourLime: Command = {
  category: "colour",
  icon: <Icon as={MdCircle} />,
  name: `#${convertGameMakerColourToHex(65280)}`,
  tooltip: "colour as lime",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#colourLime}}", "{{/colourLime}}"),
};
