import React, { useCallback, useMemo } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useInitialNodeTypes from "../../hooks/useInitialNodeTypes";

interface SelectHandleNameProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectHandleName: React.FC<SelectHandleNameProps> = ({ color, value, setValue, ...chakraProps }) => {
  const initialNodeTypes = useInitialNodeTypes();

  const handleNames = useMemo(() => {
    const handleNames: string[][] = [];

    initialNodeTypes.forEach(({ sourceHandles = [] }) =>
      sourceHandles
        .filter(({ handleCategory }) => handleCategory === "data")
        .forEach(({ handleName, label: handleLabel }) => {
          if (handleNames.map(([handleName]) => handleName).includes(handleName)) {
            return;
          }

          handleNames.push([handleName, handleLabel ?? handleName]);
        })
    );

    handleNames.push(["entity", "entity"]);
    handleNames.push(["questId", "questId"]);

    return handleNames;
  }, [initialNodeTypes]);

  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return handleNames
      .filter(([_, handleLabel]) =>
        searchQuery ? handleLabel.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
      )
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([handleName, handleLabel]) => [handleName, handleLabel]);
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Handle Name"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectHandleName;
