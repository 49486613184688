import React, { memo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { FlowNodeWithChildren } from "./FlowNode";

const ResetHighlightUIElementNode: React.FC<NodeProps<NodeType>> = (props) => {
  return <FlowNodeWithChildren {...props}></FlowNodeWithChildren>;
};

export default memo(ResetHighlightUIElementNode);
