import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateQuestMetaData as updateQuestMetaDataAsync } from "../../api/quests/quests";
import { QuestWithId } from "../../models/api/quest";

interface UpdateQuestMetaData {
  questId: string;
  partialQuest: Partial<QuestWithId>;
}

export default function useUpdateQuestMetaData() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: updateQuestMetaData } = useMutation({
    mutationFn: ({ questId, partialQuest }: UpdateQuestMetaData) => updateQuestMetaDataAsync(questId, partialQuest),
    onSuccess: async ({ questId }) => {
      await queryClient.invalidateQueries({
        queryKey: ["quest", questId],
      });

      toast({ title: "Quest update successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestMetaData,
  };
}
