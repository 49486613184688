import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { ReactFlowState, useReactFlow, useStore, useStoreApi } from "reactflow";
import generateLayout, { LayoutDirection } from "../utils/generateLayout";

interface ReactFlowAutoLayout {
  updateLayout: () => void;
}

const ReactFlowAutoLayoutContext = createContext<ReactFlowAutoLayout | null>(null);

function isNodesInitializedSelector(state: ReactFlowState) {
  return Array.from(state.nodeInternals.values()).every((node) => node?.width && node?.height);
}

function nodeCountSelector(state: ReactFlowState) {
  return state.nodeInternals.size;
}

interface ReactFlowAutoLayoutProviderProps extends PropsWithChildren {
  direction: LayoutDirection;
}

export function ReactFlowAutoLayoutProvider({ direction, children }: ReactFlowAutoLayoutProviderProps) {
  const isNodesInitialized = useStore(isNodesInitializedSelector);
  const nodeCount = useStore(nodeCountSelector);

  const store = useStoreApi();

  const { setNodes, setEdges } = useReactFlow();

  const updateLayout = useCallback(() => {
    if (!isNodesInitialized) {
      return;
    }

    if (nodeCount === 0) {
      return;
    }

    const { getNodes, edges } = store.getState();
    const nodes = getNodes();

    const { updatedNodes, updatedEdges } = generateLayout(nodes, edges, direction);

    setNodes(updatedNodes);
    setEdges(updatedEdges);
  }, [isNodesInitialized, nodeCount, store, direction]);

  return <ReactFlowAutoLayoutContext.Provider value={{ updateLayout }}>{children}</ReactFlowAutoLayoutContext.Provider>;
}

export function useReactFlowAutoLayoutProvider() {
  const context = useContext(ReactFlowAutoLayoutContext);

  if (context == null) {
    throw new Error("useReactFlowAutoLayoutProvider used outside of ReactFlowAutoLayoutProvider");
  }

  return context;
}
