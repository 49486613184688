import { useQuery } from "@tanstack/react-query";
import { QuestEditorCommitWithId } from "@worldwidewebb/client-quests";
import { getQuestCommit } from "../../api/quests/questCommits";

export default function useQuestCommit(questId: string, questEditorCommitId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: questCommit,
  } = useQuery<QuestEditorCommitWithId>({
    queryKey: ["questCommit", questId, questEditorCommitId],
    queryFn: () => getQuestCommit(questId, questEditorCommitId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questCommit,
  };
}
