import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const tagLocation: Command = {
  category: "game entity",
  icon: <Icon as={MdLocationOn} />,
  name: "location",
  tooltip: "tag as location",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#location}}", "{{/location}}"),
};
