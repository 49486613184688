import React, { memo, useCallback, useEffect } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType, SourceHandle } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { FlowNodeWithChildren } from "./FlowNode";

interface NodeData {
  flowSourceCount: number;
}

interface SplitNodeConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeId: string;
  nodeData: NodeData;
  sourceHandles: SourceHandle[];
  color?: string;
}

export const SplitNodeConfigurationModal: React.FC<SplitNodeConfigurationModalProps> = ({
  isOpen,
  onClose,
  nodeId,
  nodeData,
  sourceHandles,
  color,
}) => {
  const flowSourceCount = nodeData?.flowSourceCount ?? 2;

  const { register, reset, handleSubmit } = useForm<NodeData>({
    defaultValues: {
      flowSourceCount,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ flowSourceCount }: NodeData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.flowSourceCount = flowSourceCount;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });

      onClose();
    },
    [onClose, reactFlow, nodeId]
  );

  useEffect(() => {
    reset({
      flowSourceCount,
    });
  }, [flowSourceCount]);

  const handleCancel = useCallback(() => {
    reset({
      flowSourceCount,
    });

    onClose();
  }, [onClose, reset, flowSourceCount]);

  useEffect(() => {
    const currentFlowSourceCount = sourceHandles.filter(({ handleName }) => handleName === "out").length;

    if (currentFlowSourceCount === flowSourceCount) {
      return;
    }

    const sourceHandlesExcludingOut = sourceHandles.filter(({ handleName }) => handleName !== "out");

    const sourceHandlesIncludingOut = sourceHandles.filter(({ handleName }) => handleName === "out");

    if (currentFlowSourceCount < flowSourceCount) {
      const handlesToInsert = flowSourceCount - currentFlowSourceCount;

      sourceHandlesIncludingOut.push(
        ...[...Array(handlesToInsert)].map(() => {
          const sourceHandle: SourceHandle = {
            label: "OUT",
            handleName: "out",
            handleType: "source",
            handleCategory: "flow",
          };

          return sourceHandle;
        })
      );
    } else {
      const handlesToRemove = currentFlowSourceCount - flowSourceCount;

      sourceHandlesIncludingOut.splice(-handlesToRemove);
    }

    updateNodeSourceHandles([...sourceHandlesIncludingOut, ...sourceHandlesExcludingOut]);
  }, [flowSourceCount, updateNodeSourceHandles, sourceHandles]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <ModalHeader>
            <Text color={color}>Configuration</Text>
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  OUT Port Count
                </Text>
              </FormLabel>
              <NumberInput defaultValue={2} step={1} min={2} max={16}>
                <NumberInputField
                  id={"flowSourceCount"}
                  {...register("flowSourceCount", { valueAsNumber: true, min: 2, max: 16 })}
                  color={color}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </ModalBody>

          <ModalFooter gap={1}>
            <Button onClick={handleCancel} color={"white"} variant={"outline"}>
              Cancel
            </Button>
            <Button color={color} type={"submit"} variant={"outline"}>
              Update
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const SplitNode: React.FC<NodeProps<NodeType>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    id: nodeId,
    data: { color, nodeData = {}, sourceHandles = [] },
  } = props;

  return (
    <FlowNodeWithChildren {...props}>
      <Button onClick={onOpen} color={color} textTransform={"uppercase"} w={"100%"} variant={"outline"}>
        Configure
      </Button>

      <SplitNodeConfigurationModal
        isOpen={isOpen}
        onClose={onClose}
        nodeId={nodeId}
        nodeData={nodeData as NodeData}
        sourceHandles={sourceHandles}
        color={color}
      />
    </FlowNodeWithChildren>
  );
};

export default memo(SplitNode);
