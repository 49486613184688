import { Stack } from "@chakra-ui/react";
import React, { memo, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import SelectAmbience from "../../base/SelectAmbience";
import { FlowNodeWithChildren } from "./FlowNode";

interface PrefetchAmbience extends NodeData {
  fileName: string;
}

const PrefetchAmbienceNode: React.FC<NodeProps<NodeType<PrefetchAmbience>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const fileName = nodeData?.fileName ?? "";

  const { register, getValues, setValue, handleSubmit } = useForm<PrefetchAmbience>({
    defaultValues: useMemo(
      () => ({
        fileName,
      }),
      [fileName]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<PrefetchAmbience>(nodeId);

  const handleUpdate = useCallback(
    (nodeData: PrefetchAmbience) => {
      updateNodeData(nodeData);
    },
    [updateNodeData]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <Stack>
            <SelectAmbience
              value={getValues("fileName")}
              setValue={(value) => setValue("fileName", value)}
              color={color}
            />
          </Stack>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(PrefetchAmbienceNode);
