import { Center, Checkbox, FormControl, Stack, Text } from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system";
import SearchBar from "../../base/SearchBar";
import { NodeCategory, NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo, useMemo, useState } from "react";
import QuestNodeCategorySelector from "./QuestNodeCategorySelector";
import QuestNodeTypeList from "./QuestNodeTypeList";

// TODO: VERIFICATION PENDING

interface QuestNodeSelectorProps extends ChakraProps {
  questNodeTypes: NodeType[];
  x: number;
  y: number;
}

function QuestNodeSelector({ questNodeTypes, x, y, maxH }: QuestNodeSelectorProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showExperimentalNodes, setShowExperimentalNodes] = useState<boolean>(false);
  const [selectedNodeCategories, setSelectedNodeCategories] = useState<NodeCategory[]>([]);

  const filteredQuestNodeTypes = useMemo(
    () =>
      questNodeTypes
        .filter(({ nodeCategory }) => {
          if (nodeCategory == null) {
            return true;
          }

          if (selectedNodeCategories.length === 0) {
            return true;
          }

          return selectedNodeCategories.includes(nodeCategory);
        })
        .filter(({ isReady }) => {
          return showExperimentalNodes ? true : isReady;
        })
        .filter(({ label }) => {
          return label?.toLowerCase().includes(searchValue.toLowerCase().trim());
        })
        .sort(({ label: a = "" }, { label: b = "" }) => {
          return a.localeCompare(b);
        }),
    [questNodeTypes, selectedNodeCategories, showExperimentalNodes, searchValue]
  );

  return (
    <Stack bg={"gray.900"} w={"xs"}>
      <Stack>
        <QuestNodeCategorySelector
          selectedNodeCategories={selectedNodeCategories}
          setSelectedNodeCategories={setSelectedNodeCategories}
        />

        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={"Search for node types..."}
          variant={"block"}
        />

        <FormControl textAlign={"center"}>
          <Checkbox
            isChecked={showExperimentalNodes}
            onChange={({ target: { checked } }) => setShowExperimentalNodes(checked)}
          >
            <Text casing={"uppercase"} color={"white"} fontWeight={500}>
              Show Experimental Nodes
            </Text>
          </Checkbox>
        </FormControl>
      </Stack>

      {filteredQuestNodeTypes.length !== 0 ? (
        <Stack spacing={0} maxH={maxH}>
          <QuestNodeTypeList questNodeTypes={filteredQuestNodeTypes} x={x} y={y} searchValue={searchValue} />
        </Stack>
      ) : (
        <Center p={2}>
          <Text color={"white"} casing={"uppercase"} fontWeight={500}>
            No results found
          </Text>
        </Center>
      )}
    </Stack>
  );
}

export default memo(QuestNodeSelector);
