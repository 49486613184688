import { useQuery } from "@tanstack/react-query";
import { getQuests } from "../../api/quests/quests";
import { QuestWithId } from "../../models/api/quest";

export default function useQuests() {
  const {
    isLoading,
    isFetching,
    error,
    data: quests,
  } = useQuery<QuestWithId[]>({
    queryKey: ["quest"],
    queryFn: () => getQuests(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    quests,
  };
}
