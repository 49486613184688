import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";

export type QuestWaypoint = "none" | "location" | "npc";

const questWaypoints: Record<QuestWaypoint, string> = {
  none: "NONE",
  location: "Location",
  npc: "NPC",
};

interface SelectWaypointProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectWaypoint: React.FC<SelectWaypointProps> = ({ color, value, setValue, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return Object.entries(questWaypoints).filter(([_, displayName]) =>
      searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
    );
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Waypoint"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectWaypoint;
