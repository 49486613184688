import { useReactFlow } from "reactflow";
import { useCallback } from "react";

const useDeselectNodes = () => {
  const reactFlow = useReactFlow();

  const deselectNodes = useCallback(() => {
    reactFlow.setNodes((nodes) => {
      return nodes.map((node) => ({
        ...node,
        selected: false,
      }));
    });
  }, [reactFlow]);

  return {
    deselectNodes,
  };
};

export default useDeselectNodes;
