import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { ObservableEvent } from "../../../models/api/observableEvent";
import { NodeType } from "../../../models/nodeType";
import SelectObservableEvent from "../../base/SelectObservableEvent";
import { FlowNodeWithChildren } from "./FlowNode";

const WatchPlayerActionNode: React.FC<NodeProps<NodeType<ObservableEvent>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const { updateNodeData } = useUpdateNodeData<ObservableEvent>(nodeId);

  const handleUpdateEventName = useCallback(
    (observableEvent: ObservableEvent) => {
      updateNodeData(observableEvent);
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <SelectObservableEvent color={color} observableEvent={nodeData} setObservableEvent={handleUpdateEventName} />
    </FlowNodeWithChildren>
  );
};

export default memo(WatchPlayerActionNode);
