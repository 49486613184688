import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { LuTriangleRight } from "react-icons/lu";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const scribbleSlant: Command = {
  category: "transform",
  icon: <Icon as={LuTriangleRight} />,
  name: "slant",
  tooltip: "tag with slant effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#slant}}", "{{/slant}}"),
};
