import { useQuery } from "@tanstack/react-query";
import { getSessionUserId } from "../../api/users/users";

export default function useSessionUserId() {
  const {
    isLoading,
    isFetching,
    error,
    data: sessionUserId,
  } = useQuery<string>({
    queryKey: ["sessionUserId"],
    queryFn: () => getSessionUserId(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    sessionUserId,
  };
}
