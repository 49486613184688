import { ChakraProps, Heading, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { memo, PropsWithChildren } from "react";
import ItemNameAndQuantityComponent from "./ItemNameAndQuantityComponent";

interface ItemNameAndQuantityComponentGroupProps extends ChakraProps, PropsWithChildren {
  itemNameAndQuantities: Record<string, number>;
}

function ItemNameAndQuantityComponentGroup({
  color,
  children,
  itemNameAndQuantities,
}: ItemNameAndQuantityComponentGroupProps) {
  const itemNameAndQuantityEntries = Object.entries(itemNameAndQuantities);

  if (itemNameAndQuantityEntries.length === 0) {
    return null;
  }

  return (
    <>
      <Heading size={"sm"}>
        <Text color={color} casing={"uppercase"}>
          {children}
        </Text>
      </Heading>

      <Wrap>
        {itemNameAndQuantityEntries.map(([itemName, quantity]) => (
          <WrapItem key={itemName}>
            <ItemNameAndQuantityComponent itemName={itemName} quantity={quantity} />
          </WrapItem>
        ))}
      </Wrap>
    </>
  );
}

export default memo(ItemNameAndQuantityComponentGroup);
