import { useQuery } from "@tanstack/react-query";
import { Profile } from "@worldwidewebb/client-auth";
import { getSessionProfile } from "../../api/users/users";

export default function useSessionProfile() {
  const {
    isLoading,
    isFetching,
    error,
    data: sessionProfile,
  } = useQuery<Profile>({
    queryKey: ["sessionProfile"],
    queryFn: () => getSessionProfile(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    sessionProfile,
  };
}
