import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import React, { memo, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import { FlowNodeWithChildren } from "./FlowNode";

interface HighlightUIElement extends NodeData {
  uiElement: string;
}

const HighlightUIElementNode: React.FC<NodeProps<NodeType<HighlightUIElement>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const uiElement = nodeData?.uiElement ?? "";

  const { register, handleSubmit } = useForm<HighlightUIElement>({
    defaultValues: useMemo(
      () => ({
        uiElement,
      }),
      [uiElement]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    ({ uiElement }: HighlightUIElement) => {
      updateNodeData({ uiElement });
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              UI Element
            </Text>
          </FormLabel>
          <Input color={color} {...register("uiElement")} />
        </FormControl>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(HighlightUIElementNode);
