import { useQuery } from "@tanstack/react-query";
import { getMusic } from "../../api/music/music";

export default function useMusic() {
  const {
    isLoading,
    isFetching,
    error,
    data: music,
  } = useQuery<string[]>({
    queryKey: ["music"],
    queryFn: () => getMusic(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    music,
  };
}
