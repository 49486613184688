import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { NodeDiff, NodePropertyDiff } from "../../hooks/useNodeTypesDiff";
import { NodeHandle, NodeType } from "../../models/nodeType";

interface NodePropertyDiffsComponentProps {
  nodePropertyDiffs: NodePropertyDiff<NodeType>[] | NodePropertyDiff<NodeHandle>[];
}

const NodePropertyDiffsComponent: React.FC<NodePropertyDiffsComponentProps> = ({ nodePropertyDiffs }) => {
  return (
    <TableContainer>
      <Table>
        <TableCaption>
          Showing {nodePropertyDiffs.length} change{nodePropertyDiffs.length === 1 ? "" : "s"}
        </TableCaption>

        <Thead>
          <Tr>
            <Th color={"orange.600"}>Property</Th>
            <Th color={"orange.600"}>Action</Th>
            <Th color={"orange.600"}>Old Value</Th>
            <Th color={"orange.600"}>New Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {nodePropertyDiffs &&
            nodePropertyDiffs.map(({ property, actionType, oldValue, newValue }, index) => {
              let actionTypeColor = "";

              if (actionType === "created") {
                actionTypeColor = "green.600";
              }

              if (actionType === "updated") {
                actionTypeColor = "orange.600";
              }

              if (actionType === "deleted") {
                actionTypeColor = "red.800";
              }

              return (
                <Tr key={index}>
                  <Td>{property}</Td>
                  <Td color={actionTypeColor}>{actionType}</Td>
                  <Td>{oldValue}</Td>
                  <Td>{newValue}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

interface NodeDiffComponentProps {
  nodeDiff: NodeDiff;
}

const NodeDiffComponent: React.FC<NodeDiffComponentProps> = ({
  nodeDiff: { nodeId, nodeType, actionType, nodeDataDiffs = [], nodeHandleDiffs = [] },
}) => {
  let actionTypeText = "";
  let actionTypeColor = "";

  if (actionType === "created") {
    actionTypeText = "+";
    actionTypeColor = "green.600";
  }

  if (actionType === "updated") {
    actionTypeText = "~";
    actionTypeColor = "orange.600";
  }

  if (actionType === "deleted") {
    actionTypeText = "-";
    actionTypeColor = "red.800";
  }

  return (
    <Card bg={"theme.dark.background"} borderColor={"orange.600"} borderRadius={0} borderWidth={1}>
      <CardHeader>
        <Flex gap={2}>
          <Text color={actionTypeColor}>{actionTypeText}</Text>
          <Text color={"white"} casing={"uppercase"}>
            {nodeType} ({nodeId})
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Stack>
          {nodeDataDiffs.length !== 0 && (
            <>
              <Text color={"white"}>Node Data</Text>
              <NodePropertyDiffsComponent nodePropertyDiffs={nodeDataDiffs} />
            </>
          )}
          {nodeHandleDiffs.length !== 0 && (
            <>
              <Text color={"white"}>Node Handles</Text>
              <NodePropertyDiffsComponent nodePropertyDiffs={nodeHandleDiffs} />
            </>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

interface NodeDiffModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeDiffs: NodeDiff[];
}

export const NodeDiffModal: React.FC<NodeDiffModalProps> = ({ isOpen, onClose, nodeDiffs }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent bg={"theme.dark.background"} borderColor={"orange.600"} borderRadius={0} borderWidth={1}>
        <ModalHeader>
          <Text color={"orange.600"}>Node History</Text>
        </ModalHeader>
        <ModalBody>
          <Stack>
            {nodeDiffs && nodeDiffs.map((nodeDiff) => <NodeDiffComponent key={nodeDiff.nodeId} nodeDiff={nodeDiff} />)}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} color={"white"} variant={"outline"}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
