import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { RxReset } from "react-icons/rx";
import { insertText } from "../utils/insertText";

export const scribbleResetScale: Command = {
  category: "transform",
  icon: <Icon as={RxReset} />,
  name: "reset scale",
  tooltip: "tag with resetScale",
  execute: (editorRef) => insertText(editorRef, "{{resetScale}}"),
};
