import { ObservableEventSelector, QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getObservableEvents(): Promise<ObservableEventSelector[]> {
  try {
    const { data: observableEvents } = await questEditorApi.getObservableEvents();

    return observableEvents;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
