import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { TbArrowWaveRightDown } from "react-icons/tb";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const scribbleWave: Command = {
  category: "effect",
  icon: <Icon as={TbArrowWaveRightDown} />,
  name: "wave",
  tooltip: "tag with wave effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#wave}}", "{{/wave}}"),
};
