import React, { DragEvent, memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  ControlButton,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  NodeProps,
  Panel,
  ReactFlowProps,
  ReactFlowProvider,
  SelectionMode,
  updateEdge,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import { NodeData, NodeType } from "../../../../models/nodeType";
import { BaseNodeWithChildren } from "./BaseNode";
import { EdgeType } from "../../../../models/edgeType";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  ChakraProps,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import useInitialNodeTypes from "../../../../hooks/useInitialNodeTypes";
import useNodeTypesDiff, { NodeDiff } from "../../../../hooks/useNodeTypesDiff";
import { useParams, useRevalidator } from "react-router-dom";
import { ConnectionProvider, useConnectionProvider } from "../../../../context/ConnectionContext";
import { useCopyAndPasteShortcuts } from "../../../../hooks/useCopyAndPasteShortcuts";
import useNodeHandlesValidation from "../../../../hooks/useNodeHandlesValidation";
import { createHandleId } from "../../../../utils/handleId";
import useNodeTypeNameLookup from "../../../../hooks/useNodeTypeNameLookup";
import { ulid } from "ulid";
import { UserSettingsProvider, useUserSettingsProvider } from "../../../../context/UserSettingsContext";
import { useToken } from "@chakra-ui/system";
import { nodeTypeDictionary } from "../index";
import { edgeTypeDictionary } from "../../edgeTypes";
import { ConnectionLine } from "../../ConnectionLine";
import StyledBackground from "../../StyledBackground";
import StyledControls from "../../StyledControls";
import StyledMiniMap from "../../StyledMiniMap";
import { BiSolidInfoCircle } from "react-icons/bi";
import { LiaHandPaperSolid, LiaHandPointer } from "react-icons/lia";
import Sidebar, { SidebarItem } from "../../../navigation/Sidebar";
import NodeSelector from "../../../navigation/elements/NodeSelector";
import NodeDebugger from "../../../navigation/elements/NodeDebugger";
import { CgDebug, CgListTree } from "react-icons/cg";
import MultiProvider from "../../../../context/MultiProvider";
import { useUpdateNodeData } from "../../../../hooks/useUpdateNodeData";
import { useForm } from "react-hook-form";
import ControlSchemeInfo from "../../ControlSchemeInfo";
import { useActiveUsersProvider } from "../../../../context/ActiveUsersContext";
import { exportRuntimeData } from "../../../../utils/exportRuntimeData";
import { UserList } from "../../../UserList";
import { ControlToolbarSubgraph } from "../../ControlToolbarSubgraph";
import useDeselectNodes from "../../../../hooks/useDeselectNodes";
import { useUpdateNodeHandles } from "../../../../hooks/useUpdateNodeHandles";
import { MdLock, MdLockOpen, MdSaveAs } from "react-icons/md";
import useQuests from "../../../../api/quests/useQuests";
import { useCopyAndPaste } from "../../../../hooks/useCopyAndPaste";
import { useUpdateNode } from "../../../../hooks/useUpdateNode";
import { useLoaderData } from "react-router";
import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import ConfirmModal from "../../../modals/ConfirmModal";
import { QuestPointerContainerList, ToggleQuestPointersButton } from "../../../quests/QuestPointerContainer";
import { StyledPanel } from "../../StyledPanel";
import useExportRuntimeData from "../../../../hooks/useExportRuntimeData";

// TODO: WIP requires abstraction of reactflow functionality

export interface Subgraph extends NodeData {
  subgraphQuestId?: string;
  displayName: string;
  description: string;
  nodes: Node<NodeType>[];
  edges: Edge<EdgeType>[];
  isLabelsLocked: boolean;
}

interface SubgraphTemplateLabelProps extends ChakraProps {
  subgraph?: Subgraph;
}

const SubgraphTemplateLabel: React.FC<SubgraphTemplateLabelProps> = ({ color, subgraph: { subgraphQuestId } = {} }) => {
  return subgraphQuestId ? (
    <Tag>
      <Text color={color} casing={"uppercase"}>
        Template
      </Text>
    </Tag>
  ) : null;
};

interface SubgraphHeaderProps extends ChakraProps {
  subgraph?: Subgraph;
  onSaveSubgraph: (subgraph: Subgraph) => void;
}

const SubgraphHeader: React.FC<SubgraphHeaderProps> = ({ subgraph, onSaveSubgraph }) => {
  // PATCH
  const color = "pink.400";

  if (subgraph == null) {
    return null;
  }

  const { subgraphQuestId } = subgraph;

  const [displayName, setDisplayName] = useState<string>(subgraph.displayName);
  const [description, setDescription] = useState<string>(subgraph.description);

  const handleUpdateDisplayName = useCallback(
    (displayName: string) => {
      setDisplayName(displayName);

      onSaveSubgraph({ ...subgraph, displayName });
    },
    [onSaveSubgraph, subgraph]
  );

  const handleUpdateDescription = useCallback(
    (description: string) => {
      setDescription(description);

      onSaveSubgraph({ ...subgraph, description });
    },
    [onSaveSubgraph, subgraph]
  );

  useEffect(() => {
    setDisplayName(subgraph.displayName);
    setDescription(subgraph.description);
  }, [subgraph]);

  return (
    <Stack p={3} className={"nodrag"}>
      {subgraphQuestId ? (
        <Accordion allowToggle>
          <AccordionItem borderWidth={0} borderBottomColor={"transparent"}>
            <AccordionButton pl={0} justifyContent={"space-between"}>
              <Text color={color} casing={"uppercase"} fontWeight={500}>
                Description
              </Text>

              <AccordionIcon color={color} />
            </AccordionButton>

            <AccordionPanel pl={0}>
              <Text color={"white"} textAlign={"justify"} whiteSpace={"pre-wrap"}>
                {description}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <>
          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Title
              </Text>
            </FormLabel>

            <Input
              borderRadius={0}
              borderColor={color}
              color={"white"}
              value={displayName}
              onChange={({ target: { value } }) => handleUpdateDisplayName(value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Description
              </Text>
            </FormLabel>

            <Textarea
              borderRadius={0}
              borderColor={color}
              color={"white"}
              value={description}
              onChange={({ target: { value } }) => handleUpdateDescription(value)}
            />
          </FormControl>
        </>
      )}
    </Stack>
  );
};

interface SubgraphCanvasProps extends ChakraProps {
  subgraph?: Subgraph;
}

const SubgraphCanvas: React.FC<SubgraphCanvasProps> = ({ subgraph }) => {
  const { subgraphs } = useLoaderData() as {
    subgraphs: QuestSubgraphWithId[];
  };
  const initialNodeTypes = useInitialNodeTypes(subgraphs);
  const [nodes, setNodes] = useState<Node<NodeType>[]>(subgraph?.nodes ?? []);
  const [edges, setEdges] = useState<Edge[]>(subgraph?.edges ?? []);
  const { updatedEdges, updatedNodes, updatedNodeDiffs } = useNodeTypesDiff(edges, nodes, initialNodeTypes);
  const hasDefinitionsUpgrade = updatedNodeDiffs.length !== 0;
  const [updatedNodeDiffsLog, setUpdatedNodeDiffsLog] = useState<NodeDiff[]>([]);
  const { id } = useParams();
  const reactFlow = useReactFlow();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { activeUsers } = useActiveUsersProvider();

  const { onEdgeUpdateStart, onEdgeUpdateEnd } = useConnectionProvider();

  const { isValidConnection } = useNodeHandlesValidation();

  const handleDefinitionsUpgrade = useCallback(() => {
    setUpdatedNodeDiffsLog(updatedNodeDiffs);

    // https://github.com/wbkd/react-flow/issues/3198
    setEdges([]);
    setNodes([]);
    setTimeout(() => {
      setNodes(updatedNodes);
      setEdges(updatedEdges);
    }, 0);
  }, [updatedEdges, updatedNodes, updatedNodeDiffs]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((changedNodes) => applyNodeChanges(changes, changedNodes));
  }, []);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((changedEdges) => applyEdgeChanges(changes, changedEdges));
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    setEdges((edges) => updateEdge(oldEdge, newConnection, edges));
  }, []);

  const { onConnect } = useConnectionProvider();

  const { project } = useReactFlow();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useCopyAndPasteShortcuts(wrapperRef);

  const { screenToFlowPosition } = useReactFlow();

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const { getLatestType } = useNodeTypeNameLookup();
  const { paste } = useCopyAndPaste();

  const onDrop = useCallback(
    async (event: DragEvent) => {
      event.stopPropagation();

      const nodeName = event.dataTransfer.getData("application/reactflow");
      const nodePosition = screenToFlowPosition({ x: event.clientX, y: event.clientY });

      const nodeType = initialNodeTypes.find((nodeType) => nodeType.nodeName === nodeName);

      if (nodeType == null) {
        return;
      }

      nodeType.targetHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId ??= createHandleId(nodeHandle);
      });

      nodeType.sourceHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId ??= createHandleId(nodeHandle);
      });

      const type = getLatestType(nodeType, nodeName);

      const { nodes } = await paste(
        [
          {
            id: ulid(),
            type,
            position: nodePosition,
            data: structuredClone(nodeType),
          },
        ],
        [],
        true
      );

      const [node] = nodes;
      node.position = nodePosition;

      setNodes((nodes) => nodes.concat(node));
    },
    [screenToFlowPosition, initialNodeTypes, getLatestType, paste]
  );

  const { exportQuestNodes } = useExportRuntimeData();

  const handleExportToFile = useCallback(() => {
    if (!id) {
      return;
    }

    const exportJSON = JSON.stringify(exportQuestNodes(nodes, edges, initialNodeTypes), null, 2);

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(exportJSON)}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${subgraph?.displayName}.json`;

    link.click();
    link.remove();
  }, [nodes, edges, id, initialNodeTypes, exportQuestNodes]);

  const handleExportToClipboard = useCallback(() => {
    if (!id) {
      return;
    }

    const exportJSON = JSON.stringify(exportQuestNodes(nodes, edges, initialNodeTypes), null, 2);

    navigator.clipboard.writeText(exportJSON).catch((error) => console.error(error));
  }, [nodes, edges, id, initialNodeTypes, exportQuestNodes]);

  const handleClickNodeInSelectionRectangle = useCallback(
    (event: Event) => {
      const { target, clientX, clientY } = event as unknown as MouseEvent;

      if (!(target instanceof HTMLElement)) {
        return;
      }

      // noinspection SpellCheckingInspection
      if (!target.closest(".react-flow__nodesselection")) {
        return;
      }

      const { x: clickX, y: clickY } = screenToFlowPosition({ x: clientX, y: clientY });

      const selectedNodes = reactFlow?.getNodes().filter(({ selected }) => selected) ?? [];
      const [clickedNode] = selectedNodes.filter(
        ({ position: { x, y }, width, height }) =>
          x <= clickX && clickX < x + (width || 0) && y <= clickY && clickY < y + (height || 0)
      );

      if (clickedNode == null) {
        return;
      }

      reactFlow?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: clickedNode.id === node.id,
        }))
      );

      reactFlow?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );

      target.remove();
    },
    [reactFlow, screenToFlowPosition]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickNodeInSelectionRectangle);

    return () => {
      window.removeEventListener("click", handleClickNodeInSelectionRectangle);
    };
  }, [handleClickNodeInSelectionRectangle]);

  const handleClickNodeInSelection = useCallback(
    ({ ctrlKey, metaKey }: MouseEvent, { id: nodeId }: Node) => {
      // corresponds to multiSelectionKeyCode
      if (ctrlKey || metaKey) {
        return;
      }

      const selectedNodes = reactFlow?.getNodes().filter(({ selected }) => selected) ?? [];

      if (selectedNodes.length <= 1) {
        return;
      }

      reactFlow?.setNodes((nodes) =>
        nodes.map((node) => ({
          ...node,
          selected: node.id === nodeId,
        }))
      );

      reactFlow?.setEdges((edges) =>
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );
    },
    [reactFlow]
  );

  useEffect(() => {
    if (!reactFlow) {
      return;
    }

    setTimeout(() => {
      reactFlow.fitView();

      setIsLoading(false);
    }, 100);
  }, [reactFlow]);

  const nodeColors = useToken(
    "colors",
    initialNodeTypes.map(({ color }) => color ?? "white")
  );

  const nodeColorDictionary = useMemo(
    () => Object.fromEntries(initialNodeTypes.map(({ color }, index) => [color ?? "white", nodeColors[index]])),
    [initialNodeTypes, nodeColors]
  );

  const getNodeColor = useCallback(
    (colorToken?: string) => {
      if (colorToken == null) {
        return "#FFF";
      }

      return nodeColorDictionary[colorToken];
    },
    [nodeColorDictionary]
  );

  const { controlScheme, toggleControlScheme } = useUserSettingsProvider();

  const navigationConfig: ReactFlowProps = useMemo(
    () =>
      controlScheme === "primary"
        ? {
            multiSelectionKeyCode: ["Meta", "Control"],
            panActivationKeyCode: "Shift",
            panOnDrag: [1],
            selectionKeyCode: null,
            selectionOnDrag: true,
          }
        : {
            panOnScroll: true,
            selectionKeyCode: ["Meta", "Control"],
          },
    [controlScheme]
  );

  const { onClose, onOpen, isOpen } = useDisclosure();

  const items: SidebarItem[] = [
    {
      icon: CgListTree,
      label: "node selection",
      content: <NodeSelector />,
    },
    {
      icon: CgDebug,
      label: "quest pointers",
      content: <NodeDebugger />,
    },
  ];

  return (
    <>
      <Box ref={wrapperRef} position={"relative"} tabIndex={0}>
        {isLoading && (
          <Center bg={"mirage.900"} position={"fixed"} top={0} bottom={0} left={0} right={0} zIndex={1000}>
            <Spinner size={"xl"} color={"white"} />
          </Center>
        )}

        <ReactFlow
          nodeTypes={nodeTypeDictionary}
          edgeTypes={edgeTypeDictionary}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}
          connectionLineComponent={ConnectionLine}
          onConnect={onConnect}
          onEdgeUpdateStart={(_, edge) => onEdgeUpdateStart(edge)}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          isValidConnection={isValidConnection}
          proOptions={{ hideAttribution: true }}
          deleteKeyCode={["Backspace", "Delete"]}
          selectionMode={SelectionMode.Partial}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onNodeClick={handleClickNodeInSelection}
          minZoom={0.125}
          maxZoom={1}
          {...navigationConfig}
        >
          <StyledBackground />
          <StyledControls position="top-right" showInteractive={false}>
            <ControlButton onClick={onOpen}>
              <Icon as={BiSolidInfoCircle} />
            </ControlButton>
            <ControlButton onClick={toggleControlScheme}>
              <Icon as={controlScheme === "primary" ? LiaHandPointer : LiaHandPaperSolid} />
            </ControlButton>
          </StyledControls>
          <StyledMiniMap
            position="bottom-right"
            pannable={true}
            zoomable={true}
            nodeColor={(node: Node<NodeType>) => getNodeColor(node.data.color)}
          />

          <StyledPanel position={"bottom-center"}>
            <ToggleQuestPointersButton />
          </StyledPanel>
        </ReactFlow>

        <Sidebar
          position={"absolute"}
          left={0}
          top={0}
          bottom={0}
          flexGrow={1}
          bg={"theme.dark.background"}
          color={"white"}
          items={items}
        />

        <ControlToolbarSubgraph
          onExportToFile={handleExportToFile}
          onExportToClipboard={handleExportToClipboard}
          hasDefinitionsUpgrade={hasDefinitionsUpgrade}
          onDefinitionsUpgrade={handleDefinitionsUpgrade}
          nodeDiffs={updatedNodeDiffsLog}
        />

        <ControlSchemeInfo isOpen={isOpen} onClose={onClose} />

        <UserList users={activeUsers} />
      </Box>
    </>
  );
};

interface SubgraphNodeModalProps extends ChakraProps {
  isOpen: boolean;
  onClose: () => void;
  displayName: string;
  description: string;
  nodes: Node<NodeType>[];
  edges: Edge<EdgeType>[];
  isLabelsLocked: boolean;
  onSubmit: (nodes: Node<NodeType>[], edges: Edge<EdgeType>[]) => void;
}

const SubgraphNodeModal: React.FC<SubgraphNodeModalProps> = ({
  color,
  isOpen,
  onClose,
  onSubmit,
  displayName,
  description,
  nodes,
  edges,
  isLabelsLocked,
}) => {
  const reactFlow = useReactFlow();

  const handleSubmit = useCallback(() => {
    onSubmit(reactFlow.getNodes(), reactFlow.getEdges());
    onClose();
  }, [reactFlow, onSubmit, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay />

      <ModalContent bg={"theme.dark.background"}>
        <ModalHeader>
          <HStack>
            <Text color={"white"} casing={"uppercase"}>
              Subgraph
            </Text>
            <Text color={color} casing={"uppercase"}>
              {displayName}
            </Text>
          </HStack>
        </ModalHeader>

        <ModalCloseButton color={color} />

        <ModalBody display={"grid"} flexDirection={"column"} bg={"var(--chakra-colors-chakra-body-bg)"} p={0}>
          <SubgraphCanvas subgraph={{ displayName, description, nodes, edges, isLabelsLocked }} />
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onClose} variant={"outline"} borderColor={color} borderRadius={0}>
              <Text color={color} casing={"uppercase"}>
                Cancel
              </Text>
            </Button>
            {isLabelsLocked ? (
              <Button onClick={handleSubmit} variant={"outline"} borderColor={color} borderRadius={0}>
                <Text color={color} casing={"uppercase"}>
                  Update
                </Text>
              </Button>
            ) : (
              <ConfirmModal
                color={color}
                variant={"outline"}
                borderColor={color}
                borderRadius={0}
                title={"Update"}
                onConfirm={handleSubmit}
              >
                The subgraph is unlocked, are you sure you want to update and rename all current inputs and outputs?
              </ConfirmModal>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface SaveAsModalProps extends ChakraProps {
  subgraphId?: string;
  subgraph?: Subgraph;
  onSaveAs: (subgraph: Subgraph) => void;
}

const SaveAsModal: React.FC<SaveAsModalProps> = ({ color, subgraphId, subgraph, onSaveAs }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overwriteExisting, setOverwriteExisting] = useState(Boolean(subgraphId));
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  const { reset, register, getValues } = useForm<Pick<Subgraph, "displayName" | "description">>({
    defaultValues: useMemo(
      () => ({
        displayName: subgraph?.displayName ?? "no title",
        description: subgraph?.description ?? "no description",
      }),
      [subgraph]
    ),
    mode: "onBlur",
  });

  const handleConfirm = () => {
    onSaveAs({
      subgraphQuestId: overwriteExisting ? subgraphId : ulid(),
      displayName: getValues("displayName"),
      description: getValues("description"),
      nodes: subgraph?.nodes ?? [],
      edges: subgraph?.edges ?? [],
      isLabelsLocked: true,
    });

    onClose();
  };

  useEffect(() => {
    reset({
      displayName: subgraph?.displayName ?? "no title",
      description: subgraph?.description ?? "no description",
    });
  }, [subgraph]);

  return (
    <>
      <Tooltip label={"save as template"}>
        <IconButton
          color={color}
          variant={"outline"}
          aria-label={"save as"}
          icon={<Icon as={MdSaveAs} />}
          onClick={onOpen}
        />
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={leastDestructiveRef}>
        <AlertDialogOverlay />

        <AlertDialogContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
          <AlertDialogHeader bg={"indigo.600"}>
            <Heading size={"md"}>
              <Text color={"white"}>Save As Template</Text>
            </Heading>
          </AlertDialogHeader>

          <AlertDialogBody>
            <form>
              <Stack>
                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Title
                    </Text>
                  </FormLabel>

                  <Input {...register("displayName")} />
                </FormControl>
                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Description
                    </Text>
                  </FormLabel>

                  <Textarea {...register("description")} />
                </FormControl>

                {subgraphId != null && (
                  <FormControl>
                    <Checkbox
                      isChecked={overwriteExisting}
                      onChange={({ target: { checked } }) => setOverwriteExisting(checked)}
                    >
                      <Text color={"white"} casing={"uppercase"}>
                        overwrite existing template
                      </Text>
                    </Checkbox>
                  </FormControl>
                )}
              </Stack>
            </form>
          </AlertDialogBody>

          <AlertDialogFooter gap={2}>
            <Button ref={leastDestructiveRef} onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} variant={"outline"}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const SubgraphNode: React.FC<NodeProps<NodeType<Subgraph>>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: questId = "" } = useParams();
  const toast = useToast();
  const { revalidate } = useRevalidator();

  // PATCH
  props.data.color = "pink.400";

  const {
    id: nodeId,
    data: { color, nodeData, sourceHandles: currentSourceHandles = [], targetHandles: currentTargetHandles = [] },
  } = props;

  const [isLabelsLocked, setIsLabelsLocked] = useState<boolean>(nodeData?.isLabelsLocked ?? false);

  const { deselectNodes } = useDeselectNodes();

  const handleOpenSubgraphCanvas = useCallback(() => {
    onOpen();

    // deselect nodes to prevent deleting parent canvas node
    deselectNodes();
  }, [onOpen, deselectNodes]);

  const { updateNodeDisplayName, updateNodeDescription, updateNodeIsHandlesEditable } = useUpdateNode(nodeId);
  const { updateNodeData } = useUpdateNodeData<Subgraph>(nodeId);
  const { updateNodeSourceHandles, updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();

  const { setSubgraph, getSubgraph } = useQuests();
  const [subgraphQuestId, setSubgraphQuestId] = useState<string | undefined>(nodeData?.subgraphQuestId);

  const handleSaveSubgraph = useCallback(
    (subgraph: Subgraph) => {
      const { subgraphQuestId, displayName, description } = subgraph;

      updateNodeDisplayName(displayName);
      updateNodeDescription(description);

      if (!nodeData) {
        return;
      }

      updateNodeData({
        ...nodeData,
        subgraphQuestId,
        displayName,
        description,
      });

      if (!subgraphQuestId) {
        return;
      }

      setSubgraph(subgraphQuestId, {
        displayName: subgraph.displayName,
        description: subgraph.description,
        questEditorDataDefinition: {
          ...props.data,
          label: displayName,
          nodeName: subgraphQuestId,
          nodeDescription: description,
          nodeCategory: "Subgraph Template",
          nodeData: subgraph,
        },
      })
        .then(({ questSubgraphId }) => {
          setSubgraphQuestId(questSubgraphId);

          revalidate();

          toast({
            title: "Saved template successfully",
            status: "success",
          });
        })
        .catch((error) => {
          console.error(error);

          toast({
            title: "Error occurred saving template",
            description: (error as Error).message,
            status: "error",
          });
        });
    },
    [updateNodeData, updateNodeDisplayName, updateNodeDescription, nodeData]
  );

  const handleUpdateSubgraphNodesAndEdges = useCallback(
    (nodes: Node<NodeType>[], edges: Edge<EdgeType>[]) => {
      if (!nodeData) {
        return;
      }

      const sourceHandles = nodes.flatMap(
        ({ data: { sourceHandles } }) =>
          sourceHandles?.filter(({ handleId }) => !edges.map(({ sourceHandle }) => sourceHandle).includes(handleId)) ??
          []
      );

      const targetHandles = nodes.flatMap(
        ({ data: { targetHandles } }) =>
          targetHandles?.filter(({ handleId }) => !edges.map(({ targetHandle }) => targetHandle).includes(handleId)) ??
          []
      );

      const currentSourceHandleIdsToLabels = Object.fromEntries(
        currentSourceHandles.map(({ handleId, label }) => [handleId ?? "", label ?? ""] ?? [])
      );

      updateNodeSourceHandles(
        sourceHandles.map((sourceHandle) => {
          const nodeLabel = nodes.find(({ data: { sourceHandles } }) =>
            sourceHandles?.some(({ handleId }) => handleId === sourceHandle.handleId)
          )?.data.label;

          let label = sourceHandle.label;

          if (!isLabelsLocked || !currentSourceHandleIdsToLabels[sourceHandle.handleId ?? ""]) {
            label = nodeLabel ? `${nodeLabel} (${sourceHandle.label})` : sourceHandle.label;
          }

          return {
            ...sourceHandle,
            label: isLabelsLocked ? currentSourceHandleIdsToLabels[sourceHandle.handleId ?? ""] ?? label : label,
          };
        })
      );

      const currentTargetHandleIdsToLabels = Object.fromEntries(
        currentTargetHandles.map(({ handleId, label }) => [handleId ?? "", label ?? ""] ?? [])
      );

      updateNodeTargetHandles(
        targetHandles.map((targetHandle) => {
          const nodeLabel = nodes.find(({ data: { targetHandles } }) =>
            targetHandles?.some(({ handleId }) => handleId === targetHandle.handleId)
          )?.data.label;

          let label = targetHandle.label;

          if (!isLabelsLocked || !currentTargetHandleIdsToLabels[targetHandle.handleId ?? ""]) {
            label = nodeLabel ? `${nodeLabel} (${targetHandle.label})` : targetHandle.label;
          }

          return {
            ...targetHandle,
            label: isLabelsLocked ? currentTargetHandleIdsToLabels[targetHandle.handleId ?? ""] ?? label : label,
          };
        })
      );

      updateNodeData({
        ...nodeData,
        nodes,
        edges,
      });

      updateNodeInternals(nodeId);
    },
    [updateNodeData, nodeData, isLabelsLocked]
  );

  const handleToggleSubgraphIsLabelsLocked = useCallback(() => {
    setIsLabelsLocked((isLabelsLocked) => {
      updateNodeIsHandlesEditable(!isLabelsLocked);

      return !isLabelsLocked;
    });
  }, [isLabelsLocked]);

  useEffect(() => {
    if (nodeData == null) {
      return;
    }

    if (nodeData.isLabelsLocked === isLabelsLocked) {
      return;
    }

    updateNodeData({
      ...nodeData,
      isLabelsLocked,
    });

    updateNodeIsHandlesEditable(!isLabelsLocked);
  }, [updateNodeData, nodeData, updateNodeIsHandlesEditable, isLabelsLocked]);

  useEffect(() => {
    if (subgraphQuestId == null) {
      return;
    }

    getSubgraph(subgraphQuestId)
      .then((subgraph) => {
        if (nodeData == null) {
          return;
        }

        if (subgraph != null) {
          return;
        }

        setSubgraphQuestId(undefined);

        handleSaveSubgraph({
          ...nodeData,
          subgraphQuestId: undefined,
        });
      })
      .catch((error) => console.error(error));
  }, [getSubgraph, subgraphQuestId, nodeData]);

  return (
    <>
      <QuestPointerContainerList mx={1} mb={1} color={color} nodes={nodeData.nodes} />

      <BaseNodeWithChildren
        headerButtons={[<SubgraphTemplateLabel color={color} subgraph={nodeData} />]}
        header={<SubgraphHeader color={color} subgraph={nodeData} onSaveSubgraph={handleSaveSubgraph} />}
        {...props}
      >
        <Stack p={2}>
          <HStack>
            <Tooltip label={`${isLabelsLocked ? "unlock" : "lock"} inputs and outputs`}>
              <IconButton
                color={color}
                variant={"outline"}
                aria-label={`${isLabelsLocked ? "unlock" : "lock"} inputs and outputs`}
                icon={<Icon as={isLabelsLocked ? MdLock : MdLockOpen} />}
                onClick={handleToggleSubgraphIsLabelsLocked}
              />
            </Tooltip>

            <Button variant={"outline"} w={"full"} onClick={handleOpenSubgraphCanvas}>
              <Text color={color} casing={"uppercase"}>
                Configure
              </Text>
            </Button>

            <SaveAsModal color={color} subgraphId={subgraphQuestId} subgraph={nodeData} onSaveAs={handleSaveSubgraph} />
          </HStack>
        </Stack>
      </BaseNodeWithChildren>

      <MultiProvider providers={[<ReactFlowProvider />, <ConnectionProvider />, <UserSettingsProvider />]}>
        <SubgraphNodeModal
          color={color}
          isOpen={isOpen}
          onClose={onClose}
          displayName={nodeData?.displayName ?? ""}
          description={nodeData?.description ?? ""}
          nodes={nodeData?.nodes ?? []}
          edges={nodeData?.edges ?? []}
          isLabelsLocked={isLabelsLocked}
          onSubmit={handleUpdateSubgraphNodesAndEdges}
        />
      </MultiProvider>
    </>
  );
};

export default memo(SubgraphNode);
