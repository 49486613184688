import styled from "@emotion/styled";
import { Controls } from "reactflow";
import { useToken } from "@chakra-ui/system";

const StyledControls = styled(Controls)(() => {
  const [backgroundColor, buttonBackgroundColor, hoverButtonBackgroundColor] = useToken("colors", [
    "theme.dark.background",
    "whiteAlpha.200",
    "whiteAlpha.400",
  ]);

  return `
    padding: 4px;
    background-color: ${backgroundColor};
    border-radius: 6px;
    overflow: hidden;

    button:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    button:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    button {
      padding: 0.75rem;
      background-color: ${buttonBackgroundColor};
      border: none;
      color: white;

      &:hover {
        background-color: ${hoverButtonBackgroundColor};
      }

      path {
        fill: white;
      }
    }
  `;
});

export default StyledControls;
