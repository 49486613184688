import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";

interface FormData {
  radius: number;
}

const PlayerMoveToNpcNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const radius = formData?.radius ?? 24;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      radius,
    },
    mode: "onChange",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ radius }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.radius = radius;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow, nodeId]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Radius
              </Text>
            </FormLabel>
            <NumberInput isRequired step={1} defaultValue={0} min={0}>
              <NumberInputField id={"radius"} {...register("radius", { valueAsNumber: true })} color={color} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(PlayerMoveToNpcNode);
