import { useQuery } from "@tanstack/react-query";
import { QuestEditorCommitWithId } from "@worldwidewebb/client-quests";
import { getQuestCommits } from "../../api/quests/questCommits";

export default function useQuestCommits(questId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: questCommits,
  } = useQuery<Omit<QuestEditorCommitWithId, "quest">[]>({
    queryKey: ["questCommit", questId],
    queryFn: () => getQuestCommits(questId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questCommits,
  };
}
