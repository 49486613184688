import React, { useCallback } from "react";
import {
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdCheckCircle, MdList, MdRemoveCircle, MdRestore, MdSave } from "react-icons/md";
import { BsFillArrowUpSquareFill } from "react-icons/bs";
import { AiOutlineCopy, AiOutlineDownload } from "react-icons/ai";
import { TextEdit } from "../base/TextEdit";
import { NodeDiff } from "../../hooks/useNodeTypesDiff";
import { NodeDiffModal } from "./NodeDiffModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import UserSessionControl from "../UserSessionControl";
import QuestResetButton from "../quests/QuestResetButton";
import QuestDeployButton from "../quests/QuestDeployButton";
import QuestDeployVersionButton from "../../features/quests/QuestDeployVersionButton";

interface ControlToolbarProps {
  title: string;
  onUpdateTitle: (title: string) => Promise<void>;
  isReady: boolean;
  onLoadAuto: () => void;
  onLoad: () => Promise<void>;
  onExportToFile: () => void;
  onExportToClipboard: () => void;
  hasDefinitionsUpgrade: boolean;
  onDefinitionsUpgrade: () => void;
  nodeDiffs: NodeDiff[];
}

export const ControlToolbar: React.FC<ControlToolbarProps> = ({
  title,
  onUpdateTitle,
  isReady,
  onLoadAuto,
  onLoad,
  onExportToFile,
  onExportToClipboard,
  hasDefinitionsUpgrade,
  onDefinitionsUpgrade,
  nodeDiffs,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleUpdateTitle = useCallback(
    async (title: string) => {
      try {
        await onUpdateTitle(title);

        toast({
          title: "Updating of quest successful",
          status: "success",
        });
      } catch (error) {
        toast({
          title: "Updating of quest unsuccessful",
          description: "See developer console for details",
          status: "error",
        });
        console.error(error);
      }
    },
    [toast, onUpdateTitle]
  );

  const handleDefinitionsUpgrade = useCallback(() => {
    try {
      onDefinitionsUpgrade();

      toast({
        title: "Updating of definitions successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Updating of definitions unsuccessful",
        description: "See developer console for details",
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onDefinitionsUpgrade]);

  const handleLoadAuto = useCallback(async () => {
    try {
      await onLoadAuto();

      toast({
        title: "Restoring of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Restoring of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onLoadAuto]);

  const handleLoad = useCallback(async () => {
    try {
      await onLoad();

      toast({
        title: "Loading of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Loading of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onLoad]);

  const handleExportToFile = useCallback(() => {
    try {
      onExportToFile();

      toast({
        title: "Export of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Export of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onExportToFile]);

  const handleExportToClipboard = useCallback(() => {
    try {
      onExportToClipboard();

      toast({
        title: "Export of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Export of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onExportToClipboard]);

  const { id: questId } = useParams();

  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  return (
    <HStack position={"fixed"} right={20} top={4}>
      <Card p={1} bg={"theme.dark.background"}>
        <Flex gap={1} align={"center"}>
          <TextEdit value={title} onUpdate={handleUpdateTitle} />

          <Divider orientation={"vertical"} h={8} />

          {nodeDiffs.length !== 0 && (
            <>
              <Tooltip label={"upgrade history"}>
                <IconButton
                  color={"orange.600"}
                  icon={<Icon as={MdList} />}
                  aria-label={"upgrade history"}
                  onClick={() => onOpen()}
                />
              </Tooltip>

              <Divider orientation={"vertical"} h={8} />
            </>
          )}

          {hasDefinitionsUpgrade && (
            <>
              <Tooltip label={"upgrade definitions"}>
                <IconButton
                  color={"orange.600"}
                  icon={<Icon as={BsFillArrowUpSquareFill} />}
                  aria-label={"upgrade definitions"}
                  onClick={handleDefinitionsUpgrade}
                />
              </Tooltip>

              <Divider orientation={"vertical"} h={8} />
            </>
          )}

          <Tooltip label={"load from auto-save"}>
            <IconButton color={"red.800"} icon={<Icon as={MdRestore} />} aria-label={"load"} onClick={handleLoadAuto} />
          </Tooltip>
          <Tooltip label={"load"}>
            <IconButton color={"green.600"} icon={<Icon as={MdRestore} />} aria-label={"load"} onClick={handleLoad} />
          </Tooltip>

          <Divider orientation={"vertical"} h={8} />

          <Tooltip label={"export to file"}>
            <IconButton
              color={"white"}
              icon={<Icon as={AiOutlineDownload} />}
              aria-label={"export to file"}
              onClick={handleExportToFile}
            />
          </Tooltip>
          <Tooltip label={"export to clipboard"}>
            <IconButton
              color={"white"}
              icon={<Icon as={AiOutlineCopy} />}
              aria-label={"export to clipboard"}
              onClick={handleExportToClipboard}
            />
          </Tooltip>

          <Divider orientation={"vertical"} h={8} />

          {questId && userId && <QuestResetButton questId={questId} userId={userId} />}

          <UserSessionControl />
        </Flex>

        <NodeDiffModal isOpen={isOpen} onClose={onClose} nodeDiffs={nodeDiffs} />
      </Card>

      <Card p={1} bg={"theme.dark.background"}>
        <QuestDeployVersionButton />
      </Card>

      {isReady && questId && (
        <Card p={1} bg={"theme.dark.background"}>
          <QuestDeployButton questId={questId} />
        </Card>
      )}
    </HStack>
  );
};
