import { Heading, HStack, Icon, MenuItem, Text, Tooltip } from "@chakra-ui/react";
import { NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo, useCallback } from "react";
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md";
import useCreateQuestNode from "./useCreateQuestNode";

// TODO: VERIFICATION PENDING

interface QuestNodeListItemProps {
  nodeType: NodeType;
  x: number;
  y: number;
}

function QuestNodeListItem({
  nodeType: { label, color, nodeName, nodeDescription, isReady },
  x,
  y,
}: QuestNodeListItemProps) {
  const { createQuestNode } = useCreateQuestNode();

  const handleSelectNodeType = useCallback(async () => {
    createQuestNode(nodeName, { x, y });
  }, [createQuestNode, nodeName, x, y]);

  return (
    <Tooltip>
      <MenuItem
        display={"flex"}
        bg={"theme.dark.background"}
        borderTopColor={color}
        borderTopWidth={4}
        _hover={{ bg: "blackAlpha.200" }}
        onClick={handleSelectNodeType}
      >
        <Heading flexGrow={1} display={"flex"} size={"xs"}>
          <HStack flexGrow={1}>
            <Icon color={isReady ? "green.600" : "red.800"} as={isReady ? MdCheckCircle : MdRemoveCircle} />

            <Text color={color} casing={"uppercase"} p={2}>
              {label}
            </Text>
          </HStack>
        </Heading>
      </MenuItem>
    </Tooltip>
  );
}

export default memo(QuestNodeListItem);
