import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { GiPresent } from "react-icons/gi";
import { insertSurroundingText } from "../utils/insertSurroundingText";

export const tagItem: Command = {
  category: "game entity",
  icon: <Icon as={GiPresent} />,
  name: "item",
  tooltip: "tag as item",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#item}}", "{{/item}}"),
};
