import React, { memo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { FlowNodeWithChildren } from "./FlowNode";

const SpawnEnemySequencerNode: React.FC<NodeProps<NodeType>> = (props) => {
  // TODO: TECH-4000 (various external services for consuming for configuration)

  return <FlowNodeWithChildren {...props}></FlowNodeWithChildren>;
};

export default memo(SpawnEnemySequencerNode);
