import { FormControl, FormLabel, Input, InputProps } from "@chakra-ui/react";
import React, { ForwardedRef, PropsWithChildren } from "react";

// TODO: VERIFICATION PENDING

interface FormInputProps extends PropsWithChildren, InputProps {
  disabled?: boolean;
}

function FormInput(props: FormInputProps, ref: ForwardedRef<HTMLInputElement>) {
  const {
    disabled,
    children,
    color,
    colorScheme,
    borderColor = color,
    borderRadius = 0,
    borderWidth = 2,
    ...inputProps
  } = props;

  return (
    <FormControl>
      <FormLabel>{children}</FormLabel>

      <Input
        ref={ref}
        disabled={disabled}
        color={color}
        colorScheme={colorScheme}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        {...inputProps}
      />
    </FormControl>
  );
}

export default React.forwardRef(FormInput);
