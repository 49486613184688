import React, { useCallback } from "react";
import { Card, Divider, Flex, Icon, IconButton, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { MdList } from "react-icons/md";
import { BsFillArrowUpSquareFill } from "react-icons/bs";
import { AiOutlineCopy, AiOutlineDownload } from "react-icons/ai";
import { NodeDiff } from "../../hooks/useNodeTypesDiff";
import { NodeDiffModal } from "./NodeDiffModal";

interface ControlToolbarSubgraphProps {
  onExportToFile: () => void;
  onExportToClipboard: () => void;
  hasDefinitionsUpgrade: boolean;
  onDefinitionsUpgrade: () => void;
  nodeDiffs: NodeDiff[];
}

export const ControlToolbarSubgraph: React.FC<ControlToolbarSubgraphProps> = ({
  onExportToFile,
  onExportToClipboard,
  hasDefinitionsUpgrade,
  onDefinitionsUpgrade,
  nodeDiffs,
}) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDefinitionsUpgrade = useCallback(() => {
    try {
      onDefinitionsUpgrade();

      toast({
        title: "Updating of definitions successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Updating of definitions unsuccessful",
        description: "See developer console for details",
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onDefinitionsUpgrade]);

  const handleExportToFile = useCallback(() => {
    try {
      onExportToFile();

      toast({
        title: "Export of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Export of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onExportToFile]);

  const handleExportToClipboard = useCallback(() => {
    try {
      onExportToClipboard();

      toast({
        title: "Export of quest data successful",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Export of quest data unsuccessful",
        description: (error as Error).message,
        status: "error",
      });
      console.error(error);
    }
  }, [toast, onExportToClipboard]);

  return (
    <Card p={1} position={"absolute"} right={20} top={4} bg={"theme.dark.background"}>
      <Flex gap={1} align={"center"}>
        {nodeDiffs.length !== 0 && (
          <>
            <Tooltip label={"upgrade history"}>
              <IconButton
                color={"orange.600"}
                icon={<Icon as={MdList} />}
                aria-label={"upgrade history"}
                onClick={() => onOpen()}
              />
            </Tooltip>

            <Divider orientation={"vertical"} h={8} />
          </>
        )}

        {hasDefinitionsUpgrade && (
          <>
            <Tooltip label={"upgrade definitions"}>
              <IconButton
                color={"orange.600"}
                icon={<Icon as={BsFillArrowUpSquareFill} />}
                aria-label={"upgrade definitions"}
                onClick={handleDefinitionsUpgrade}
              />
            </Tooltip>

            <Divider orientation={"vertical"} h={8} />
          </>
        )}

        <Tooltip label={"export to file"}>
          <IconButton
            color={"white"}
            icon={<Icon as={AiOutlineDownload} />}
            aria-label={"export to file"}
            onClick={handleExportToFile}
          />
        </Tooltip>
        <Tooltip label={"export to clipboard"}>
          <IconButton
            color={"white"}
            icon={<Icon as={AiOutlineCopy} />}
            aria-label={"export to clipboard"}
            onClick={handleExportToClipboard}
          />
        </Tooltip>
      </Flex>

      <NodeDiffModal isOpen={isOpen} onClose={onClose} nodeDiffs={nodeDiffs} />
    </Card>
  );
};
