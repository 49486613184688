import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteQuestPointerForUser } from "../../api/quests/questPointers";

interface DeleteQuestPointer {
  userId: string;
  questPointerId: string;
}

export default function useDeleteQuestPointer() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isDeleting, mutate: deleteQuestPointer } = useMutation({
    mutationFn: ({ userId, questPointerId }: DeleteQuestPointer) => deleteQuestPointerForUser(userId, questPointerId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questPointer"],
      });

      toast({ title: "Quest pointer delete successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest pointer delete unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isDeleting,
    deleteQuestPointer,
  };
}
