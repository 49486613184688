import { Icon, IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react";
import { MdOpenInNew } from "react-icons/md";
import { Link } from "react-router-dom";

export interface NavigateToButtonProps extends Omit<IconButtonProps, "aria-label"> {
  resourceName: string;
  resourcePath: string;
}

function NavigateToButton({
  resourceName,
  resourcePath,
  color,
  colorScheme,
  bg = "theme.dark.background",
  borderColor = color,
  borderWidth = 2,
  ...iconButtonProps
}: NavigateToButtonProps) {
  return (
    <Tooltip
      label={`open ${resourceName}`}
      color={color}
      colorScheme={colorScheme}
      bg={bg}
      borderColor={borderColor}
      borderWidth={borderWidth}
    >
      <IconButton
        aria-label={`open ${resourceName}`}
        color={color}
        colorScheme={colorScheme}
        bg={bg}
        borderColor={borderColor}
        borderWidth={borderWidth}
        icon={<Icon as={MdOpenInNew} position={"absolute"} />}
        variant={"outline"}
        isRound={true}
        as={Link}
        to={resourcePath}
        target={"_blank"}
        {...iconButtonProps}
      />
    </Tooltip>
  );
}

export default NavigateToButton;
