import { useCallback } from "react";
import { Edge, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { NodeType, SourceHandle, TargetHandle } from "../models/nodeType";
import { createHandleId } from "../utils/handleId";

export const useUpdateNodeHandles = (nodeId: string) => {
  const reactFlow = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const updateTargetHandles = useCallback(
    (oldTargetHandles: TargetHandle[] | undefined, newTargetHandles: TargetHandle[] | undefined) => {
      const removedTargetHandles = oldTargetHandles
        ?.filter(
          (oldTargetHandle) => newTargetHandles?.find(({ handleId }) => handleId === oldTargetHandle.handleId) == null
        )
        .map(({ handleId }) => handleId);

      const invalidatedEdges: Edge[] = reactFlow
        .getEdges()
        .filter(({ targetHandle }) => removedTargetHandles?.includes(targetHandle ?? undefined));

      newTargetHandles?.forEach((newTargetHandle) => {
        const { handleId } = newTargetHandle;

        if (handleId == null) {
          newTargetHandle.handleId = createHandleId(newTargetHandle);
        } else {
          const oldTargetHandle = oldTargetHandles?.find((oldTargetHandle) => oldTargetHandle.handleId === handleId);

          if (oldTargetHandle == null) {
            return;
          }

          if (
            oldTargetHandle.handleType === newTargetHandle.handleType &&
            oldTargetHandle.handleCategory === newTargetHandle.handleCategory
          ) {
            return;
          }

          newTargetHandle.handleId = createHandleId(newTargetHandle);

          const invalidatedEdge = reactFlow
            .getEdges()
            .find(({ targetHandle }) => targetHandle === oldTargetHandle.handleId);

          if (invalidatedEdge != null) {
            invalidatedEdges.push(invalidatedEdge);
          }
        }
      });

      reactFlow.deleteElements({ edges: invalidatedEdges });

      return newTargetHandles;
    },
    [reactFlow]
  );

  const updateNodeTargetHandles = useCallback(
    (newTargetHandles: TargetHandle[]) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;
        const { targetHandles: oldTargetHandles } = nodeDataCloned;

        node.data = {
          ...nodeDataCloned,
          targetHandles: updateTargetHandles(oldTargetHandles, newTargetHandles),
        };

        return nodes;
      });

      updateNodeInternals(nodeId);
    },
    [reactFlow, updateNodeInternals, nodeId, updateTargetHandles]
  );

  const updateSourceHandles = useCallback(
    (oldSourceHandles: SourceHandle[] | undefined, newSourceHandles: SourceHandle[] | undefined) => {
      const removedSourceHandles = oldSourceHandles
        ?.filter(
          (oldSourceHandle) => newSourceHandles?.find(({ handleId }) => handleId === oldSourceHandle.handleId) == null
        )
        .map(({ handleId }) => handleId);

      const invalidatedEdges: Edge[] = reactFlow
        .getEdges()
        .filter(({ sourceHandle }) => removedSourceHandles?.includes(sourceHandle ?? undefined));

      newSourceHandles?.forEach((newSourceHandle) => {
        const { handleId } = newSourceHandle;

        if (handleId == null) {
          newSourceHandle.handleId = createHandleId(newSourceHandle);
        } else {
          const oldSourceHandle = oldSourceHandles?.find((oldSourceHandle) => oldSourceHandle.handleId === handleId);

          if (oldSourceHandle == null) {
            return;
          }

          if (
            oldSourceHandle.handleType === newSourceHandle.handleType &&
            oldSourceHandle.handleCategory === newSourceHandle.handleCategory
          ) {
            return;
          }

          newSourceHandle.handleId = createHandleId(newSourceHandle);

          const invalidatedEdge = reactFlow
            .getEdges()
            .find(({ sourceHandle }) => sourceHandle === oldSourceHandle.handleId);

          if (invalidatedEdge != null) {
            invalidatedEdges.push(invalidatedEdge);
          }
        }
      });

      reactFlow.deleteElements({ edges: invalidatedEdges });

      return newSourceHandles;
    },
    [reactFlow]
  );

  const updateNodeSourceHandles = useCallback(
    (newSourceHandles: SourceHandle[]) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;
        const { sourceHandles: oldSourceHandles } = nodeDataCloned;

        node.data = {
          ...nodeDataCloned,
          sourceHandles: updateSourceHandles(oldSourceHandles, newSourceHandles),
        };

        return nodes;
      });

      updateNodeInternals(nodeId);
    },
    [reactFlow, updateNodeInternals, nodeId, updateSourceHandles]
  );

  return {
    updateNodeTargetHandles,
    updateNodeSourceHandles,
  };
};
