import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

interface ContextType {
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  reloadResults: () => void;
  searchResults: [string, string][];
  isLoading: boolean;
  hasError: boolean;
  hasSearchResults: boolean;
}

const Context = createContext<ContextType | null>(null);

interface AsyncSearchProviderProps extends PropsWithChildren {
  initialSearchQuery?: string;
  onSearchAsync: (searchQuery?: string) => Promise<[string, string][]>;
}

const AsyncSearchProvider: React.FC<AsyncSearchProviderProps> = ({
  initialSearchQuery = "",
  onSearchAsync,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery);
  const [searchResults, setSearchResults] = useState<[string, string][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const hasSearchResults = searchResults.length !== 0;

  const reloadResults = useCallback(() => {
    setIsLoading(true);
    setHasError(false);

    onSearchAsync(searchQuery)
      .then(setSearchResults)
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [onSearchAsync, searchQuery]);

  useEffect(() => {
    const timeoutId = setTimeout(reloadResults, 1000);

    return () => clearTimeout(timeoutId);
  }, [reloadResults]);

  useEffect(() => {
    setSearchQuery(initialSearchQuery);
  }, [initialSearchQuery]);

  return (
    <Context.Provider
      value={{
        searchQuery,
        setSearchQuery,
        reloadResults,
        searchResults,
        isLoading,
        hasError,
        hasSearchResults,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useAsyncSearchProvider = () => {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useAsyncSearchProvider is used outside of AsyncSearchProvider");
  }

  return context;
};

export { AsyncSearchProvider, useAsyncSearchProvider };
