import { DgsScriptQuery, QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export interface Gui extends DgsScriptQuery {
  guiName: string;
}

export async function getGuis(): Promise<Gui[]> {
  try {
    const { data } = await questEditorApi.getGuis();

    if (!data) {
      return [];
    }

    const guis = data as Gui[];

    return guis.sort(({ displayName: a }, { displayName: b }) => (a < b ? -1 : a > b ? 1 : 0));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
