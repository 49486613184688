import React, { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Checkbox, FormControl, FormLabel, Text } from "@chakra-ui/react";

interface TakeItem extends NodeData {
  notifyPlayer: boolean;
}

const TakeItemNode: React.FC<NodeProps<NodeType<TakeItem>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const notifyPlayer = nodeData?.notifyPlayer ?? true;

  const { register, handleSubmit } = useForm<TakeItem>({
    defaultValues: useMemo(
      () => ({
        notifyPlayer,
      }),
      [notifyPlayer]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<TakeItem>(nodeId);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Notify Player
              </Text>
            </FormLabel>
            <Checkbox color={color} {...register("notifyPlayer")} />
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(TakeItemNode);
