import { useQuery } from "@tanstack/react-query";
import { DgsSpriteQuery } from "@worldwidewebb/client-quests";
import { getLuts } from "../../api/quests/luts";

export default function useLuts() {
  const {
    isLoading,
    isFetching,
    error,
    data: luts,
  } = useQuery<DgsSpriteQuery[]>({
    queryKey: ["lut"],
    queryFn: () => getLuts(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    luts,
  };
}
