import {
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";
import { MdClose, MdOutlineMarkunread } from "react-icons/md";
import { useReactFlowStoreProvider } from "../../context/ReactFlowStoreContext";
import { useStoreDispatch, useStoreSelector } from "../../store/hooks";
import { removeQuestNotifications } from "../../store/quests";
import QuestNotificationComponent from "./QuestNotificationComponent";

function QuestNotificationsButton(flexProps: FlexProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { nodes: questNodes } = useReactFlowStoreProvider();
  const questNotifications = useStoreSelector(({ quests: { notifications } }) => notifications);

  const activeQuestNotifications = useMemo(
    () =>
      questNotifications.filter(({ questId }) => questNodes.some((questNode) => questNode.id === questId)).reverse(),
    [questNodes, questNotifications]
  );

  const dispatch = useStoreDispatch();

  function handleClose() {
    dispatch(removeQuestNotifications());

    onClose();
  }

  const notificationColor = questNotifications.length ? "red.800" : "white;";

  return (
    <>
      <Flex {...flexProps}>
        <Tooltip
          placement={"left"}
          label={"show quest notifications"}
          color={"white"}
          colorScheme={"whiteAlpha"}
          bg={"theme.dark.background"}
          borderColor={"white"}
          borderWidth={2}
        >
          <IconButton
            aria-label={"show quest notifications"}
            color={"white"}
            colorScheme={"whiteAlpha"}
            bg={"theme.dark.background"}
            borderColor={"white"}
            borderWidth={2}
            icon={<Icon as={MdOutlineMarkunread} position={"absolute"} />}
            variant={"outline"}
            isRound={true}
            onClick={onOpen}
          />
        </Tooltip>

        <Tag
          position={"absolute"}
          top={-4}
          right={-4}
          w={8}
          h={8}
          color={notificationColor}
          bg={"theme.dark.background"}
          borderColor={notificationColor}
          borderWidth={2}
          justifyContent={"center"}
        >
          {questNotifications.length}
        </Tag>
      </Flex>

      <Flex position={"fixed"} right={0} top={0} bottom={0}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              style={{ display: "flex" }}
              variants={{
                hide: { transition: { width: { delay: 0.25 } }, opacity: 0, width: 0 },
                show: { transition: { opacity: { delay: 0.25 } }, opacity: 1, width: "auto" },
              }}
              initial={"hide"}
              animate={"show"}
              exit={"hide"}
              transition={{ ease: "easeInOut", duration: 0.25 }}
            >
              <Stack p={4} bg={"theme.dark.background"}>
                <HStack spacing={12}>
                  <Heading size={"md"}>
                    <Text color={"white"} casing={"uppercase"}>
                      Quest Notifications
                    </Text>
                  </Heading>

                  <Tooltip
                    placement={"left"}
                    label={"hide quest notifications"}
                    color={"white"}
                    colorScheme={"whiteAlpha"}
                    bg={"theme.dark.background"}
                    borderColor={"white"}
                    borderWidth={2}
                  >
                    <IconButton
                      aria-label={"hide quest notifications"}
                      color={"white"}
                      colorScheme={"whiteAlpha"}
                      bg={"theme.dark.background"}
                      borderColor={"white"}
                      borderWidth={2}
                      icon={<Icon as={MdClose} position={"absolute"} />}
                      variant={"outline"}
                      isRound={true}
                      onClick={handleClose}
                    />
                  </Tooltip>
                </HStack>

                {activeQuestNotifications.map((activeQuestNotification, index) => (
                  <QuestNotificationComponent key={index} questNotification={activeQuestNotification} />
                ))}
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>
      </Flex>
    </>
  );
}

export default QuestNotificationsButton;
