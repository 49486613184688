import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QuestEditorFolder } from "@worldwidewebb/client-quests";
import { updateQuestFolder as updateQuestFolderAsync } from "../../api/quests/questFolders";

interface UpdateQuestFolder {
  questEditorFolderId: string;
  questEditorFolder: QuestEditorFolder;
}

export default function useUpdateQuestFolder() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: updateQuestFolder } = useMutation({
    mutationFn: ({ questEditorFolderId, questEditorFolder }: UpdateQuestFolder) =>
      updateQuestFolderAsync(questEditorFolderId, questEditorFolder),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questFolder"],
      });

      toast({ title: "Quest folder update successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest folder update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestFolder,
  };
}
