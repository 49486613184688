import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateQuestPointerForUser } from "../../api/quests/questPointers";

interface UpdateQuestPointer {
  userId: string;
  questPointerId: string;
  nodeId: string;
}

export default function useUpdateQuestPointer() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: updateQuestPointer } = useMutation({
    mutationFn: ({ userId, questPointerId, nodeId }: UpdateQuestPointer) =>
      updateQuestPointerForUser(userId, questPointerId, nodeId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questPointer"],
      });

      toast({ title: "Quest pointer update successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest pointer update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestPointer,
  };
}
