import React, { memo, SyntheticEvent, useCallback, useState } from "react";
import { Image as ImageComponent, ImageProps } from "@chakra-ui/react";

interface ImageFrameProps extends ImageProps {
  frameTimes?: number[];
}

const ImageFrame: React.FC<ImageFrameProps> = ({ src, frameTimes, ...imageProps }) => {
  const [w, setW] = useState<number>(0);
  const [h, setH] = useState<number>(0);

  const handleLoad = useCallback(
    ({ target }: SyntheticEvent<HTMLImageElement, Event>) => {
      const image = target as HTMLImageElement;

      setW(image.naturalWidth / (frameTimes?.length || 1));
      setH(image.naturalHeight);
    },
    [frameTimes]
  );

  return (
    <ImageComponent
      src={src}
      w={`${w}px`}
      h={`${h}px`}
      objectFit={"cover"}
      objectPosition={"left top"}
      onLoad={handleLoad}
      {...imageProps}
    />
  );
};

export default memo(ImageFrame);
