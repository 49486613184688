import { useQuery } from "@tanstack/react-query";
import { getQuest } from "../../api/quests/quests";
import { QuestWithId } from "../../models/api/quest";

export default function useQuest(questId: string, questVersion?: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: quest,
  } = useQuery<QuestWithId>({
    queryKey: ["quest", questId, questVersion],
    queryFn: () => getQuest(questId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    quest,
  };
}
