import { AssetsApi } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getCraftingStations(displayName?: string) {
  try {
    const { data: craftingStations } = await assetsApi.getCraftingStationsByQuery(displayName);

    return craftingStations.sort(({ craftingStationId: a }, { craftingStationId: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
