import { HandleCategory, NodeCategory } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
export * from "@worldwidewebb/quest-shared/dist/editor/nodeType";

export const getHandleCategoryColor = (handleCategory: HandleCategory, isReady?: boolean) => {
  switch (handleCategory) {
    case "hook": {
      return "green.600";
    }
    case "start": {
      return "red.800";
    }
    case "end": {
      return "red.800";
    }
    case "flow": {
      return "green.600";
    }
    case "data": {
      return "blue.400";
    }
    case "quest": {
      return isReady ? "green.600" : "red.800";
    }
    case "questId": {
      return "purple.600";
    }
    case "taskId": {
      return "purple.600";
    }
    case "vendorId": {
      return "blue.400";
    }
    case "entity": {
      return "blue.400";
    }
    case "soundId": {
      return "blue.400";
    }
    case "craftingStationId": {
      return "blue.400";
    }
    case "playerActionAndRequirement": {
      return "blue.400";
    }
    default: {
      return undefined;
    }
  }
};

export const nodeCategories: NodeCategory[] = [
  "Tutorial",
  "Camera and Music",
  "Flow",
  "Item",
  "Player",
  "Quest Log",
  "Start/End",
  "Subgraph",
  "Subgraph Template",
  "NPC Control",
  "Location",
  "Logic",
  "Flag",
  "Allegiance",
  "Object",
  "Context",
];
