import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button, HStack, Text } from "@chakra-ui/react";
import { NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import ScrollableBox from "../../base/ScrollableBox";
import QuestNodeListItem from "./QuestNodeListItem";

// TODO: VERIFICATION PENDING

interface QuestNodeTypeListProps {
  questNodeTypes: NodeType[];
  x: number;
  y: number;
  searchValue: string;
}

function QuestNodeTypeList({ questNodeTypes, x, y, searchValue }: QuestNodeTypeListProps) {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const questNodeTypesGroupedByCategory = useMemo<Record<string, NodeType[]>>(
    () =>
      questNodeTypes.reduce((questNodeTypesGroupedByCategory: Record<string, NodeType[]>, nodeType: NodeType) => {
        const { nodeCategory } = nodeType;

        if (nodeCategory == null) {
          return questNodeTypesGroupedByCategory;
        }

        questNodeTypesGroupedByCategory[nodeCategory] ??= [];
        questNodeTypesGroupedByCategory[nodeCategory].push(nodeType);

        return questNodeTypesGroupedByCategory;
      }, {}),
    [questNodeTypes]
  );

  const questNodeTypeCategoryEntries = Object.entries(questNodeTypesGroupedByCategory);

  const handleCollapseAll = useCallback(() => {
    setOpenIndexes([]);
  }, [questNodeTypeCategoryEntries]);

  const handleExpandAll = useCallback(() => {
    setOpenIndexes(Array.from({ length: questNodeTypeCategoryEntries.length }, (_, index) => index));
  }, [questNodeTypeCategoryEntries]);

  const handleToggleOpenIndex = useCallback(
    (index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes((openIndexes) => openIndexes.filter((openIndex) => openIndex !== index));
      } else {
        setOpenIndexes((openIndexes) => openIndexes.concat(index));
      }
    },
    [openIndexes]
  );

  useEffect(() => {
    if (searchValue !== "") {
      handleExpandAll();
    } else {
      handleCollapseAll();
    }
  }, [searchValue]);

  return (
    <>
      <HStack>
        <Button color={"white"} bg={"indigo.600"} borderRadius={0} w={"full"} onClick={handleCollapseAll}>
          <Text color={"white"} casing={"uppercase"}>
            Collapse All
          </Text>
        </Button>

        <Button color={"white"} bg={"indigo.600"} borderRadius={0} w={"full"} onClick={handleExpandAll}>
          <Text color={"white"} casing={"uppercase"}>
            Expand All
          </Text>
        </Button>
      </HStack>

      <ScrollableBox color={"indigo.600"}>
        <Accordion allowMultiple index={openIndexes}>
          {questNodeTypeCategoryEntries.map(([nodeCategory, nodeTypes], index) => (
            <AccordionItem key={nodeCategory} onClick={() => handleToggleOpenIndex(index)}>
              <AccordionButton>
                <Text color={"white"} casing={"uppercase"} fontWeight={500}>
                  {nodeCategory}
                </Text>
              </AccordionButton>

              <AccordionPanel p={0}>
                {nodeTypes.map((nodeType) => (
                  <QuestNodeListItem nodeType={nodeType} x={x} y={y} />
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </ScrollableBox>
    </>
  );
}

export default memo(QuestNodeTypeList);
