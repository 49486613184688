import { DependencyList, RefObject, useEffect, useState } from "react";

export default function useContainerWidth(
  containerRef: RefObject<HTMLDivElement | null>,
  containerRefDependencies: DependencyList = []
) {
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const [container] = entries;

      if (container == null) {
        return;
      }

      setContainerWidth(container.contentRect.width);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [containerRef, ...containerRefDependencies]);

  return containerWidth;
}
