import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createQuest as createQuestAsync } from "../../api/quests/quests";

export default function useCreateQuest() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isCreating, mutate: createQuest } = useMutation({
    mutationFn: (questName: string) => createQuestAsync(questName),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["quest"],
      });

      toast({ title: "Quest create successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest create unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isCreating,
    createQuest,
  };
}
