import { Image, ImageProps, Spinner } from "@chakra-ui/react";
import { memo } from "react";
import useNpc from "../../hooks/npcs/useNpc";

interface NpcSpriteProps extends ImageProps {
  npcId: string;
}

function NpcSprite({ npcId, boxSize = 32, ...imageProps }: NpcSpriteProps) {
  const { isLoading, error, npc, sprite } = useNpc(npcId);

  if (isLoading) {
    return <Spinner size={"md"} color={"white"} />;
  }

  if (error || npc == null || sprite == null) {
    return <Spinner size={"md"} color={"red.800"} />;
  }

  const { displayName } = npc;

  const { imageUri } = sprite;

  return (
    <Image src={imageUri} alt={displayName} sx={{ imageRendering: "pixelated" }} boxSize={boxSize} {...imageProps} />
  );
}

export default memo(NpcSprite);
