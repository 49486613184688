import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Center, ChakraProps, FormControl, FormLabel, HStack, Input, Spinner, Text, Icon } from "@chakra-ui/react";
import { hasProfile } from "../../api/users/users";
import { Form } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdError } from "react-icons/md";
import { AxiosError } from "axios";

interface FormData {
  userId: string;
}

interface SelectUserByUserIdProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectUserByUserId: React.FC<SelectUserByUserIdProps> = ({ color, value, setValue }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { register, reset, handleSubmit } = useForm<FormData>({
    defaultValues: useMemo(
      () => ({
        userId: value,
      }),
      [value]
    ),
    mode: "onBlur",
  });

  const updateSelectedUser = useCallback(({ userId }: FormData) => {
    setIsLoading(true);
    setHasError(false);
    setError("");

    hasProfile(userId)
      .then(() => setValue(userId))
      .catch((error) => {
        setHasError(true);

        if (error instanceof AxiosError) {
          setError(error.response?.data.details ?? "");
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setHasError(false);
    setError("");

    reset({ userId: value });
  }, [reset, value]);

  return (
    <Form onSubmit={handleSubmit(updateSelectedUser)} onBlur={handleSubmit(updateSelectedUser)}>
      <HStack alignItems={"end"}>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              User ID
            </Text>
          </FormLabel>

          <Input color={color} {...register("userId")} borderColor={"white"} borderRadius={0} borderWidth={2} />
        </FormControl>

        {isLoading && (
          <Center p={1}>
            <Spinner size={"lg"} color={color?.toString()} />
          </Center>
        )}

        {hasError && (
          <Center p={3}>
            <Icon color={"red.800"} as={MdError} />
          </Center>
        )}
      </HStack>

      {error && (
        <Text color={"red.800"} casing={"uppercase"}>
          {error}
        </Text>
      )}
    </Form>
  );
};

export default SelectUserByUserId;
