import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";

type Emote =
  | "Wave"
  | "Laugh"
  | "Jazz Hands"
  | "Tea Bag"
  | "Sweaty"
  | "Exclaim"
  | "Applaud"
  | "Fisting Gentle"
  | "UWU"
  | "Heart"
  | "Bok Bok"
  | "Sit Down"
  | "Cat Black"
  | "Cat Tabby"
  | "Cat Calico"
  | "AFK"
  | "Angry"
  | "Clench";

const emotes: Emote[] = [
  "Wave",
  "Laugh",
  "Jazz Hands",
  "Tea Bag",
  "Sweaty",
  "Exclaim",
  "Applaud",
  "Fisting Gentle",
  "UWU",
  "Heart",
  "Bok Bok",
  "Sit Down",
  "Cat Black",
  "Cat Tabby",
  "Cat Calico",
  "AFK",
  "Angry",
  "Clench",
];

interface SelectEmoteProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectEmote: React.FC<SelectEmoteProps> = ({ color, value, setValue, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return emotes
      .filter((emote) => (searchQuery ? emote.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true))
      .sort((a, b) => a.localeCompare(b))
      .map((emote) => [emote, emote]);
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"Emote"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectEmote;
