import NavigateToButton, { NavigateToButtonProps } from "../../ui/base/NavigateToButton";

export interface QuestNavigateToButtonProps extends Omit<NavigateToButtonProps, "resourceName" | "resourcePath"> {
  questId: string;
}

function QuestNavigateToButton({ questId, ...navigateToButtonProps }: QuestNavigateToButtonProps) {
  return <NavigateToButton resourceName={"quest"} resourcePath={`/quests/${questId}`} {...navigateToButtonProps} />;
}

export default QuestNavigateToButton;
