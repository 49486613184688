import {
  ChakraProps,
  Modal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Stack,
  Divider,
  Button,
  HStack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { memo, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { useSelectedQuestProvider } from "../../context/SelectedQuestProvider";
import QuestName from "../../features/quests/QuestName";
import useUpdateQuestWithStartCondition from "../../hooks/quests/useUpdateQuestWithStartCondition";
import { QuestWithId } from "../../models/api/quest";
import { StartCondition, StartConditionType } from "../../store/quests";
import SelectSomething from "../base/SelectSomething";
import ConfirmModal from "../modals/ConfirmModal";

export type StartConditionKind = "all" | "any";

interface UpdateQuestWithStartCondition extends StartCondition<string> {
  startConditionNodeId: string;
  startConditionKind: StartConditionKind;
  startConditionType: StartConditionType;
}

interface QuestUpdateTargetModalProps extends Omit<ChakraProps, "scrollBehavior">, Omit<ModalProps, "children"> {
  questId: string;
  quest: QuestWithId;
}

function QuestUpdateTargetModal({ color, questId, quest, ...modalProps }: QuestUpdateTargetModalProps) {
  const { selectedQuestId, changeSelectedQuest } = useSelectedQuestProvider();

  const startNodes: Record<string, string> = useMemo(
    () =>
      quest.data
        ? Object.fromEntries(
            quest.data.nodes
              .filter(({ data: { nodeClass } }) => nodeClass === "start")
              .map(({ id, data: { label } }) => [id, label ?? id])
          )
        : {},
    [quest]
  );

  const startNodeId = useMemo(() => Object.entries(startNodes).at(0)?.at(0), [startNodes]);

  const startConditionKinds: StartConditionKind[] = ["all", "any"];

  const startConditionTypes: StartConditionType[] = ["quest_completed", "quest_started"];

  const { getValues, setValue, watch, handleSubmit } = useForm<UpdateQuestWithStartCondition>({
    defaultValues: useMemo(
      () => ({
        startConditionNodeId: startNodeId,
        startConditionKind: "all",
        startConditionType: "quest_completed",
      }),
      [startNodes]
    ),
    mode: "onChange",
  });

  const { isUpdating, updateQuestWithStartCondition } = useUpdateQuestWithStartCondition();

  const [startConditionNodeId, setStartConditionNodeId] = useState<string | undefined>(startNodeId);
  const [startConditionKind, setStartConditionKind] = useState<StartConditionKind | undefined>("all");
  const [startConditionType, setStartConditionType] = useState<StartConditionType | undefined>("quest_completed");

  const handleUpdateQuest = useCallback(
    ({ startConditionNodeId, startConditionKind, startConditionType }: UpdateQuestWithStartCondition) => {
      setStartConditionNodeId(startConditionNodeId);
      setStartConditionKind(startConditionKind);
      setStartConditionType(startConditionType);
    },
    []
  );

  const handleUpdateQuestWithStartCondition = useCallback(() => {
    if (selectedQuestId == null) {
      return;
    }

    if (startConditionNodeId == null) {
      return;
    }

    if (startConditionKind == null) {
      return;
    }

    if (startConditionType == null) {
      return;
    }

    updateQuestWithStartCondition(
      {
        questId: selectedQuestId,
        startConditionNodeId,
        startConditionKind,
        startConditionType,
        value: questId,
      },
      {
        onSuccess: () => {
          changeSelectedQuest(selectedQuestId);

          modalProps.onClose();
        },
      }
    );
  }, [selectedQuestId, startConditionNodeId, startConditionKind, startConditionType, questId]);

  if (selectedQuestId == null) {
    return null;
  }

  return (
    <Modal {...modalProps}>
      <ModalOverlay />

      <ModalContent bg={"theme.dark.background"} borderColor={color} borderRadius={0} borderWidth={1}>
        <ModalHeader>
          <Text color={color} casing={"uppercase"}>
            Add Quest Requirement
          </Text>
        </ModalHeader>

        <ModalBody>
          <Stack>
            <Form
              onSubmit={handleSubmit(handleUpdateQuest)}
              onBlur={handleSubmit(handleUpdateQuest)}
              onChange={handleSubmit(handleUpdateQuest)}
            >
              <Stack>
                <SelectSomething
                  color={color}
                  title={"Start Node"}
                  value={getValues("startConditionNodeId")}
                  setValue={(value) => setValue("startConditionNodeId", value)}
                  values={startNodes}
                />

                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Start Condition Kind
                    </Text>
                  </FormLabel>

                  <RadioGroup
                    onChange={(value) => setValue("startConditionKind", value as StartConditionKind)}
                    value={watch("startConditionKind")}
                  >
                    <Stack direction="row">
                      {startConditionKinds.map((value, index) => (
                        <Radio key={index} color={color} value={value}>
                          {value}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <FormLabel>
                    <Text color={color} casing={"uppercase"}>
                      Start Condition Type
                    </Text>
                  </FormLabel>

                  <RadioGroup
                    onChange={(value) => setValue("startConditionType", value as StartConditionType)}
                    value={watch("startConditionType")}
                  >
                    <Stack direction="row">
                      {startConditionTypes.map((value, index) => (
                        <Radio key={index} color={color} value={value}>
                          {value}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Form>

            <Divider />

            <Stack>
              <Text color={color} casing={"uppercase"}>
                adding
              </Text>

              <QuestName questId={questId} />

              <Text color={color} casing={"uppercase"}>
                to
              </Text>

              <QuestName questId={selectedQuestId} />
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button color={color} onClick={modalProps.onClose}>
              <Text color={color} casing={"uppercase"}>
                Cancel
              </Text>
            </Button>

            <ConfirmModal
              color={color}
              onConfirm={() => handleUpdateQuestWithStartCondition()}
              title={"Update"}
              isDisabled={isUpdating}
            >
              <Text color={"white"} fontWeight={500}>
                Reset quest for everyone?
              </Text>
            </ConfirmModal>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default memo(QuestUpdateTargetModal);
