import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useTutorial from "../../hooks/tutorial/useTutorial";

interface SelectQuestProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectTutorial: React.FC<SelectQuestProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, tutorials = [] } = useTutorial();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return tutorials
        .filter(({ tutorialId, title }) => tutorialId.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? "") || title.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ tutorialId, title }) => [tutorialId, title]);
    },
    [tutorials]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Tutorials"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectTutorial;
