import { Icon, IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";

export interface RefreshButtonProps extends Omit<IconButtonProps, "aria-label"> {
  resourceName: string;
}

function RefreshButton({
  resourceName,
  color,
  colorScheme,
  bg = "theme.dark.background",
  borderColor = color,
  borderWidth = 2,
  ...iconButtonProps
}: RefreshButtonProps) {
  return (
    <Tooltip
      label={`refresh ${resourceName}`}
      color={color}
      colorScheme={colorScheme}
      bg={bg}
      borderColor={borderColor}
      borderWidth={borderWidth}
    >
      <IconButton
        aria-label={`refresh ${resourceName}`}
        color={color}
        colorScheme={colorScheme}
        bg={bg}
        borderColor={borderColor}
        borderWidth={borderWidth}
        icon={<Icon as={MdRefresh} position={"absolute"} />}
        variant={"outline"}
        isRound={true}
        {...iconButtonProps}
      />
    </Tooltip>
  );
}

export default RefreshButton;
