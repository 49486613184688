import React, { useCallback, useEffect, useState } from "react";
import { ChakraProps, FormControl, FormLabel, Input, Stack, Text } from "@chakra-ui/react";
import AsyncSelect from "./AsyncSelect";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import { ObservableEvent } from "../../models/api/observableEvent";
import startCase from "lodash.startcase";
import { InputError } from "./InputError";
import useObservableEvents from "../../hooks/events/useObservableEvents";

interface SelectObservableEventProps extends ChakraProps {
  observableEvent: ObservableEvent;
  setObservableEvent: (observableEvent: ObservableEvent) => void;
}

const SelectObservableEvent: React.FC<SelectObservableEventProps> = ({
  color,
  observableEvent,
  setObservableEvent,
  ...chakraProps
}) => {
  const { isLoading, error, observableEvents = [] } = useObservableEvents();
  const [displayName, setDisplayName] = useState<string>(observableEvent.displayName);
  const [eventName, setEventName] = useState<string>(observableEvent.eventName);
  const [observationId, setObservationId] = useState<string>(observableEvent.observationId ?? "");
  const [showObservationIdInput, setShowObservationIdInput] = useState<boolean>(false);
  const [isObservationIdRequired, setIsObservationIdRequired] = useState<boolean>(false);
  const [observationIdLabel, setObservationIdLabel] = useState<string>("observationId");
  const [subjectId, setSubjectId] = useState<string>(observableEvent.subjectId ?? "");
  const [showSubjectIdInput, setShowSubjectIdInput] = useState<boolean>(false);
  const [isSubjectIdRequired, setIsSubjectIdRequired] = useState<boolean>(false);
  const [subjectIdLabel, setSubjectIdLabel] = useState<string>("subjectId");

  useEffect(() => {
    if (!observableEvents.length) {
      return;
    }

    const observableEventDefinition = observableEvents.find(
      (observableEvent) => observableEvent.displayName === displayName
    );

    if (observableEventDefinition == null) {
      return;
    }

    setEventName(observableEventDefinition.eventName);

    if (observableEvent.displayName !== observableEventDefinition.displayName) {
      setObservationId(observableEventDefinition.observationId ?? "");
    }

    if (observableEvent.displayName !== observableEventDefinition.displayName) {
      setSubjectId(observableEventDefinition.subjectId ?? "");
    }

    setShowObservationIdInput(observableEventDefinition.observationIdInput);
    setIsObservationIdRequired(!observableEventDefinition.observationIdAllowNull);
    setObservationIdLabel(startCase(observableEventDefinition.observationIdDisplayName ?? "observationId"));
    setShowSubjectIdInput(observableEventDefinition.subjectIdInput);
    setIsSubjectIdRequired(!observableEventDefinition.subjectIdAllowNull);
    setSubjectIdLabel(startCase(observableEventDefinition.subjectIdDisplayName ?? "subjectId"));
  }, [observableEvents, displayName]);

  useEffect(() => {
    setObservableEvent({ displayName, eventName, observationId, subjectId });
  }, [setObservableEvent, displayName, eventName, observationId, subjectId]);

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return observableEvents
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ displayName }) => [displayName, displayName]);
    },
    [observableEvents]
  );

  return (
    <Stack>
      <AsyncSearchProvider onSearchAsync={handleSearch}>
        <AsyncSelect
          selectedValue={displayName}
          setSelectedValue={setDisplayName}
          color={color}
          title={"Observable Event"}
          isLoading={isLoading}
          error={error}
          {...chakraProps}
        />
      </AsyncSearchProvider>

      {showObservationIdInput && (
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              {observationIdLabel}
            </Text>
          </FormLabel>
          <Input color={color} value={observationId} onChange={({ target: { value } }) => setObservationId(value)} />
        </FormControl>
      )}

      {showObservationIdInput && isObservationIdRequired && !observationId && (
        <InputError errorMessage={"This value is required"} />
      )}

      {showSubjectIdInput && (
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              {subjectIdLabel}
            </Text>
          </FormLabel>
          <Input color={color} value={subjectId} onChange={({ target: { value } }) => setSubjectId(value)} />
        </FormControl>
      )}

      {showSubjectIdInput && isSubjectIdRequired && !subjectId && (
        <InputError errorMessage={"This value is required"} />
      )}
    </Stack>
  );
};

export default SelectObservableEvent;
