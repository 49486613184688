import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resetQuest as resetQuestAsync } from "../../api/quests/quests";

export default function useResetQuest(questId: string, userId: string) {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isResetting, mutate: resetQuest } = useMutation({
    mutationFn: () => resetQuestAsync(questId, userId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["quest", questId, userId],
      });

      toast({ title: "Quest reset successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest reset unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isResetting,
    resetQuest,
  };
}
