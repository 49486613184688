import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";

function getDefaultControlScheme() {
  return (localStorage.getItem("controlScheme") as ControlScheme | null | undefined) ?? "primary";
}

function setDefaultControlScheme(controlScheme: ControlScheme) {
  localStorage.setItem("controlScheme", controlScheme);
}

export type ControlScheme = "primary" | "secondary";

interface UserSettings {
  controlScheme: ControlScheme;
  toggleControlScheme: () => void;
}

const UserSettingsContext = createContext<UserSettings | null>(null);

const UserSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [controlScheme, setControlScheme] = useState<ControlScheme>(getDefaultControlScheme);

  const toggleControlScheme = useCallback(() => {
    setControlScheme((controlScheme) => {
      const updatedControlScheme = controlScheme === "primary" ? "secondary" : "primary";

      setDefaultControlScheme(updatedControlScheme);

      return updatedControlScheme;
    });
  }, []);

  return (
    <UserSettingsContext.Provider value={{ controlScheme, toggleControlScheme }}>
      {children}
    </UserSettingsContext.Provider>
  );
};

const useUserSettingsProvider = () => {
  const context = useContext(UserSettingsContext);

  if (context == null) {
    throw new Error("useUserSettingsProvider used outside of UserSettingsProvider");
  }

  return context;
};

export { UserSettingsProvider, useUserSettingsProvider };
