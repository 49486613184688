import React from "react";
import { onNodeDragStart } from "../utils/dragEvent";
import { BaseNodeHeader } from "./reactFlow/nodeTypes/base/BaseNode";

interface NodeTypeListItemProps {
  label?: string;
  color?: string;
  nodeName: string;
  nodeDescription?: string;
  isReady?: boolean;
}

export const NodeTypeListItem: React.FC<NodeTypeListItemProps> = ({
  label,
  color,
  nodeName,
  nodeDescription,
  isReady,
}) => {
  return (
    <BaseNodeHeader
      label={label}
      color={color}
      draggable
      onDragStart={(event) => onNodeDragStart(event, nodeName)}
      sx={{ cursor: "pointer" }}
      isReady={isReady}
      nodeDescription={nodeDescription}
    />
  );
};
