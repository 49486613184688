import React, { memo, useCallback, useEffect, useState } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import {
  ChakraProps,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import { StartNodeWithChildren } from "./StartNode";
import SelectNpc from "../../base/SelectNpc";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import { Location } from "../../../models/api/location";
import SelectLocation from "../../base/SelectLocation";
import OverheadDialogConfigurationModal, {
  OverheadDialogConfiguration,
} from "../../../ui/dialog/OverheadDialogConfigurationModal";
import { SpeakerPassageWithId } from "@worldwidewebb/quest-shared/dist/dialog";

interface DialogPreviewProps extends ChakraProps {
  speakerPassages: SpeakerPassageWithId[];
}

const DialogPreview: React.FC<DialogPreviewProps> = ({ color, speakerPassages }) => {
  return (
    <Stack pb={4} spacing={4}>
      {speakerPassages.length !== 0 && (
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              NPC Dialog Passages
            </Text>
          </FormLabel>

          <Stack>
            {speakerPassages.map(({ passageId, speakerId, speakerType, passage, passageType }) => (
              <Stack key={passageId}>
                <Text color={"white"} textAlign={"justify"} whiteSpace={"pre-wrap"} maxW={"md"}>
                  {passage}
                </Text>
              </Stack>
            ))}
          </Stack>
        </FormControl>
      )}
    </Stack>
  );
};

interface NodeData extends Location {
  priority: number;
  npcId: string;
  npcIndicator: string;
  speakerPassages: SpeakerPassageWithId[];
}

const StartWithNpcAtLocationNode: React.FC<NodeProps<NodeType<NodeData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const [priority, setPriority] = useState<number>(nodeData?.priority ?? 0);
  const [npcId, setNpcId] = useState(nodeData?.npcId ?? "");
  const [npcIndicator, setNpcIndicator] = useState(nodeData?.npcIndicator ?? "");

  const roomName = nodeData?.roomName ?? "";
  const x = nodeData?.x ?? 0;
  const y = nodeData?.y ?? 0;
  const radius = nodeData?.radius ?? 10;
  const speakerPassages = nodeData?.speakerPassages ?? [];

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ npcId, npcIndicator, priority }: Partial<NodeData>) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.priority = priority ?? 0;
        nodeData.npcId = npcId ?? "";
        nodeData.npcIndicator = npcIndicator ?? "";
        nodeData.speakerPassages =
          nodeData.speakerPassages?.map((speakerPassage) => ({
            ...speakerPassage,
            speakerId: npcId ?? "",
          })) ?? [];

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  const handleUpdateLocation = useCallback(
    ({ roomName, x, y, radius }: Location) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as Location) ?? {};
        nodeData.roomName = roomName;
        nodeData.x = Number(x) || 0;
        nodeData.y = Number(y) || 0;
        nodeData.radius = Number(radius) || 0;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  const handleUpdateSpeakerPassages = useCallback(
    ({ speakerPassages }: OverheadDialogConfiguration) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as NodeData) ?? {};
        nodeData.speakerPassages = speakerPassages;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  useEffect(() => {
    handleUpdate({ npcId, npcIndicator, priority });
  }, [handleUpdate, npcId, npcIndicator, priority]);

  return (
    <StartNodeWithChildren {...props}>
      <Stack>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Priority
            </Text>
          </FormLabel>
          <NumberInput
            defaultValue={0}
            step={1}
            min={0}
            value={priority}
            onChange={(value) => setPriority(Number(value))}
          >
            <NumberInputField color={color} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <SelectNpc value={npcId} setValue={(value) => setNpcId(value)} color={color} />
        <SelectNpcIndicator value={npcIndicator} setValue={(value) => setNpcIndicator(value)} color={color} />

        <FormControl w={"full"}>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              NPC Dialog
            </Text>
          </FormLabel>
          <Stack>
            <OverheadDialogConfigurationModal
              color={color}
              speakerId={npcId}
              speakerPassages={speakerPassages}
              onSubmit={handleUpdateSpeakerPassages}
            />
          </Stack>
        </FormControl>

        <FormControl w={"full"}>
          <Stack>
            <DialogPreview color={color} speakerPassages={speakerPassages} />
          </Stack>
        </FormControl>

        <SelectLocation value={{ roomName, x, y, radius }} setValue={handleUpdateLocation} color={color} />
      </Stack>
    </StartNodeWithChildren>
  );
};

export default memo(StartWithNpcAtLocationNode);
