import { QuestEditorApi, QuestSubgraph } from "@worldwidewebb/client-quests";
import { AxiosError } from "axios";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuestSubgraphs() {
  try {
    const { data: questSubgraphs } = await questEditorApi.getQuestEditorQuestSubgraphs();

    return questSubgraphs;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getQuestSubgraph(questSubgraphId: string) {
  try {
    const { data: questSubgraph } = await questEditorApi.getQuestEditorQuestSubgraph(questSubgraphId);

    return questSubgraph;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 404) {
        return undefined;
      }
    }

    console.error(error);
    throw error;
  }
}

export async function upsertQuestSubgraph(questSubgraphId: string, questSubgraph: QuestSubgraph) {
  try {
    await questEditorApi.setQuestEditorQuestSubgraph(questSubgraphId, questSubgraph);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteQuestSubgraph(questSubgraphId: string) {
  try {
    await questEditorApi.deleteQuestEditorQuestSubgraph(questSubgraphId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
