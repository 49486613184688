import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Center,
  ChakraProps,
  Heading,
  HStack,
  Icon,
  Input,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Panel, useKeyPress } from "reactflow";
import { MdKeyboardCommandKey, MdSearch } from "react-icons/md";

interface SearchResultsProps<T> extends ChakraProps {
  items: T[];
  onClose: () => void;
  onSelect: (item: T) => void;
  render: (item: T) => ReactElement;
  isLoading: boolean;
  hasError: boolean;
  hasItems: boolean;
}

function SearchResults<T>({
  color,
  items,
  onClose,
  onSelect,
  render,
  isLoading,
  hasError,
  hasItems,
}: SearchResultsProps<T>) {
  if (isLoading) {
    return (
      <Center p={4}>
        <Spinner color={color?.toString() ?? ""} />
      </Center>
    );
  }

  if (hasError) {
    return (
      <Center p={4}>
        <Text color={color} casing={"uppercase"}>
          Try again later
        </Text>
      </Center>
    );
  }

  if (!hasItems) {
    return (
      <Center p={4}>
        <Text color={color} casing={"uppercase"}>
          No results found
        </Text>
      </Center>
    );
  }

  const handleSelect = useCallback(
    (item: T) => {
      onClose();

      onSelect(item);
    },
    [onClose, onSelect]
  );

  return (
    <Stack
      spacing={0}
      maxH={"xl"}
      sx={{
        "::-webkit-scrollbar": {
          width: 2,
        },
        "::-webkit-scrollbar-track": {
          bg: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          bg: color,
        },
      }}
      overflowY={"auto"}
      onWheel={(event) => event.stopPropagation()}
    >
      {items.map((item) => (
        <Box onClick={() => handleSelect(item)}>{render(item)}</Box>
      ))}
    </Stack>
  );
}

interface SearchModalProps<T> extends ChakraProps, PropsWithChildren {
  onSearchAsync: (query: string) => Promise<T[]>;
  onSelect: (item: T) => void;
  render: (item: T) => ReactElement;
  isOpen: boolean;
  onClose: () => void;
}

function SearchModal<T>({ color, children, onSearchAsync, onSelect, render, isOpen, onClose }: SearchModalProps<T>) {
  const initialFocusRef = useRef<HTMLInputElement | null>(null);

  const [query, setQuery] = useState<string>("");
  const [items, setItems] = useState<T[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const hasItems = items.length !== 0;

  const refreshItems = useCallback(() => {
    setIsLoading(true);
    setHasError(false);

    onSearchAsync(query)
      .then(setItems)
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [onSearchAsync, query]);

  const handleRefreshItems = useCallback(
    ({ key }: React.KeyboardEvent) => {
      if (key !== "Enter" && key !== "Return") {
        return;
      }

      refreshItems();
    },
    [refreshItems]
  );

  useEffect(() => {
    const timeout = setTimeout(refreshItems, 1000);

    return () => clearTimeout(timeout);
  }, [refreshItems]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <ModalOverlay />

      <ModalContent bg={"theme.dark.background"} p={1}>
        <ModalBody bg={"whiteAlpha.200"} borderRadius={5}>
          <Heading size={"md"}>
            <HStack alignItems={"center"}>
              <Icon color={color} as={MdSearch} />
              <Input
                ref={initialFocusRef}
                value={query}
                onChange={({ target: { value } }) => setQuery(value)}
                onKeyDown={handleRefreshItems}
                placeholder={`Search ${children}`}
                textTransform={"uppercase"}
                color={color}
                borderColor={"transparent"}
                borderWidth={0}
                focusBorderColor={"transparent"}
              />
            </HStack>
          </Heading>

          <SearchResults
            color={color}
            items={items}
            onClose={onClose}
            onSelect={onSelect}
            render={render}
            isLoading={isLoading}
            hasError={hasError}
            hasItems={hasItems}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

interface SearchPanelProps<T> extends ChakraProps, PropsWithChildren {
  onSearchAsync: (query: string) => Promise<T[]>;
  onSelect: (item: T) => void;
  render: (item: T) => ReactElement;
}

function SearchPanel<T>({ color, children, onSearchAsync, onSelect, render }: SearchPanelProps<T>) {
  const isSearchInitiated = useKeyPress(["Control+k", "Meta+k"]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isSearchInitiated) {
      return;
    }

    onOpen();

    containerRef.current?.focus();
  }, [containerRef, onOpen, isSearchInitiated]);

  useEffect(() => {
    function handleAbortSearch(event: KeyboardEvent) {
      const { key } = event;

      if (key !== "Escape") {
        return;
      }

      onClose();

      containerRef.current?.blur();

      event.stopPropagation();
    }

    containerRef.current?.addEventListener("keydown", handleAbortSearch);

    return () => containerRef.current?.removeEventListener("keydown", handleAbortSearch);
  }, [containerRef, onClose]);

  return (
    <Panel position={"top-center"}>
      <Card
        ref={containerRef}
        tabIndex={0}
        outline={"none"}
        bg={"theme.dark.background"}
        p={1}
        cursor={"pointer"}
        onClick={onOpen}
      >
        <CardBody bg={"whiteAlpha.200"} borderRadius={5}>
          <Heading size={"md"}>
            <HStack alignItems={"center"}>
              <HStack alignItems={"center"}>
                <Icon color={color} as={MdSearch} />
                <Text color={color} casing={"uppercase"} fontSize={"sm"}>
                  Search {children}
                </Text>
              </HStack>

              <HStack alignItems={"center"} pl={8}>
                <Kbd color={color}>
                  <Icon boxSize={3} as={MdKeyboardCommandKey} />
                </Kbd>
                <Text color={color} casing={"uppercase"} fontSize={"sm"}>
                  +
                </Text>
                <Kbd color={color}>K</Kbd>
              </HStack>
            </HStack>
          </Heading>
        </CardBody>
      </Card>

      <SearchModal
        color={color}
        onSearchAsync={onSearchAsync}
        onSelect={onSelect}
        render={render}
        isOpen={isOpen}
        onClose={onClose}
      >
        {children}
      </SearchModal>
    </Panel>
  );
}

export default SearchPanel;
