import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import SelectNpc from "../../base/SelectNpc";

interface SetNpcContext extends NodeData {
  npcId: string;
  npcIdSelectionEnabled: boolean;
}

const SetNpcContextNode: React.FC<NodeProps<NodeType<SetNpcContext>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const npcId = nodeData?.npcId ?? "last_used";
  const npcIdSelectionEnabled = nodeData?.npcIdSelectionEnabled ?? false;

  const { register, getValues, setValue, handleSubmit, watch } = useForm<SetNpcContext>({
    defaultValues: useMemo(
      () => ({
        npcId,
        npcIdSelectionEnabled,
      }),
      [npcId, npcIdSelectionEnabled]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<SetNpcContext>(nodeId);

  const handleUpdate = useCallback(
    (nodeData: SetNpcContext) => {
      updateNodeData(nodeData);
    },
    [updateNodeData]
  );

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const npcIdSelectionEnabledWatched = watch("npcIdSelectionEnabled");

  useEffect(() => {
    let updatedTargetHandles = targetHandles.slice();

    if (npcIdSelectionEnabledWatched) {
      updatedTargetHandles = updatedTargetHandles.filter(({ handleName }) => handleName !== "npc");
    } else if (!updatedTargetHandles.some(({ handleName }) => handleName === "npc")) {
      updatedTargetHandles.push({
        label: "NPC",
        handleName: "npc",
        handleType: "target",
        handleCategory: "data",
      });
    }

    updateNodeTargetHandles(updatedTargetHandles);
  }, [npcIdSelectionEnabledWatched]);

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                NPC selection enabled
              </Text>
            </FormLabel>

            <Checkbox color={color} {...register("npcIdSelectionEnabled")} />
          </FormControl>

          {npcIdSelectionEnabledWatched && (
            <SelectNpc value={getValues("npcId")} setValue={(value) => setValue("npcId", value)} color={color} />
          )}
        </Stack>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(SetNpcContextNode);
