import {
  Box,
  Card,
  Center,
  ChakraProps,
  HStack,
  IconButton,
  Icon,
  Text,
  Tooltip,
  Stack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { memo, MouseEvent, useCallback, useMemo } from "react";
import { MdClose } from "react-icons/md";
import { FaMousePointer } from "react-icons/fa";
import { Node } from "reactflow";
import { UserQuestPointerStatus, useUserQuestPointerProvider } from "../../context/UserQuestPointerContext";
import { NodeType } from "../../models/nodeType";
import { AiOutlineCopy } from "react-icons/ai";

interface QuestPointerProps extends ChakraProps {
  isLoading: boolean;
  isSelected: boolean;
  questPointerId?: string;
}

const QuestPointer = memo(({ color, isLoading, isSelected, questPointerId }: QuestPointerProps) => {
  const { selectQuestPointer, deleteQuestPointer } = useUserQuestPointerProvider();

  const handleSelectQuestPointer = useCallback(() => {
    if (!questPointerId) {
      return;
    }

    selectQuestPointer(questPointerId);
  }, [selectQuestPointer, questPointerId]);

  const handleDeleteQuestPointer = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      if (!questPointerId) {
        return;
      }

      deleteQuestPointer(questPointerId);
    },
    [deleteQuestPointer, questPointerId]
  );

  const toast = useToast();

  const handleCopyQuestPointerId = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();

      if (questPointerId == null) {
        return;
      }

      await navigator.clipboard.writeText(questPointerId);

      toast({
        title: `Copied ID ${questPointerId} to clipboard`,
        status: "info",
      });
    },
    [toast, questPointerId]
  );

  if (!questPointerId) {
    return null;
  }

  return (
    <Center
      bg={isSelected ? color : "theme.dark.background"}
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      onClick={handleSelectQuestPointer}
    >
      <HStack flexGrow={1} p={2} justifyContent={"space-between"}>
        <Stack spacing={0}>
          <Text color={isSelected ? "theme.dark.background" : color} casing={"uppercase"} fontWeight={500} size={"sm"}>
            Quest Pointer ID
          </Text>

          <HStack>
            <Text
              color={isSelected ? "theme.dark.background" : color}
              casing={"uppercase"}
              fontWeight={500}
              size={"sm"}
            >
              {questPointerId}
            </Text>

            <Tooltip
              label={"copy quest poiner id"}
              bg={"theme.dark.background"}
              borderColor={color}
              borderWidth={1}
              color={color}
            >
              <IconButton
                aria-label={"copy quest id"}
                icon={<Icon position={"absolute"} as={AiOutlineCopy} />}
                size={"xs"}
                variant={"ghost"}
                color={isSelected ? "theme.dark.background" : color}
                onClick={handleCopyQuestPointerId}
              />
            </Tooltip>
          </HStack>
        </Stack>

        <Tooltip
          label={"delete pointer"}
          bg={"theme.dark.background"}
          borderColor={color}
          borderWidth={1}
          color={color}
        >
          <IconButton
            aria-label={"delete pointer"}
            size={"sm"}
            icon={<Icon position={"absolute"} as={MdClose} />}
            variant={"outline"}
            isRound={true}
            onClick={handleDeleteQuestPointer}
            isLoading={isLoading}
            borderColor={isSelected ? "theme.dark.background" : color}
            borderWidth={2}
            color={isSelected ? "theme.dark.background" : color}
          />
        </Tooltip>
      </HStack>
    </Center>
  );
});

interface IsLoadingOverlayProps extends ChakraProps {
  isLoading: boolean;
}

function IsLoadingOverlay({ color, isLoading }: IsLoadingOverlayProps) {
  if (!isLoading) {
    return null;
  }

  return (
    <IconButton
      h={"full"}
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      aria-label={"loading"}
      variant={"ghost"}
      color={color}
      isLoading={isLoading}
    />
  );
}

interface IsSelectableOverlayProps {
  isLoading: boolean;
  isSelectable: boolean;
}

function IsSelectableOverlay({ isLoading, isSelectable }: IsSelectableOverlayProps) {
  if (isLoading) {
    return null;
  }

  if (!isSelectable) {
    return null;
  }

  return (
    <Center position={"absolute"} top={0} left={0} right={0} bottom={0}>
      <Text color={"white"} casing={"uppercase"} fontWeight={500}>
        click to move pointer here
      </Text>
    </Center>
  );
}

interface IsSelectedOverlayProps extends ChakraProps {
  isSelected: boolean;
}

function IsSelectedOverlay({ color, isSelected }: IsSelectedOverlayProps) {
  if (!isSelected) {
    return null;
  }

  return <Box bg={color} position={"absolute"} top={0} left={0} right={0} bottom={0} />;
}

interface QuestPointerContainerProps extends ChakraProps {
  nodeId: string;
}

export const QuestPointerContainer = memo(({ color, nodeId, ...chakraProps }: QuestPointerContainerProps) => {
  const { showQuestPointers, selectedQuestPointerId, updateQuestPointer, getQuestPointerStatus, isLoading } =
    useUserQuestPointerProvider();

  const questPointerStatus = useMemo(() => getQuestPointerStatus(nodeId), [getQuestPointerStatus, nodeId]);
  const { questPointerId, questPointerColor } = questPointerStatus;

  const hasSelectedQuestPointerId = selectedQuestPointerId != null;
  const isSelected = hasSelectedQuestPointerId && selectedQuestPointerId === questPointerId;
  const isSelectable = hasSelectedQuestPointerId && !isSelected;

  const currentColor = questPointerColor ?? color;

  const handleUpdateQuestPointer = useCallback(() => {
    if (!hasSelectedQuestPointerId) {
      return;
    }

    updateQuestPointer(nodeId);
  }, [hasSelectedQuestPointerId, updateQuestPointer, nodeId]);

  if (!showQuestPointers) {
    return null;
  }

  return (
    <Card
      className={"nodrag"}
      w={"xs"}
      h={16}
      position={"relative"}
      bg={"theme.dark.background"}
      borderColor={currentColor}
      borderRadius={0}
      borderWidth={2}
      {...chakraProps}
    >
      <IsLoadingOverlay color={color} isLoading={isLoading} />
      <IsSelectableOverlay isLoading={isLoading} isSelectable={isSelectable} />
      <IsSelectedOverlay isSelected={isSelected} />

      <Box position={"absolute"} top={0} left={0} right={0} bottom={0} onClick={handleUpdateQuestPointer}>
        <QuestPointer
          color={currentColor}
          isLoading={isLoading}
          isSelected={isSelected}
          questPointerId={questPointerId}
        />
      </Box>
    </Card>
  );
});

interface QuestPointerContainerListProps extends ChakraProps {
  nodes: Node<NodeType>[];
}

export const QuestPointerContainerList = memo(({ color, nodes, ...chakraProps }: QuestPointerContainerListProps) => {
  const { showQuestPointers, questPointers } = useUserQuestPointerProvider();

  const filteredNodes = useMemo(
    () => nodes.filter(({ id }) => questPointers.map(({ nodeId }) => nodeId).includes(id)),
    [nodes, questPointers]
  );

  if (!showQuestPointers) {
    return null;
  }

  return (
    <Stack {...chakraProps}>
      {filteredNodes.map(({ id: nodeId, data: { label } }) => (
        <Tooltip
          key={nodeId}
          shouldWrapChildren={true}
          label={label}
          bg={"theme.dark.background"}
          borderColor={color}
          borderWidth={1}
          color={color}
        >
          <QuestPointerContainer color={color} nodeId={nodeId} />
        </Tooltip>
      ))}
    </Stack>
  );
});

export function ToggleQuestPointersButton() {
  const { showQuestPointers, openQuestPointers, hideQuestPointers } = useUserQuestPointerProvider();

  const handleToggleShowQuestPointers = useCallback(() => {
    if (showQuestPointers) {
      hideQuestPointers();
    } else {
      openQuestPointers();
    }
  }, [showQuestPointers, openQuestPointers, hideQuestPointers]);

  return (
    <Box>
      <Button onClick={handleToggleShowQuestPointers} color={showQuestPointers ? "green.600" : "white"}>
        <HStack>
          <Icon as={FaMousePointer} />
          <Text color={showQuestPointers ? "green.600" : "white"} casing={"uppercase"}>
            {showQuestPointers ? "currently showing quest pointers" : "currently hiding quest pointers"}
          </Text>
        </HStack>
      </Button>
    </Box>
  );
}
