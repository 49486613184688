import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useQuestFolders from "../../hooks/quests/useQuestFolders";

interface SelectQuestProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectQuestFolder: React.FC<SelectQuestProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, questFolders = [] } = useQuestFolders();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return questFolders
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ questEditorFolderId, displayName }) => [questEditorFolderId, displayName]);
    },
    [questFolders]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Quest Folder"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectQuestFolder;
