import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { Precedence } from "@worldwidewebb/quest-shared/dist/dynamic";

const precedences: Record<Precedence, string> = {
  low: "LOW",
  medium: "MEDIUM",
  high: "HIGH",
};

interface SelectPrecedenceProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectPrecedence: React.FC<SelectPrecedenceProps> = ({ color, value, setValue, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return Object.entries(precedences).filter(([_, displayName]) =>
      searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
    );
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Precedence"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectPrecedence;
