import { memo, useCallback } from "react";
import { Button, ButtonProps, Flex, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { Status } from "@worldwidewebb/client-quests";
import useQuest from "../../hooks/quests/useQuest";
import { Edge, Node } from "reactflow";
import { NodeType } from "../../models/nodeType";
import { EdgeType } from "../../models/edgeType";
import useUpdateQuestMetaDataAndNodeData from "../../hooks/quests/useUpdateQuestMetaDataAndNodeData";
import ConfirmModal from "../../components/modals/ConfirmModal";

interface QuestSubStatusButtonProps extends ButtonProps {
  questId: string;
  status: Status;
  nodes: Node<NodeType>[];
  setNodes?: (nodes: Node<NodeType>[]) => void;
  edges: Edge<EdgeType>[];
  setEdges?: (edges: Edge<EdgeType>[]) => void;
}

function QuestStatusButtonDraft({
  questId,
  status,
  nodes,
  setNodes,
  edges,
  setEdges,
  ...buttonProps
}: QuestSubStatusButtonProps) {
  const color = status === "draft" ? "green.600" : "white";
  const isDisabled = status === "draft";

  const { isUpdating, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const handleUpdateQuestStatus = useCallback(() => {
    updateQuestMetaDataAndNodeData({
      questId,
      partialQuest: { isReady: false, status: "draft" },
      questData: { nodes, edges },
    });
  }, [updateQuestMetaDataAndNodeData, questId, nodes, edges]);

  return (
    <ConfirmModal
      title={"DRAFT"}
      tooltip={"disables the quest completely"}
      color={color}
      flexGrow={1}
      variant={"solid"}
      onConfirm={handleUpdateQuestStatus}
      isDisabled={isDisabled}
      isLoading={isUpdating}
      borderLeftRadius={undefined}
      borderRightRadius={0}
      {...buttonProps}
    >
      <Text color={color} fontWeight={500}>
        Are you sure you want to change this to draft?
      </Text>
    </ConfirmModal>
  );
}

function QuestStatusButtonDev({
  questId,
  status,
  nodes,
  setNodes,
  edges,
  setEdges,
  ...buttonProps
}: QuestSubStatusButtonProps) {
  const color = status === "dev" ? "green.600" : "white";
  const isDisabled = status === "dev";

  const { isUpdating, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const handleUpdateQuestStatus = useCallback(() => {
    updateQuestMetaDataAndNodeData({
      questId,
      partialQuest: { isReady: true, status: "dev" },
      questData: { nodes, edges },
    });
  }, [updateQuestMetaDataAndNodeData, questId, nodes, edges]);

  return (
    <ConfirmModal
      title={"DEV ONLY"}
      tooltip={"enables the quest for devs only"}
      color={color}
      flexGrow={1}
      variant={"solid"}
      onConfirm={handleUpdateQuestStatus}
      isDisabled={isDisabled}
      isLoading={isUpdating}
      borderLeftRadius={0}
      borderRightRadius={0}
      {...buttonProps}
    >
      <Text color={color} fontWeight={500}>
        Are you sure you want to change this to devs only?
      </Text>
    </ConfirmModal>
  );
}

function QuestStatusButtonLive({
  questId,
  status,
  nodes,
  setNodes,
  edges,
  setEdges,
  ...buttonProps
}: QuestSubStatusButtonProps) {
  const color = status === "live" ? "green.600" : "white";
  const isDisabled = status === "live";

  const { isUpdating, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const handleUpdateQuestStatus = useCallback(() => {
    updateQuestMetaDataAndNodeData({
      questId,
      partialQuest: { isReady: true, status: "live" },
      questData: { nodes, edges },
    });
  }, [updateQuestMetaDataAndNodeData, questId, nodes, edges]);

  return (
    <ConfirmModal
      title={"LIVE"}
      tooltip={"enables the quest for everyone"}
      color={color}
      flexGrow={1}
      variant={"solid"}
      onConfirm={handleUpdateQuestStatus}
      isDisabled={isDisabled}
      isLoading={isUpdating}
      borderLeftRadius={0}
      borderRightRadius={undefined}
      {...buttonProps}
    >
      <Text color={color} fontWeight={500}>
        Are you sure you want to go live?
      </Text>
    </ConfirmModal>
  );
}

interface QuestStatusButtonProps extends ButtonProps {
  questId: string;
  nodes?: Node<NodeType>[];
  setNodes?: (nodes: Node<NodeType>[]) => void;
  edges?: Edge<EdgeType>[];
  setEdges?: (edges: Edge<EdgeType>[]) => void;
}

function QuestStatusButton({ questId, nodes, setNodes, edges, setEdges, ...buttonProps }: QuestStatusButtonProps) {
  const { isLoading, error, quest } = useQuest(questId);

  if (isLoading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (quest == null) {
    return null;
  }

  const questStatus: Status = quest.status ?? (quest.isReady ? "live" : "draft");
  const questNodes = nodes ?? quest.data?.nodes ?? [];
  const questEdges = edges ?? quest.data?.edges ?? [];

  return (
    <HStack gap={2}>
      <Text color={"white"} casing={"uppercase"}>
        Visibility Status
      </Text>

      <Flex>
        <QuestStatusButtonDraft
          questId={questId}
          status={questStatus}
          nodes={questNodes}
          setNodes={setNodes}
          edges={questEdges}
          setEdges={setEdges}
          {...buttonProps}
        />

        <QuestStatusButtonDev
          questId={questId}
          status={questStatus}
          nodes={questNodes}
          setNodes={setNodes}
          edges={questEdges}
          setEdges={setEdges}
          {...buttonProps}
        />

        <QuestStatusButtonLive
          questId={questId}
          status={questStatus}
          nodes={questNodes}
          setNodes={setNodes}
          edges={questEdges}
          setEdges={setEdges}
          {...buttonProps}
        />
      </Flex>
    </HStack>
  );
}

export default memo(QuestStatusButton);
