import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteQuestFolder as deleteQuestFolderAsync } from "../../api/quests/questFolders";

export default function useDeleteQuestFolder() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: deleteQuestFolder } = useMutation({
    mutationFn: (questEditorFolderId: string) => deleteQuestFolderAsync(questEditorFolderId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questFolder"],
      });

      toast({ title: "Quest folder delete successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest folder delete unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    deleteQuestFolder,
  };
}
