import React, { BaseSyntheticEvent, useCallback, useEffect, useRef } from "react";
import { Input, InputGroup, InputGroupProps, InputLeftAddon, InputRightAddon, Kbd } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

interface SearchBarProps extends InputGroupProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  inputFocusKey?: string;
  placeholder?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchValue,
  setSearchValue,
  containerRef,
  inputFocusKey,
  placeholder,
  ...inputGroupProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocus = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== inputFocusKey) {
        return;
      }

      event.preventDefault();

      inputRef.current?.focus();
    },
    [inputRef, inputFocusKey]
  );

  const insertKeyListener = useCallback(() => {
    containerRef?.current?.addEventListener("keydown", handleInputFocus);
  }, [containerRef, handleInputFocus]);

  const removeKeyListener = useCallback(() => {
    containerRef?.current?.removeEventListener("keydown", handleInputFocus);
  }, [containerRef, handleInputFocus]);

  useEffect(() => {
    if (inputFocusKey == null) {
      return;
    }

    insertKeyListener();

    return () => removeKeyListener();
  }, [insertKeyListener, removeKeyListener]);

  const handleInput = useCallback(({ target: { value } }: BaseSyntheticEvent) => {
    setSearchValue(value);
  }, []);

  return (
    <InputGroup {...inputGroupProps}>
      <InputLeftAddon>
        <SearchIcon />
      </InputLeftAddon>
      <Input ref={inputRef} value={searchValue} onInput={handleInput} placeholder={placeholder} bg={"neutral.900"} />
      {inputFocusKey && (
        <InputRightAddon>
          <Kbd bg={"white"} color={"gray.800"}>
            {inputFocusKey}
          </Kbd>
        </InputRightAddon>
      )}
    </InputGroup>
  );
};

export default SearchBar;
