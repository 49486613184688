import styled from "@emotion/styled";
import { MiniMap } from "reactflow";
import { useToken } from "@chakra-ui/system";

const StyledMiniMap = styled(MiniMap)(() => {
  const [backgroundColor, maskFill] = useToken("colors", ["theme.dark.background", "whiteAlpha.200"]);

  return `
    padding: 4px;
    background-color: ${backgroundColor};
    border-radius: 6px;
    overflow: hidden;

    .react-flow__minimap-mask {
      fill: ${maskFill};
    }

    .react-flow__minimap-node {
      stroke: none;
    }
  `;
});

export default StyledMiniMap;
