import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectEquipmentSlot from "../../base/SelectEquipmentSlot";

interface NpcUnequipEquipmentNodeData extends NodeData {
  slotName: string;
}

const NpcUnequipEquipmentNode: React.FC<NodeProps<NodeType<NpcUnequipEquipmentNodeData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const slotName = nodeData?.slotName ?? "";

  const { getValues, setValue, handleSubmit } = useForm<NpcUnequipEquipmentNodeData>({
    defaultValues: useMemo(
      () => ({
        slotName,
      }),
      [slotName]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    ({ slotName }: NpcUnequipEquipmentNodeData) => {
      updateNodeData({
        slotName,
      });
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <SelectEquipmentSlot
          color={color}
          value={getValues("slotName")}
          setValue={(value) => setValue("slotName", value)}
        />
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(NpcUnequipEquipmentNode);
