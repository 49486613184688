import { FormControl, FormLabel, Textarea, TextareaProps, SystemStyleObject } from "@chakra-ui/react";
import React, { ForwardedRef, PropsWithChildren } from "react";

interface FormTextAreaProps extends PropsWithChildren, TextareaProps {
  disabled?: boolean;
}

function FormTextArea(props: FormTextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) {
  const {
    disabled,
    children,
    color,
    colorScheme,
    borderColor = color,
    borderRadius = 0,
    borderWidth = 2,
    minH = 32,
    ...textAreaProps
  } = props;

  const sx: SystemStyleObject = {
    "::-webkit-scrollbar": {
      width: 3,
    },
    "::-webkit-scrollbar-track": {
      bg: "mirage.900",
    },
    "::-webkit-scrollbar-thumb": {
      bg: color,
    },
    "::-webkit-scrollbar-corner": {
      bg: color,
    },
  };

  return (
    <FormControl>
      <FormLabel>{children}</FormLabel>

      <Textarea
        ref={ref}
        sx={sx}
        cursor={"auto"}
        overflowY={"auto"}
        disabled={disabled}
        color={color}
        colorScheme={colorScheme}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        minH={minH}
        {...textAreaProps}
      />
    </FormControl>
  );
}

export default React.forwardRef(FormTextArea);
