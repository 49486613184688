import { AlertStatus } from "@chakra-ui/react";
import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react";
import { ulid } from "ulid";

export interface NodeNotification {
  notificationId: string;
  status: AlertStatus;
  nodeId: string;
  nodeType: string;
  message?: string;
}

interface ContextType {
  notifications: NodeNotification[];
  insertNotification: (status: AlertStatus, nodeId: string, nodeType: string, message?: string) => void;
  removeNotification: (notificationId: string) => void;
  removeNotifications: () => void;
}

const Context = createContext<ContextType | null>(null);

export function NodeNotificationsProvider({ children }: PropsWithChildren) {
  const [notifications, setNotifications] = useState<NodeNotification[]>([]);

  const insertNotification = useCallback((status: AlertStatus, nodeId: string, nodeType: string, message?: string) => {
    setNotifications((notifications) => [
      ...notifications,
      { notificationId: ulid(), status, nodeId, nodeType, message },
    ]);
  }, []);

  const removeNotification = useCallback((notificationId: string) => {
    setNotifications((notifications) =>
      notifications.filter((notification) => notification.notificationId !== notificationId)
    );
  }, []);

  const removeNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  return (
    <Context.Provider value={{ notifications, insertNotification, removeNotification, removeNotifications }}>
      {children}
    </Context.Provider>
  );
}

export function useNodeNotificationsProvider() {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("useNodeNotificationsProvider used outside of NodeNotificationsProvider");
  }

  return context;
}
