import React, { memo, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import SelectQuest from "../../base/SelectQuest";
import { FlowNodeWithChildren } from "./FlowNode";

interface CompleteQuest extends NodeData {
  questId: string;
}

const CompleteQuestNode: React.FC<NodeProps<NodeType<CompleteQuest>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const questId = nodeData?.questId ?? "";

  const { register, getValues, setValue, handleSubmit } = useForm<CompleteQuest>({
    defaultValues: useMemo(
      () => ({
        questId,
      }),
      [questId]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<CompleteQuest>(nodeId);

  const handleUpdate = useCallback(
    (nodeData: CompleteQuest) => {
      updateNodeData(nodeData);
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <SelectQuest value={getValues("questId")} setValue={(value) => setValue("questId", value)} color={color} />
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(CompleteQuestNode);
