import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useForm } from "react-hook-form";
import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";

interface FormData {
  flagName: string;
}

const FlagNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const flagName = formData?.flagName;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      flagName,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ flagName }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.flagName = flagName;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <BaseNodeWithChildren {...props}>
      <Box p={2}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Flag Name
              </Text>
            </FormLabel>
            <Input id={"flagName"} {...register("flagName")} color={color} />
          </FormControl>
        </form>
      </Box>
    </BaseNodeWithChildren>
  );
};

export default memo(FlagNode);
