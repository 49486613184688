import { AssetsApi, Tutorial } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getTutorials(): Promise<Tutorial[]> {
  try {
    const { data: tutorials } = await assetsApi.listTutorials();

    if (!tutorials) {
      return [];
    }

    return tutorials.sort(({ title: a }, { title: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
