import { Text, TextProps } from "@chakra-ui/react";
import useProfile from "../hooks/users/useProfile";

interface UserProps extends TextProps {
  userId?: string;
}

export function User({ userId, ...textProps }: UserProps) {
  const { profile } = useProfile(userId);
  const displayName = profile?.displayName ?? "<unknown user>";

  return (
    <Text casing={"uppercase"} {...textProps}>
      {displayName}
    </Text>
  );
}
