import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { MdCircle } from "react-icons/md";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { convertGameMakerColourToHex } from "../../../utils/convertGameMakerColourToHex";

export const colourRed: Command = {
  category: "colour",
  icon: <Icon as={MdCircle} />,
  name: `#${convertGameMakerColourToHex(255)}`,
  tooltip: "colour as red",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#colourRed}}", "{{/colourRed}}"),
};
