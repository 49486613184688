import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectSfx from "../../base/SelectSfx";
import { Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";

interface PlaySfx extends NodeData {
  fileName: string;
  enableLoop: boolean;
}

const PlaySfxPositionalNode: React.FC<NodeProps<NodeType<PlaySfx>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const fileName = nodeData?.fileName ?? "";
  const enableLoop = nodeData?.enableLoop ?? false;

  const { register, getValues, setValue, handleSubmit } = useForm<PlaySfx>({
    defaultValues: useMemo(
      () => ({
        fileName,
        enableLoop,
      }),
      [fileName, enableLoop]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<PlaySfx>(nodeId);

  const handleUpdate = useCallback(
    (nodeData: PlaySfx) => {
      updateNodeData(nodeData);
    },
    [updateNodeData]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <Stack>
            <SelectSfx value={getValues("fileName")} setValue={(value) => setValue("fileName", value)} color={color} />

            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Enable Loop
                </Text>
              </FormLabel>
              <Checkbox color={color} {...register("enableLoop")} />
            </FormControl>
          </Stack>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(PlaySfxPositionalNode);
