import { SkillApi } from "@worldwidewebb/client-skills";
import { axiosInstance, buildApiClientBasePath } from "..";

const skillApi = new SkillApi(undefined, buildApiClientBasePath("skills"), axiosInstance);

export async function getAllegiances() {
  try {
    const { data: allegiances } = await skillApi.listAllegiances();

    return allegiances.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAllegiance(allegianceId: string) {
  try {
    const { data: allegiance } = await skillApi.getAllegiance(allegianceId);

    return allegiance;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
