import React, { useCallback } from "react";
import { ChakraProps, useToast } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { QuestWithId } from "../../models/api/quest";
import { getQuests } from "../../api/quests/quests";

interface SelectQuestProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectQuest: React.FC<SelectQuestProps> = ({ color, value, setValue, ...chakraProps }) => {
  const toast = useToast();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      let quests: QuestWithId[] = [];

      try {
        quests = await getQuests(searchQuery);
      } catch (error) {
        toast({
          title: "API error occurred",
          description: (error as Error).message,
          status: "error",
        });
      }

      return quests.map(({ questId, name }) => [questId, name]);
    },
    [getQuests, toast]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect selectedValue={value} setSelectedValue={setValue} color={color} title={"Quest"} {...chakraProps} />
    </AsyncSearchProvider>
  );
};

export default SelectQuest;
