import { useQuery } from "@tanstack/react-query";
import { Allegiance } from "@worldwidewebb/client-skills";
import { getAllegiances } from "../../api/allegiances/allegiances";

export default function useAllegiances() {
  const {
    isLoading,
    isFetching,
    error,
    data: allegiances,
  } = useQuery<Allegiance[]>({
    queryKey: ["allegiance"],
    queryFn: () => getAllegiances(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    allegiances,
  };
}
