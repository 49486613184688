import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { generateVoices as generateVoicesAsync } from "../../api/voices/voices";

export default function useGenerateVoices() {
  const toast = useToast();

  const { isPending: isGenerating, mutate: generateVoices } = useMutation({
    mutationFn: (questId: string) => generateVoicesAsync(questId),
    onSuccess: async () => {
      toast({ title: "Voice generation batch successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Voice generation batch unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isGenerating,
    generateVoices,
  };
}
