import { useCallback } from "react";
import { useReactFlow } from "reactflow";
import { NodeType } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";

const useUpdateNode = (nodeId: string) => {
  const { setNodes } = useReactFlow<NodeType, EdgeType>();

  const updateNodeDisplayName = useCallback(
    (label: string) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            node.data = {
              ...node.data,
              label,
            };
          }

          return node;
        })
      );
    },
    [setNodes, nodeId]
  );

  const updateNodeDescription = useCallback(
    (nodeDescription: string) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            node.data = {
              ...node.data,
              nodeDescription,
            };
          }

          return node;
        })
      );
    },
    [setNodes, nodeId]
  );

  const updateNodeIsHandlesEditable = useCallback(
    (isEditable: boolean) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === nodeId) {
            node.data = {
              ...node.data,
              isSourceHandlesEditable: isEditable,
              isTargetHandlesEditable: isEditable,
            };
          }

          return node;
        })
      );
    },
    [setNodes, nodeId]
  );

  return {
    updateNodeDisplayName,
    updateNodeDescription,
    updateNodeIsHandlesEditable,
  };
};

export { useUpdateNode };
