import { DgsSpriteQuery, QuestEditorApi } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getLuts(): Promise<DgsSpriteQuery[]> {
  try {
    const { data: luts } = await questEditorApi.getLuts();

    if (!luts) {
      return [];
    }

    return luts.sort(({ displayName: a }, { displayName: b }) => (a < b ? -1 : a > b ? 1 : 0));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
