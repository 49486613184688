import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import { PassageType } from "@worldwidewebb/quest-shared/dist/dialog";

const passageTypes: Record<PassageType, string> = {
  continue: "CONTINUE",
  dialog: "DIALOG",
  dialog_ai: "DIALOG (AI)",
  auto_continue: "AUTO PLAY",
};

interface SelectPassageTypeProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectPassageType: React.FC<SelectPassageTypeProps> = ({ color, value, setValue, ...chakraProps }) => {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return Object.entries(passageTypes).filter(([_, displayName]) =>
      searchQuery ? displayName.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true
    );
  }, []);

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Passage Type"}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectPassageType;
