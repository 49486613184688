import React, { memo, useCallback, useMemo } from "react";
import { Command } from "../commands";
import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  MenuListProps,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface CommandMenuListProps extends MenuListProps {
  editorRef: React.RefObject<ReactCodeMirrorRef>;
  commands: Command[];
}

const CommandMenuList: React.FC<CommandMenuListProps> = ({ editorRef, commands, ...menuListProps }) => {
  const commandsGroupedByCategory = useMemo(
    () =>
      Object.entries(
        commands.reduce((commandsGroupedByCategory: Record<string, Command[]>, command: Command) => {
          commandsGroupedByCategory[command.category] ??= [];
          commandsGroupedByCategory[command.category].push(command);

          return commandsGroupedByCategory;
        }, {})
      ),
    [commands]
  );

  const handleClick = useCallback(
    (execute: Command["execute"]) => {
      if (editorRef.current == null) {
        return;
      }

      execute(editorRef.current);
    },
    [editorRef]
  );

  return (
    <MenuList
      borderWidth={2}
      borderColor={"theme.dark.background"}
      bg={"theme.dark.background"}
      p={0.5}
      {...menuListProps}
    >
      <Stack spacing={1}>
        {commandsGroupedByCategory.map(([category, commands]) => (
          <Menu key={category} placement={"right"}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size={"sm"} color={"white"} borderRadius={0}>
              <Text color={"white"} textTransform={"uppercase"}>
                {category}
              </Text>
            </MenuButton>
            <MenuList borderWidth={2} borderColor={"theme.dark.background"} bg={"theme.dark.background"} p={0.5}>
              {commands.map(({ icon, name, tooltip, execute, category }) =>
                category === "colour" ? (
                  <Tooltip key={name} label={tooltip} placement={"right"}>
                    <MenuItem color={name} icon={icon} onClick={() => handleClick(execute)}>
                      <Skeleton h={2} startColor={name} endColor={name}></Skeleton>
                    </MenuItem>
                  </Tooltip>
                ) : (
                  <Tooltip key={name} label={tooltip} placement={"right"}>
                    <MenuItem icon={icon} onClick={() => handleClick(execute)}>
                      <Text color={"white"} casing={"uppercase"}>
                        {name}
                      </Text>
                    </MenuItem>
                  </Tooltip>
                )
              )}
            </MenuList>
          </Menu>
        ))}
      </Stack>
    </MenuList>
  );
};

export default memo(CommandMenuList);
