import React, { memo, useCallback } from "react";
import { NodeProps, useReactFlow } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import SelectNpcIndicator from "../../base/SelectNpcIndicator";
import { FlowNodeWithChildren } from "./FlowNode";

interface FormData {
  npcIndicator: string;
}

const WaitForChatWithNpcNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const npcIndicator = formData?.npcIndicator;

  const { getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      npcIndicator,
    },
    mode: "onBlur",
  });

  const reactFlow = useReactFlow();

  const handleUpdate = useCallback(
    ({ npcIndicator }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.npcIndicator = npcIndicator;

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        return nodes;
      });
    },
    [reactFlow]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <SelectNpcIndicator
          value={getValues("npcIndicator")}
          setValue={(value) => setValue("npcIndicator", value)}
          color={color}
        />
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(WaitForChatWithNpcNode);
