import axios from "axios";

export const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    apikey: "d52fb3f1-104d-4fe3-9d18-2c49da197950",
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as any;

export function buildApiClientBasePath(serviceName: string) {
  const apiPrefix = window.WEBB_CONFIG?.BASE_URL_API_PREFIX ?? "https://play.webb.party/api/";
  const apiSuffix = window.WEBB_CONFIG?.BASE_URL_API_SUFFIX ?? "";
  return `${apiPrefix}${serviceName}${apiSuffix}`;
}

export const buildQuestClientBasePath = (): string => {
  if (window.WEBB_CONFIG) {
    // when deployed, we should use the proxy provided by the host
    return "/api/quest-editor";
  } else {
    // fall back to full URL of dev location
    return "https://quest-editor.apps.webb.party/api/quest-editor"
  }
};
