import { SkillApi } from "@worldwidewebb/client-skills";
import { axiosInstance, buildApiClientBasePath } from "..";

const skillApi = new SkillApi(undefined, buildApiClientBasePath("skills"), axiosInstance);

export async function getSkills() {
  try {
    const { data: skills } = await skillApi.listSkills();

    return skills.sort(({ displayName: a }, { displayName: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSkill(skillId: string) {
  try {
    const { data: skill } = await skillApi.getSkill(skillId);

    return skill;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
