import _ from "lodash";
import { NodeHandle } from "../models/nodeType";

export function findLastIndex(handles: NodeHandle[], handleNames: string[], handleName: string) {
  const handleNameIndex = handleNames.indexOf(handleName);

  if (handleNameIndex === -1) {
    return handles.length;
  }

  let searchIndex = handleNameIndex - 1;
  let searchHandleName = handleNames[searchIndex];

  while (!handles.some(({ handleName }) => handleName === searchHandleName) && searchIndex > 0) {
    searchIndex = searchIndex - 1;
    searchHandleName = handleNames[searchIndex];
  }

  if (searchHandleName == null) {
    return -1;
  }

  return _.findLastIndex(handles, ({ handleName }) => handleName === searchHandleName);
}

export function findNextIndex(handles: NodeHandle[], handleNames: string[], handleName: string) {
  const handleNameIndex = handleNames.indexOf(handleName);

  if (handleNameIndex === -1) {
    return handles.length;
  }

  let searchIndex = handleNameIndex + 1;
  let searchHandleName = handleNames[searchIndex];

  while (!handles.some(({ handleName }) => handleName === searchHandleName) && searchIndex < handleNames.length) {
    searchIndex = searchIndex + 1;
    searchHandleName = handleNames[searchIndex];
  }

  if (searchHandleName == null) {
    return handles.length;
  }

  return _.findIndex(handles, ({ handleName }) => handleName === searchHandleName);
}
