import { memo, useCallback } from "react";
import { MdSave } from "react-icons/md";
import { useReactFlowStoreProvider } from "../../context/ReactFlowStoreContext";
import useUpdateQuestMetaDatas from "../../hooks/quests/useUpdateQuestMetaDatas";
import Icon from "../../ui/base/chakra/Icon";
import IconButton from "../../ui/base/chakra/IconButton";
import Tooltip from "../../ui/base/chakra/Tooltip";

function QuestsSaveButton() {
  const { nodes } = useReactFlowStoreProvider();

  const { isUpdating, updateQuestMetaDatas } = useUpdateQuestMetaDatas();

  const handleSave = useCallback(() => {
    updateQuestMetaDatas(nodes.map(({ id, position }) => ({ questId: id, questEditorPosition: position })));
  }, [updateQuestMetaDatas, nodes]);

  return (
    <Tooltip label={"save"} color={"white"} shouldWrapChildren={true}>
      <IconButton
        aria-label={"save"}
        icon={<Icon as={MdSave} />}
        color={"white"}
        borderColor={"transparent"}
        onClick={handleSave}
        isLoading={isUpdating}
      />
    </Tooltip>
  );
}

export default memo(QuestsSaveButton);
