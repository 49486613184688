import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import AsyncSelect from "./AsyncSelect";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import useItems from "../../hooks/items/useItems";

interface SelectItemProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectItem: React.FC<SelectItemProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, items = [] } = useItems();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return items
        .filter(({ displayName }) =>
          displayName
            .toLowerCase()
            .trim()
            .includes(searchQuery?.toLowerCase().trim() ?? "")
        )
        .map(({ itemName, displayName }) => [itemName, `${displayName} (${itemName})`]);
    },
    [items]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Item"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectItem;
