import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

interface SpawnObjectNodeData extends NodeData {
  objectName: string;
}

const SpawnObjectNode: React.FC<NodeProps<NodeType<SpawnObjectNodeData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const objectName = nodeData?.objectName ?? "";

  const { register, handleSubmit } = useForm<SpawnObjectNodeData>({
    defaultValues: useMemo(
      () => ({
        objectName,
      }),
      [objectName]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    ({ objectName }: SpawnObjectNodeData) => {
      updateNodeData({
        objectName,
      });
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Object Name
            </Text>
          </FormLabel>
          <Input color={color} {...register("objectName")} />
        </FormControl>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(SpawnObjectNode);
