import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import SelectDirection from "../../base/SelectDirection";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

interface FormData {
  direction: string;
}

const NpcSetDirectionNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = {} },
  } = props;

  const formData = nodeData as FormData | undefined;
  const direction = formData?.direction;

  const { getValues, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      direction,
    },
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  const handleUpdate = useCallback(
    ({ direction }: FormData) => {
      updateNodeData({ direction });
    },
    [updateNodeData]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <SelectDirection
          value={getValues("direction")}
          setValue={(value) => setValue("direction", value)}
          color={color}
        />
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(NpcSetDirectionNode);
