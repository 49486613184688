import { Box, Text } from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system";
import { PropsWithChildren } from "react";

function QuestSearchResult({ color, children }: ChakraProps & PropsWithChildren) {
  return (
    <Box _hover={{ bg: "theme.dark.background" }} cursor={"pointer"} p={4} borderRadius={6}>
      <Text color={color} casing={"uppercase"} isTruncated={true}>
        {children}
      </Text>
    </Box>
  );
}

export default QuestSearchResult;
