import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import AsyncSelect from "./AsyncSelect";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import useItemTypes from "../../hooks/items/useItemTypes";

interface SelectItemProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectItemType: React.FC<SelectItemProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, itemTypes = [] } = useItemTypes();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return itemTypes
        .filter(({ displayName }) =>
          displayName
            .toLowerCase()
            .trim()
            .includes(searchQuery?.toLowerCase().trim() ?? "")
        )
        .sort(({ itemType: a }, { itemType: b }) => a.localeCompare(b))
        .map(({ itemType, displayName }) => [itemType, `${displayName} (${itemType})`]);
    },
    [itemTypes]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Item Type"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectItemType;
