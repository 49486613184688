import { useQuery } from "@tanstack/react-query";
import { Allegiance } from "@worldwidewebb/client-skills";
import { getAllegiance } from "../../api/allegiances/allegiances";

export default function useAllegiance(allegianceId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: allegiance,
  } = useQuery<Allegiance>({
    queryKey: ["allegiance", allegianceId],
    queryFn: () => getAllegiance(allegianceId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    allegiance,
  };
}
