import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { MdFilterAlt } from "react-icons/md";
import { Form } from "react-router-dom";
import { useReactFlowStoreProvider } from "../../context/ReactFlowStoreContext";
import { useStoreSelector } from "../../store/hooks";
import Icon from "../../ui/base/chakra/Icon";
import { filterNodesAndEdges, FilterOptions } from "../../utils/questNodes";

function QuestsFilter() {
  const { questNodes, questEdges } = useStoreSelector(({ quests }) => quests);

  const { setNodes, setEdges } = useReactFlowStoreProvider();

  const { register, handleSubmit } = useForm<FilterOptions>({
    defaultValues: {
      includeDraft: true,
      includeDev: true,
      includeLive: true,
    },
    mode: "onChange",
  });

  const handleApplyFilters = useCallback(
    (filterOptions: FilterOptions) => {
      const { filteredNodes, filteredEdges } = filterNodesAndEdges(questNodes, questEdges, filterOptions);

      setNodes(filteredNodes);
      setEdges(filteredEdges);
    },
    [questNodes, questEdges]
  );

  return (
    <Popover placement={"bottom"}>
      <PopoverTrigger>
        <Button color={"white"} variant={"ghost"}>
          <Icon as={MdFilterAlt} />
        </Button>
      </PopoverTrigger>

      <PopoverContent w={"max-content"} bg={"theme.dark.background"} borderRadius={0} borderWidth={0}>
        <PopoverBody>
          <Form onSubmit={handleSubmit(handleApplyFilters)}>
            <Stack>
              <FormControl>
                <Checkbox {...register("includeDraft")}>
                  <FormLabel my={0}>
                    <Text color={"white"} casing={"uppercase"}>
                      draft
                    </Text>
                  </FormLabel>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register("includeDev")}>
                  <FormLabel my={0}>
                    <Text color={"white"} casing={"uppercase"}>
                      dev
                    </Text>
                  </FormLabel>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register("includeLive")}>
                  <FormLabel my={0}>
                    <Text color={"white"} casing={"uppercase"}>
                      live
                    </Text>
                  </FormLabel>
                </Checkbox>
              </FormControl>

              <Button color={"white"} type={"submit"}>
                <Text color={"white"} casing={"uppercase"}>
                  Apply
                </Text>
              </Button>
            </Stack>
          </Form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default memo(QuestsFilter);
