import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { insertSurroundingText } from "../utils/insertSurroundingText";
import { IoIosPulse } from "react-icons/io";

export const scribbleJitter: Command = {
  category: "effect",
  icon: <Icon as={IoIosPulse} />,
  name: "jitter",
  tooltip: "tag with jitter effect",
  execute: (editorRef) => insertSurroundingText(editorRef, "{{#jitter}}", "{{/jitter}}"),
};
