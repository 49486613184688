import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createQuestEditorSession as createQuestEditorSessionAsync } from "../../api/sessions/sessions";

export default function useCreateQuestEditorSession() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isCreating, mutate: createQuestEditorSession } = useMutation({
    mutationFn: (questId: string) => createQuestEditorSessionAsync(questId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["questEditorSession"],
      });

      toast({ title: "Quest editor session create successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest editor session create unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isCreating,
    createQuestEditorSession,
  };
}
