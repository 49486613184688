import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateQuestMetaDatas as updateQuestMetaDatasAsync } from "../../api/quests/quests";

export default function useUpdateQuestMetaDatas() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isUpdating, mutate: updateQuestMetaDatas } = useMutation({
    mutationFn: updateQuestMetaDatasAsync,
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: ["quests"],
      });

      await queryClient.resetQueries({
        queryKey: ["quest"],
      });

      toast({ title: "Quest updates successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest update unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isUpdating,
    updateQuestMetaDatas,
  };
}
