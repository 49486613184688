import { useQuery } from "@tanstack/react-query";
import { ItemType } from "@worldwidewebb/client-assets";
import { getItemTypes } from "../../api/items/itemTypes";

export default function useItemTypes() {
  const {
    isLoading,
    isFetching,
    error,
    data: itemTypes,
  } = useQuery<ItemType[]>({
    queryKey: ["itemType"],
    queryFn: () => getItemTypes(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    itemTypes,
  };
}
