import React, { memo, useCallback, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Input, Stack, Text } from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectLut from "../../base/SelectLut";

interface SetLut extends NodeData {
  lutName: string;
  blend: number;
}

const SetLutNode: React.FC<NodeProps<NodeType<SetLut>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const lutName = nodeData?.lutName ?? "";
  const blend = nodeData?.blend ?? 1;

  const { register, getValues, setValue, handleSubmit } = useForm<SetLut>({
    defaultValues: useMemo(
      () => ({
        lutName,
        blend,
      }),
      [lutName, blend]
    ),
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<SetLut>(nodeId);

  const handleUpdate = useCallback(
    ({ lutName, blend }: SetLut) => {
      const clampedBlend = Math.min(1, Math.max(0, blend)) || 1;

      updateNodeData({
        lutName,
        blend: clampedBlend,
      });

      setValue("blend", clampedBlend);
    },
    [updateNodeData, setValue]
  );

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <SelectLut color={color} value={getValues("lutName")} setValue={(value) => setValue("lutName", value)} />

          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Blend
              </Text>
            </FormLabel>
            <Input
              {...register("blend", { valueAsNumber: true })}
              color={color}
              borderColor={color}
              borderRadius={0}
              borderWidth={2}
            />
          </FormControl>
        </Stack>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(SetLutNode);
