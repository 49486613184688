import { Checkbox, FormControl, FormLabel, Text } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { NodeProps, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import { NodeType, SourceHandle } from "../../../models/nodeType";
import { FlowNodeWithChildren } from "./FlowNode";

interface FormData {
  removeRequiredItems: boolean;
}

const CompleteQuestTaskNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData = [], sourceHandles = [] },
  } = props;

  const formData = nodeData as FormData | undefined;
  const removeRequiredItems = formData?.removeRequiredItems ?? false;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      removeRequiredItems,
    },
    mode: "onChange",
  });

  const reactFlow = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const { updateNodeSourceHandles } = useUpdateNodeHandles(nodeId);

  const handleUpdate = useCallback(
    ({ removeRequiredItems }: FormData) => {
      reactFlow.setNodes((nodes) => {
        const node = nodes.find(({ id }) => id === nodeId);

        if (node == null) {
          return nodes;
        }

        const nodeDataCloned = structuredClone(node.data) as NodeType;

        const nodeData = (nodeDataCloned.nodeData as FormData) ?? {};
        nodeData.removeRequiredItems = removeRequiredItems;

        const updatedSourceHandles: SourceHandle[] = [];

        if (removeRequiredItems) {
          updatedSourceHandles.push(
            {
              handleId: sourceHandles.at(0)?.handleId,
              label: "Success",
              handleName: "success",
              handleType: "source",
              handleCategory: "flow",
            },
            {
              handleId: sourceHandles.at(1)?.handleId,
              label: "Fail",
              handleName: "fail",
              handleType: "source",
              handleCategory: "flow",
            }
          );
        } else {
          updatedSourceHandles.push({
            handleId: sourceHandles.at(0)?.handleId,
            label: "OUT",
            handleName: "out",
            handleType: "source",
            handleCategory: "flow",
          });
        }

        node.data = {
          ...nodeDataCloned,
          nodeData,
        };

        updateNodeSourceHandles(updatedSourceHandles);

        return nodes;
      });

      updateNodeInternals(nodeId);
    },
    [reactFlow, nodeId, sourceHandles]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onChange={handleSubmit(handleUpdate)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                Remove Required Items
              </Text>
            </FormLabel>
            <Checkbox {...register("removeRequiredItems")} color={color} />
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(CompleteQuestTaskNode);
