import { Divider, Stack } from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system";
import { QuestWithId } from "../../models/api/quest";
import QuestOverview from "./QuestOverview";

interface QuestNodeOverviewProps extends ChakraProps {
  quest?: QuestWithId;
}

function QuestNodeOverview({ color, quest }: QuestNodeOverviewProps) {
  if (quest == null) {
    return null;
  }

  return (
    <Stack spacing={4} maxW={"xl"}>
      <Divider color={color} />

      <QuestOverview color={color} quest={quest} />
    </Stack>
  );
}

export default QuestNodeOverview;
