import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { memo } from "react";

export default memo(function ({ color, ...iconButtonProps }: IconButtonProps) {
  return (
    <IconButton
      bg={"theme.dark.background"}
      borderColor={color}
      borderWidth={2}
      color={color}
      variant={"outline"}
      isRound={true}
      {...iconButtonProps}
    />
  );
});
