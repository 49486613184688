import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import useLuts from "../../hooks/quests/useLuts";

interface SelectQuestProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectLut: React.FC<SelectQuestProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, luts = [] } = useLuts();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return luts
        .filter(({ displayName }) => displayName.toLowerCase().includes(searchQuery?.toLowerCase().trim() ?? ""))
        .map(({ spriteName, displayName }) => [spriteName, displayName]);
    },
    [luts]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"LUT"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectLut;
