import { Box, Center, ChakraProps } from "@chakra-ui/react";
import { memo, MutableRefObject, ReactElement, useRef } from "react";
import { useSelectedQuestProvider } from "../../context/SelectedQuestProvider";
import useContainerWidth from "../../hooks/useContainerWidth";
import QuestSelectTarget from "./QuestSelectTarget";
import QuestUpdateTarget from "./QuestUpdateTarget";

interface QuestContainerProps extends ChakraProps {
  questId: string;
}

function QuestContainer({ questId, ...chakraProps }: QuestContainerProps) {
  const { selectedQuestId } = useSelectedQuestProvider();
  const hasSelectedQuestId = selectedQuestId != null;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerRefDependencies = [hasSelectedQuestId, questId];
  const containerWidth = useContainerWidth(containerRef, containerRefDependencies);

  let questContainerContent: ReactElement | undefined = undefined;

  if (selectedQuestId !== questId && hasSelectedQuestId) {
    questContainerContent = <QuestUpdateTarget color={chakraProps.color} questId={questId} />;
  } else {
    questContainerContent = <QuestSelectTarget color={chakraProps.color} questId={questId} />;
  }

  return (
    <Box
      className={"nodrag"}
      position={"absolute"}
      ref={containerRef}
      top={0}
      left={`-${containerWidth}px`}
      {...chakraProps}
    >
      <Center
        bg={"theme.dark.background"}
        borderColor={chakraProps.color}
        borderTopWidth={8}
        mr={2}
        h={`${5.5}rem`}
        w={`${5.5}rem`}
      >
        {questContainerContent}
      </Center>
    </Box>
  );
}

export default memo(QuestContainer);
