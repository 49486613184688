import { memo, useCallback, useEffect } from "react";
import { ChakraProps } from "@chakra-ui/system";
import { Checkbox, FormControl, FormLabel, Input, Stack, Text } from "@chakra-ui/react";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import AsyncSelect from "./AsyncSelect";
import SelectNpcWithLastUsed from "./SelectNpcWithLastUsed";

type ModTarget = "entity" | "npc";

const modTargets: ModTarget[] = ["entity", "npc"];

interface SelectModTargetIdProps extends ChakraProps {
  modTarget: ModTarget;
  modTargetId: string;
  setModTargetId: (value: string) => void;
}

function SelectModTargetId({ color, modTarget, modTargetId, setModTargetId }: SelectModTargetIdProps) {
  if (!modTarget) {
    return null;
  }

  switch (modTarget) {
    case "entity": {
      return <SelectModTargetIdAsEntity color={color} modTargetId={modTargetId} setModTargetId={setModTargetId} />;
    }
    case "npc": {
      return <SelectModTargetIdAsNpc color={color} modTargetId={modTargetId} setModTargetId={setModTargetId} />;
    }
    default: {
      return null;
    }
  }
}

interface SelectModTargetIdComponentProps extends ChakraProps {
  modTargetId: string;
  setModTargetId: (value: string) => void;
}

function SelectModTargetIdAsEntity({ color, modTargetId, setModTargetId }: SelectModTargetIdComponentProps) {
  return (
    <FormControl>
      <FormLabel>
        <Text color={color} casing={"uppercase"}>
          Entity Object Name
        </Text>
      </FormLabel>

      <Input
        color={color}
        borderColor={color}
        borderRadius={0}
        borderWidth={2}
        value={modTargetId}
        onChange={({ target: { value } }) => setModTargetId(value)}
      />
    </FormControl>
  );
}

function SelectModTargetIdAsNpc({ color, modTargetId, setModTargetId }: SelectModTargetIdComponentProps) {
  return <SelectNpcWithLastUsed color={color} value={modTargetId} setValue={setModTargetId} />;
}

interface SelectModTargetProps extends ChakraProps {
  modTarget: ModTarget;
  setModTarget: (value: ModTarget) => void;
  modTargetId: string;
  setModTargetId: (value: string) => void;
  modTargetIdSelectionEnabled: boolean;
  setModTargetIdSelectionEnabled: (value: boolean) => void;
}

function SelectModTarget({
  color,
  modTarget,
  setModTarget,
  modTargetId,
  setModTargetId,
  modTargetIdSelectionEnabled,
  setModTargetIdSelectionEnabled,
  ...chakraProps
}: SelectModTargetProps) {
  const handleSearch = useCallback(async (searchQuery?: string): Promise<[string, string][]> => {
    return modTargets
      .filter((modTarget) => (searchQuery ? modTarget.toLowerCase().includes(searchQuery.toLowerCase().trim()) : true))
      .sort((a, b) => a.localeCompare(b))
      .map((modTarget) => [modTarget, modTarget]);
  }, []);

  useEffect(() => {
    setModTargetId("");
  }, [modTarget]);

  return (
    <Stack>
      <AsyncSearchProvider onSearchAsync={handleSearch}>
        <AsyncSelect
          color={color}
          selectedValue={modTarget}
          setSelectedValue={(value) => setModTarget(value as ModTarget)}
          title={"Target"}
          {...chakraProps}
        />
      </AsyncSearchProvider>

      {Boolean(modTarget) && (
        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              {modTarget} selection enabled
            </Text>
          </FormLabel>

          <Checkbox
            color={color}
            isChecked={modTargetIdSelectionEnabled}
            onChange={({ target: { checked } }) => setModTargetIdSelectionEnabled(checked)}
          />
        </FormControl>
      )}

      {modTargetIdSelectionEnabled && (
        <SelectModTargetId
          color={color}
          modTarget={modTarget}
          modTargetId={modTargetId}
          setModTargetId={setModTargetId}
        />
      )}
    </Stack>
  );
}

export default memo(SelectModTarget);
