import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/400.css";
import MultiProvider from "./context/MultiProvider";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@worldwidewebb/frontend-components";
import { DndProvider as DragAndDropProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./index.scss";
import { Provider } from "react-redux";
import store from "./store";
import { NodeNotificationsProvider } from "./context/NodeNotificationsProvider";
import { ReactFlowProvider } from "reactflow";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MultiProvider
        providers={[
          <ChakraProvider theme={theme} />,
          <DragAndDropProvider backend={HTML5Backend} />,
          <NodeNotificationsProvider />,
          <ReactFlowProvider />,
        ]}
      >
        <App />
      </MultiProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.onbeforeunload = (event) => {
  const confirmed = confirm("Are you sure you want to leave?");

  if (confirmed) {
    return;
  }

  event.preventDefault();
};
