import { useQuery } from "@tanstack/react-query";
import { CraftingStationWithId } from "@worldwidewebb/client-assets";
import { getCraftingStations } from "../../api/craftingStations/craftingStations";

export default function useCraftingStations() {
  const {
    isLoading,
    isFetching,
    error,
    data: craftingStations,
  } = useQuery<CraftingStationWithId[]>({
    queryKey: ["craftingStation"],
    queryFn: () => getCraftingStations(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    craftingStations,
  };
}
