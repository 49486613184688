import React from "react";
import { Text } from "@chakra-ui/react";

interface InputErrorProps {
  errorMessage?: string;
}

export const InputError: React.FC<InputErrorProps> = ({ errorMessage }) => {
  return (
    <Text size={"sm"} color={"red.800"}>
      {errorMessage}
    </Text>
  );
};
