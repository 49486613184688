import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";

interface FormData {
  url: string;
}

const OpenUrlNode: React.FC<NodeProps<NodeType>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const url = nodeData?.url ?? "";

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      url,
    },
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<FormData>(nodeId);

  const handleUpdate = useCallback(
    ({ url }: FormData) => {
      updateNodeData({ url });
    },
    [updateNodeData]
  );

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
          <FormControl>
            <FormLabel>
              <Text casing={"uppercase"} color={color}>
                URL
              </Text>
            </FormLabel>
            <Input color={color} {...register("url")} />
          </FormControl>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(OpenUrlNode);
