import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const ScrollableBox: React.FC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box
      sx={{
        "::-webkit-scrollbar": {
          width: 2,
        },
        "::-webkit-scrollbar-track": {
          bg: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          bg: boxProps.color,
        },
      }}
      overflowY={"auto"}
      onWheel={(event) => event.stopPropagation()}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default ScrollableBox;
