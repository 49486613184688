import { Card, CardBody } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { Panel, PanelPosition } from "reactflow";

interface StyledPanelProps extends PropsWithChildren {
  position: PanelPosition;
}

export function StyledPanel({ position, children }: StyledPanelProps) {
  return (
    <Panel position={position}>
      <Card bg={"theme.dark.background"}>
        <CardBody p={1}>{children}</CardBody>
      </Card>
    </Panel>
  );
}
