import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { duplicateQuest as duplicateQuestAsync } from "../../api/quests/quests";
import { useCopyAndPaste } from "../useCopyAndPaste";
import useExportRuntimeData from "../useExportRuntimeData";
import useInitialNodeTypes from "../useInitialNodeTypes";
import useQuestSubgraphs from "./useQuestSubgraphs";

export default function useDuplicateQuest() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { createDuplicates } = useCopyAndPaste();
  const { exportQuestNodes } = useExportRuntimeData();

  const { questSubgraphs = [] } = useQuestSubgraphs();
  const initialNodeTypes = useInitialNodeTypes(questSubgraphs);

  const { isPending: isDuplicating, mutate: duplicateQuest } = useMutation({
    mutationFn: (questId: string) => duplicateQuestAsync(questId, createDuplicates, exportQuestNodes, initialNodeTypes),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["quest"],
      });

      toast({ title: "Quest duplication successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest duplication unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isDuplicating,
    duplicateQuest,
  };
}
