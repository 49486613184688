import { Avatar, HStack, Spinner, Text } from "@chakra-ui/react";
import { memo } from "react";
import useItem from "../../hooks/items/useItem";

interface ItemNameAndQuantityComponentProps {
  itemName: string;
  quantity: number;
}

function ItemNameAndQuantityComponent({ itemName, quantity }: ItemNameAndQuantityComponentProps) {
  const { isLoading, error, item, sprite } = useItem(itemName);

  if (isLoading) {
    return <Spinner size={"md"} color={"white"} />;
  }

  if (error || item == null || sprite == null) {
    return <Spinner size={"md"} color={"red.800"} />;
  }

  const { displayName } = item;

  const { imageUri } = sprite;

  return (
    <HStack alignItems={"center"}>
      <Avatar
        sx={{ imageRendering: "pixelated" }}
        bg={"transparent"}
        borderColor={"transparent"}
        src={imageUri}
        size={"md"}
      />

      <Text color={"white"} fontWeight={500}>
        {quantity} &times; {displayName}
      </Text>
    </HStack>
  );
}

export default memo(ItemNameAndQuantityComponent);
