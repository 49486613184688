import {
    Button,
    HStack,
    Icon,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
    Stack,
    Text,
  } from "@chakra-ui/react";
import { NodeCategory } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
  import { Dispatch, memo, SetStateAction, useCallback, useState } from "react";
  import { MdArrowRight } from "react-icons/md";
import { nodeCategories } from "../../../models/nodeType";
  
  // TODO: VERIFICATION PENDING
  
  type NodeCategorySelectionMode = "single" | "multiple" | null;
  
  interface QuestNodeCategorySelectorProps {
    selectedNodeCategories: NodeCategory[];
    setSelectedNodeCategories: Dispatch<SetStateAction<NodeCategory[]>>;
  }
  
  function QuestNodeCategorySelector({
    selectedNodeCategories,
    setSelectedNodeCategories,
  }: QuestNodeCategorySelectorProps) {
    const [selectionMode, setSelectionMode] = useState<NodeCategorySelectionMode>("single");
  
    const handleClearSelection = useCallback(() => {
      setSelectionMode("single");
  
      setSelectedNodeCategories([]);
    }, []);
  
    const handleUpdateSelectionMode = useCallback(
      (updatedSelectionMode: NodeCategorySelectionMode) => {
        if (selectionMode === "multiple" && updatedSelectionMode === "single") {
          setSelectedNodeCategories((selectedNodeCategories) => selectedNodeCategories.slice(0, 1));
        }
  
        setSelectionMode(updatedSelectionMode);
      },
      [selectionMode]
    );
  
    const handleToggleSelectedNodeCategory = useCallback(
      (nodeCategory: NodeCategory) => {
        if (selectionMode === "single") {
          if (selectedNodeCategories.includes(nodeCategory)) {
            setSelectedNodeCategories([]);
          } else {
            setSelectedNodeCategories([nodeCategory]);
          }
        } else {
          if (selectedNodeCategories.includes(nodeCategory)) {
            setSelectedNodeCategories((selectedNodeCategories) =>
              selectedNodeCategories.filter((selectedNodeCategory) => selectedNodeCategory !== nodeCategory)
            );
          } else {
            setSelectedNodeCategories((selectedNodeCategories) => [...selectedNodeCategories, nodeCategory]);
          }
        }
      },
      [selectionMode, selectedNodeCategories]
    );
  
    return (
      <Popover placement={"right"}>
        <PopoverTrigger>
          <Button bg={"indigo.600"} borderRadius={0} rightIcon={<Icon color={"white"} as={MdArrowRight} />}>
            <Text color={"white"} casing={"uppercase"}>
              Categories
            </Text>
          </Button>
        </PopoverTrigger>
  
        <PopoverContent bg={"gray.900"} borderRadius={0} borderWidth={0} boxShadow={"dark-lg"} w={"fit"}>
          <Stack>
            <Button color={"violet.600"} borderRadius={0} onClick={handleClearSelection}>
              <Text color={"white"} casing={"uppercase"}>
                Clear Selection
              </Text>
            </Button>
  
            <HStack>
              <Button
                color={"white"}
                bg={selectionMode === "single" ? "indigo.600" : "transparent"}
                borderRadius={0}
                w={"full"}
                onClick={() => handleUpdateSelectionMode("single")}
              >
                <Text color={"white"} casing={"uppercase"}>
                  Single
                </Text>
              </Button>
  
              <Button
                color={"white"}
                bg={selectionMode === "multiple" ? "indigo.600" : "transparent"}
                borderRadius={0}
                w={"full"}
                onClick={() => handleUpdateSelectionMode("multiple")}
              >
                <Text color={"white"} casing={"uppercase"}>
                  Multiple
                </Text>
              </Button>
            </HStack>
  
            <SimpleGrid columns={2} spacing={2}>
              {nodeCategories &&
                nodeCategories.map((nodeCategory) => (
                  <Button
                    key={nodeCategory}
                    bg={selectedNodeCategories.includes(nodeCategory) ? "indigo.600" : "transparent"}
                    borderRadius={0}
                    w={"full"}
                    onClick={() => handleToggleSelectedNodeCategory(nodeCategory)}
                  >
                    <Text color={"white"} casing={"uppercase"}>
                      {nodeCategory}
                    </Text>
                  </Button>
                ))}
            </SimpleGrid>
          </Stack>
        </PopoverContent>
      </Popover>
    );
  }
  
  export default memo(QuestNodeCategorySelector);
  