import { AssetsApi } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getSfx(searchQuery?: string) {
  try {
    const { data } = await assetsApi.getAudioMetadatasForTypeByQuery("sfx", searchQuery);

    return data.map(({ filename }) => filename).sort((a, b) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAmbience(searchQuery?: string) {
  try {
    const { data } = await assetsApi.getAudioMetadatasForTypeByQuery("ambience", searchQuery);

    return data.map(({ filename }) => filename).sort((a, b) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
