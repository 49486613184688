import { Box } from "@chakra-ui/react";
import { memo, useMemo, useRef } from "react";
import { NodeProps } from "reactflow";
import QuestContainer from "../../components/quests/QuestContainer";
import { BaseNodeWithChildren } from "../../components/reactFlow/nodeTypes/base/BaseNode";
import useQuest from "../../hooks/quests/useQuest";
import { NodeType } from "../../models/nodeType";
import QuestNavigateToButton from "./QuestNavigateToButton";
import QuestNodeBanner from "./QuestNodeBanner";
import QuestNodeHeader from "./QuestNodeHeader";
import QuestNodeOverview from "./QuestNodeOverview";
import QuestRefreshButton from "./QuestRefreshButton";

function QuestNode(nodeProps: NodeProps<NodeType>) {
  const {
    id: questId,
    data: { color, version: questVersion },
  } = nodeProps;

  const { isLoading, quest } = useQuest(questId, questVersion);

  const header = useMemo(
    () =>
      quest && (
        <>
          <QuestNodeHeader color={color} quest={quest} isLoading={isLoading} />
        </>
      ),
    [color, quest, isLoading]
  );

  const headerButtons = useMemo(
    () => (
      <>
        <QuestRefreshButton color={color} questId={questId} isLoading={isLoading} isDisabled={isLoading} />
        <QuestNavigateToButton color={color} questId={questId} />
      </>
    ),
    [color, questId, isLoading]
  );

  return (
    <>
      <QuestNodeBanner quest={quest} />

      <Box position={"relative"}>
        <QuestContainer mx={1} mb={1} color={color} questId={questId} />

        <BaseNodeWithChildren
          header={header}
          headerButtons={headerButtons}
          {...nodeProps}
          data={{ ...nodeProps.data, label: quest?.name }}
          disableIsReadyBorder={true}
        >
          <QuestNodeOverview color={color} quest={quest} />
        </BaseNodeWithChildren>
      </Box>
    </>
  );
}

export default memo(QuestNode);
