import React, { PropsWithChildren, useCallback, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Tooltip from "../../ui/base/chakra/Tooltip";

interface ConfirmModalProps extends ButtonProps, PropsWithChildren {
  title: string;
  tooltip?: string;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ color, title, tooltip, onConfirm, children, ...buttonProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  return (
    <>
      <Tooltip color={color} shouldWrapChildren={true} label={tooltip}>
        <Button onClick={onOpen} {...buttonProps}>
          <Text color={color} casing={"uppercase"}>
            {title}
          </Text>
        </Button>
      </Tooltip>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={leastDestructiveRef}>
        <AlertDialogOverlay />

        <AlertDialogContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
          <AlertDialogHeader bg={"indigo.600"}>
            <Heading size={"md"}>
              <Text color={"white"}>{title}</Text>
            </Heading>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text color={"white"}>{children}</Text>
          </AlertDialogBody>

          <AlertDialogFooter gap={2}>
            <Button ref={leastDestructiveRef} onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} variant={"outline"}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ConfirmModal;
