import { Outlet } from "react-router";
import Sidebar, { SidebarItem } from "../components/navigation/Sidebar";
import NodeDebugger from "../components/navigation/elements/NodeDebugger";
import MultiProvider from "../context/MultiProvider";
import { ConnectionProvider } from "../context/ConnectionContext";
import { UserSettingsProvider } from "../context/UserSettingsContext";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { CgDebug } from "react-icons/cg";
import { ReactFlowProvider } from "reactflow";
import { Flex, Grid, GridItem, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { UserQuestPointerProvider } from "../context/UserQuestPointerContext";
import { ReactFlowStoreProvider } from "../context/ReactFlowStoreContext";
import { ReactFlowAutoLayoutProvider } from "../context/ReactFlowAutoLayoutProvider";
import { QuestsProvider } from "../context/QuestsProvider";
import { UserProvider } from "../context/UserContext";
import { SelectedQuestProvider } from "../context/SelectedQuestProvider";

const items: SidebarItem[] = [
  {
    icon: CgDebug,
    label: "quest pointers",
    content: <NodeDebugger />,
  },
];

function QuestsOverviewLayout() {
  const [isNavigating, setIsNavigating] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleNavigateToHome = useCallback(() => {
    setIsNavigating(true);

    navigate("/");
  }, [navigate]);

  return (
    <MultiProvider
      providers={[
        <ReactFlowProvider />,
        <ReactFlowStoreProvider initialNodes={[]} initialEdges={[]} />,
        <ReactFlowAutoLayoutProvider direction={"LR"} />,
        <QuestsProvider />,
        <SelectedQuestProvider />,
        <ConnectionProvider />,
        <UserQuestPointerProvider />,
        <UserSettingsProvider />,
        <UserProvider />,
      ]}
    >
      <Grid as={"main"} minH={"100vh"} templateColumns={"1fr"}>
        <GridItem display={"grid"}>
          <Outlet />
        </GridItem>

        <GridItem
          display={"flex"}
          flexDirection={"column"}
          bg={"theme.dark.background"}
          position={"fixed"}
          top={0}
          left={0}
          bottom={0}
        >
          <Flex bg={"indigo.600"}>
            <Tooltip label={"return home"} placement={"right"}>
              <IconButton
                color={"white"}
                icon={<Icon as={MdHome} />}
                aria-label={"return home"}
                variant={"ghost"}
                borderRadius={0}
                isLoading={isNavigating}
                onClick={handleNavigateToHome}
              />
            </Tooltip>
          </Flex>

          <Sidebar flexGrow={1} bg={"theme.dark.background"} color={"white"} items={items} showContent={false} />
        </GridItem>
      </Grid>
    </MultiProvider>
  );
}

export default QuestsOverviewLayout;
