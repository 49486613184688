import styled from "@emotion/styled";
import { Background } from "reactflow";

const StyledBackground = styled(Background)(
  () => `
    background-color: inherit;
  `
);

export default StyledBackground;
