import React, { memo, useCallback, useEffect, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Checkbox, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { useUpdateNodeHandles } from "../../../hooks/useUpdateNodeHandles";
import SelectNpcWithLastUsed from "../../base/SelectNpcWithLastUsed";
import SelectEmote from "../../base/SelectEmote";

interface ShowEmoteOnNpc extends NodeData {
  npcId: string;
  npcIdSelectionEnabled: boolean;
  emote: string;
}

const ShowEmoteOnNpcNode: React.FC<NodeProps<NodeType<ShowEmoteOnNpc>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData, targetHandles = [] },
  } = props;

  const npcId = nodeData?.npcId ?? "last_used";
  const npcIdSelectionEnabled = nodeData?.npcIdSelectionEnabled ?? false;
  const emote = nodeData?.emote ?? "";

  const { register, getValues, setValue, handleSubmit, watch } = useForm<ShowEmoteOnNpc>({
    defaultValues: useMemo(
      () => ({
        npcId,
        npcIdSelectionEnabled,
        emote,
      }),
      [npcId, npcIdSelectionEnabled, emote]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<ShowEmoteOnNpc>(nodeId);

  const handleUpdate = useCallback(
    (nodeData: ShowEmoteOnNpc) => {
      updateNodeData(nodeData);
    },
    [updateNodeData]
  );

  const { updateNodeTargetHandles } = useUpdateNodeHandles(nodeId);

  const npcIdSelectionEnabledWatched = watch("npcIdSelectionEnabled");

  useEffect(() => {
    let updatedTargetHandles = targetHandles.slice();

    if (npcIdSelectionEnabledWatched) {
      updatedTargetHandles = updatedTargetHandles.filter(({ handleName }) => handleName !== "npc");
    } else if (!updatedTargetHandles.some(({ handleName }) => handleName === "npc")) {
      updatedTargetHandles.push({
        label: "NPC",
        handleName: "npc",
        handleType: "target",
        handleCategory: "data",
      });
    }

    updateNodeTargetHandles(updatedTargetHandles);
  }, [npcIdSelectionEnabledWatched]);

  return (
    <FlowNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack>
          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                NPC selection enabled
              </Text>
            </FormLabel>

            <Checkbox color={color} {...register("npcIdSelectionEnabled")} />
          </FormControl>

          {npcIdSelectionEnabledWatched && (
            <SelectNpcWithLastUsed
              value={getValues("npcId")}
              setValue={(value) => setValue("npcId", value)}
              color={color}
            />
          )}

          <SelectEmote value={getValues("emote")} setValue={(value) => setValue("emote", value)} color={color} />
        </Stack>
      </form>
    </FlowNodeWithChildren>
  );
};

export default memo(ShowEmoteOnNpcNode);
