import { useQuery } from "@tanstack/react-query";
import { Skill } from "@worldwidewebb/client-skills";
import { getSkills } from "../../api/skills/skills";

export default function useSkills() {
  const {
    isLoading,
    isFetching,
    error,
    data: skills,
  } = useQuery<Skill[]>({
    queryKey: ["skill"],
    queryFn: () => getSkills(),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    skills,
  };
}
