import { useQuery } from "@tanstack/react-query";
import { Profile } from "@worldwidewebb/client-auth";
import { getProfile } from "../../api/users/users";

export default function useProfile(userId?: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: profile,
  } = useQuery<Profile | null>({
    queryKey: ["profile", userId],
    queryFn: () => getProfile(userId),
    enabled: !!userId,
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    profile,
  };
}
