import { memo, useMemo } from "react";
import { EdgeProps, useReactFlow } from "reactflow";
import BaseEdge from "../../components/reactFlow/edgeTypes/base/BaseEdge";
import { EdgeType } from "../../models/edgeType";
import { NodeType } from "../../models/nodeType";

function QuestEdge(edgeProps: EdgeProps) {
  const { getNodes } = useReactFlow<NodeType, EdgeType>();

  const color = useMemo(() => {
    const sourceNodeId = edgeProps.source;
    const targetNodeId = edgeProps.target;

    const sourceNode = getNodes().find(({ id }) => id === sourceNodeId);
    const targetNode = getNodes().find(({ id }) => id === targetNodeId);

    if (sourceNode == null) {
      return undefined;
    }

    if (targetNode == null) {
      return undefined;
    }

    return sourceNode.data.isReady && targetNode.data.isReady ? "green.600" : "red.800";
  }, [edgeProps, getNodes]);

  return <BaseEdge {...edgeProps} color={color} isDeletable={false} />;
}

export default memo(QuestEdge);
