import { useQuery } from "@tanstack/react-query";
import { QuestEditorSessionWithId } from "@worldwidewebb/client-quests";
import { getQuestEditorSessions } from "../../api/sessions/sessions";

export default function useQuestEditorSessions(questId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: questEditorSessions,
  } = useQuery<QuestEditorSessionWithId[]>({
    queryKey: ["questEditorSession"],
    queryFn: () => getQuestEditorSessions(questId),
    refetchInterval: 30_000,
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    questEditorSessions,
  };
}
