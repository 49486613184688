import { AssetsApi, ItemIntrinsicWithImage, ItemIntrinsicWithName } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getItem(itemName: string): Promise<ItemIntrinsicWithImage> {
  try {
    const { data: item } = await assetsApi.getItemIntrinsic(itemName);

    return item;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getItems(): Promise<ItemIntrinsicWithName[]> {
  try {
    const { data: items } = await assetsApi.getItemIntrinsicsByQuery();

    return items.sort(({ itemName: a }, { itemName: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
