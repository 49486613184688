import { Spinner, Tag, Text } from "@chakra-ui/react";
import { memo } from "react";
import useAllegiance from "../../hooks/allegiances/useAllegiance";

interface AllegianceWithAmountComponentProps {
  allegianceId: string;
  allegianceAmount: number;
}

function AllegianceWithAmountComponent({ allegianceId, allegianceAmount }: AllegianceWithAmountComponentProps) {
  const { isLoading, error, allegiance } = useAllegiance(allegianceId);

  if (isLoading) {
    return <Spinner size={"md"} color={"white"} />;
  }

  if (error || allegiance == null) {
    return <Spinner size={"md"} color={"red.800"} />;
  }

  const { displayName, category } = allegiance;

  return (
    <Tag p={2}>
      <Text color={"white"} casing={"uppercase"}>
        {displayName} - {category} ({allegianceAmount})
      </Text>
    </Tag>
  );
}

export default memo(AllegianceWithAmountComponent);
