import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import ReactJson from "react-json-view";
import SelectUser from "../../base/SelectUser";
import { useUserQuestPointerProvider } from "../../../context/UserQuestPointerContext";
import SelectUserByUserId from "../../base/SelectUserByUserId";

const NodeDebugger: React.FC = () => {
  const { questPointers, hasError, isLoading, questPointerUserId, setQuestPointerUserId, questPointerDisplayName } =
    useUserQuestPointerProvider();

  const containerRef = useRef<HTMLDivElement>(null);

  const [scrollableHeight, setScrollableHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const containerRefHeight = containerRef.current?.offsetHeight ?? 0;

    setScrollableHeight(containerRefHeight);

    return () => setScrollableHeight(0);
  }, []);

  if (hasError) {
    return (
      <HStack p={4}>
        <Text color={"white"} casing={"uppercase"}>
          Error occurred loading quest pointers
        </Text>
      </HStack>
    );
  }

  return (
    <Stack p={2}>
      <SelectUser
        color={"white"}
        initialSearchQuery={questPointerDisplayName}
        value={questPointerUserId}
        setValue={setQuestPointerUserId}
      />

      <SelectUserByUserId color={"color"} value={questPointerUserId} setValue={setQuestPointerUserId} />

      <Stack ref={containerRef} flexGrow={1} position={"relative"}>
        <HStack p={4} position={"absolute"} top={0} right={0}>
          {isLoading && <Spinner size={"md"} color={"white"} />}
        </HStack>

        <Stack
          flexGrow={1}
          h={scrollableHeight}
          minW={"md"}
          sx={{
            "::-webkit-scrollbar": {
              width: 2,
            },
            "::-webkit-scrollbar-track": {
              bg: "mirage.900",
            },
            "::-webkit-scrollbar-thumb": {
              bg: "indigo.600",
            },
          }}
          overflowY={"auto"}
        >
          <ReactJson style={{ backgroundColor: "transparent" }} src={questPointers} theme={"google"} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(NodeDebugger);
