import { memo, useCallback } from "react";
import { FormControl, FormLabel, Stack, Text, Textarea } from "@chakra-ui/react";
import { NodeProps } from "reactflow";
import { useForm } from "react-hook-form";
import { DynamicLocation, Precedence } from "@worldwidewebb/quest-shared/dist/dynamic";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { NodeType } from "../../../models/nodeType";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectPrecedence from "../../base/SelectPrecedence";

function DynamicLocationNode(props: NodeProps<NodeType<DynamicLocation>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const description = nodeData?.description ?? "";
  const precedence = nodeData?.precedence ?? "low";

  const { getValues, setValue, register, handleSubmit } = useForm<DynamicLocation>({
    defaultValues: {
      description,
      precedence,
    },
    mode: "onBlur",
  });

  const { updateNodeData } = useUpdateNodeData<DynamicLocation>(nodeId);

  const handleUpdate = useCallback(
    ({ description, precedence }: DynamicLocation) => {
      updateNodeData({
        description,
        precedence,
        dynamicData: ["roomName", "x", "y", "radius"],
      });
    },
    [updateNodeData]
  );

  return (
    <BaseNodeWithChildren {...props}>
      <form className={"nodrag"} onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack p={2}>
          <SelectPrecedence
            color={color}
            value={getValues("precedence")}
            setValue={(value) => setValue("precedence", value as Precedence)}
          />

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Description
              </Text>
            </FormLabel>
            <Textarea
              color={color}
              borderColor={color}
              borderRadius={0}
              borderWidth={2}
              minH={"3xs"}
              {...register("description")}
            />
          </FormControl>
        </Stack>
      </form>
    </BaseNodeWithChildren>
  );
}

export default memo(DynamicLocationNode);
