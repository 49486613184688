import { AssetsApi } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getMusic(searchQuery?: string) {
  try {
    const { data: music } = await assetsApi.getAudioMetadatasForTypeByQuery("music", searchQuery);

    return music.map(({ filename }) => filename).sort((a, b) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
