import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { TbTextPlus } from "react-icons/tb";
import { insertText } from "../utils/insertText";

export const scribbleSetScaleStack: Command = {
  category: "transform",
  icon: <Icon as={TbTextPlus} />,
  name: "scale stack",
  tooltip: "tag with scaleStack effect",
  execute: (editorRef) => insertText(editorRef, "{{#scaleStack}}2{{/scaleStack}}"),
};
