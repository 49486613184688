import React, { useCallback } from "react";
import { ChakraProps } from "@chakra-ui/react";
import AsyncSelect from "./AsyncSelect";
import { AsyncSearchProvider } from "./AsyncSearchProvider";
import useMusic from "../../hooks/music/useMusic";

interface SelectMusicProps extends ChakraProps {
  value: string;
  setValue: (value: string) => void;
}

const SelectMusic: React.FC<SelectMusicProps> = ({ color, value, setValue, ...chakraProps }) => {
  const { isLoading, error, music = [] } = useMusic();

  const handleSearch = useCallback(
    async (searchQuery?: string): Promise<[string, string][]> => {
      return music
        .filter((filename) =>
          filename
            .toLowerCase()
            .trim()
            .includes(searchQuery?.toLowerCase().trim() ?? "")
        )
        .map((fileName) => [fileName, fileName]);
    },
    [music]
  );

  return (
    <AsyncSearchProvider onSearchAsync={handleSearch}>
      <AsyncSelect
        selectedValue={value}
        setSelectedValue={setValue}
        color={color}
        title={"Music"}
        isLoading={isLoading}
        error={error}
        {...chakraProps}
      />
    </AsyncSearchProvider>
  );
};

export default SelectMusic;
