import { useQuery } from "@tanstack/react-query";
import { ChunkFeature } from "@worldwidewebb/client-world";
import { getEntities } from "../../api/entities/entities";

export default function useEntities(worldId: string) {
  const {
    isLoading,
    isFetching,
    error,
    data: entities,
  } = useQuery<ChunkFeature[]>({
    queryKey: ["entity", worldId],
    queryFn: () => getEntities(worldId),
  });

  return {
    isLoading: isLoading || isFetching,
    error,
    entities,
  };
}
