import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, Input, Tag, TagProps, Tooltip } from "@chakra-ui/react";

interface TagEditForm {
  value: string;
}

interface TagEditProps extends TagProps {
  value: string;
  onUpdate?: (value: string) => void;
}

export const TagEdit: React.FC<TagEditProps> = ({ size = "lg", color = "white", value, onUpdate, ...tagProps }) => {
  const { reset, register, handleSubmit, setFocus, setValue } = useForm<TagEditForm>({
    mode: "onBlur",
  });

  const handleUpdate = useCallback(
    ({ value }: TagEditForm) => {
      if (onUpdate) {
        onUpdate(value);
      }

      setIsEditing(false);
    },
    [onUpdate]
  );

  const handleStartEditingConditionally = useCallback(({ detail: clickCount }: React.MouseEvent<HTMLElement>) => {
    if (clickCount !== 2) {
      return;
    }

    setIsEditing(true);
  }, []);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = useCallback(() => {
    setFocus("value");
  }, [setFocus]);

  const resetEditing = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (!isEditing) {
      resetEditing();
    } else {
      startEditing();
    }
  }, [isEditing]);

  useEffect(() => {
    setValue("value", value);
  }, [isEditing]);

  let inputSize = "sm";

  switch (size) {
    case "lg": {
      inputSize = "sm";
      break;
    }
    case "md": {
      inputSize = "xs";
      break;
    }
    case "sm": {
      inputSize = "xs";
      break;
    }
  }

  return (
    <form onSubmit={handleSubmit(handleUpdate)}>
      {isEditing ? (
        <FormControl>
          <Input
            id={"value"}
            isRequired
            {...register("value")}
            onBlur={handleSubmit(handleUpdate)}
            size={inputSize}
            color={color}
            {...tagProps}
          />
        </FormControl>
      ) : (
        <Tooltip label={"double click to edit"}>
          <Tag size={size} color={color} onClick={handleStartEditingConditionally} {...tagProps}>
            {value.toUpperCase()}
          </Tag>
        </Tooltip>
      )}
    </form>
  );
};
