import { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeType } from "../../../models/nodeType";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import SelectAllegiance from "../../base/SelectAllegiance";
import { AllegianceWithAmount } from "@worldwidewebb/quest-shared/dist/allegiance";

function AllegianceAndAmountNode(props: NodeProps<NodeType<AllegianceWithAmount>>) {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const allegianceId = nodeData?.allegianceId ?? "";
  const allegianceAmount = nodeData?.allegianceAmount ?? 0;

  const { register, getValues, setValue, handleSubmit } = useForm<AllegianceWithAmount>({
    defaultValues: useMemo(
      () => ({
        allegianceId,
        allegianceAmount,
      }),
      [allegianceId, allegianceAmount]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData(nodeId);

  return (
    <BaseNodeWithChildren {...props}>
      <form
        className={"nodrag"}
        onSubmit={handleSubmit(updateNodeData)}
        onBlur={handleSubmit(updateNodeData)}
        onChange={handleSubmit(updateNodeData)}
      >
        <Stack p={2}>
          <SelectAllegiance
            color={color}
            value={getValues("allegianceId")}
            setValue={(value) => setValue("allegianceId", value)}
          />

          <FormControl>
            <FormLabel>
              <Text color={color} casing={"uppercase"}>
                Allegiance Amount
              </Text>
            </FormLabel>
            <NumberInput step={1} defaultValue={0} min={0}>
              <NumberInputField color={color} {...register("allegianceAmount")} />
              <NumberInputStepper>
                <NumberIncrementStepper color={color} />
                <NumberDecrementStepper color={color} />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Stack>
      </form>
    </BaseNodeWithChildren>
  );
}

export default memo(AllegianceAndAmountNode);
