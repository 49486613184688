import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ChakraProps,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { MdClose, MdCopyAll } from "react-icons/md";
import { useStoreDispatch } from "../../store/hooks";
import { QuestNotification, removeQuestNotification } from "../../store/quests";

interface QuestNotificationComponentProps extends ChakraProps {
  questNotification: QuestNotification;
}

function QuestNotificationComponent({
  questNotification: {
    questId,
    questDisplayName,
    message: { time, text, type },
  },
}: QuestNotificationComponentProps) {
  const dispatch = useStoreDispatch();

  function handleRemoveQuestNotification() {
    dispatch(removeQuestNotification(questId));
  }

  function handleCopyQuestIdToClipboard() {
    navigator.clipboard.writeText(questId);
  }

  return (
    <Card>
      <CardHeader p={4}>
        <Stack>
          <Heading size={"sm"}>
            <HStack justifyContent={"space-between"}>
              <Text color={"white"} casing={"uppercase"}>
                {questDisplayName}
              </Text>

              <HStack>
                <Tooltip label={"copy ID"}>
                  <IconButton
                    aria-label={"copy ID"}
                    color={"white"}
                    icon={<Icon as={MdCopyAll} position={"absolute"} />}
                    variant={"ghost"}
                    isRound={true}
                    size={"xs"}
                    onClick={handleCopyQuestIdToClipboard}
                  />
                </Tooltip>

                <Tooltip label={"remove"}>
                  <IconButton
                    aria-label={"close"}
                    color={"white"}
                    icon={<Icon as={MdClose} position={"absolute"} />}
                    variant={"ghost"}
                    isRound={true}
                    size={"xs"}
                    onClick={handleRemoveQuestNotification}
                  />
                </Tooltip>
              </HStack>
            </HStack>
          </Heading>
        </Stack>
      </CardHeader>

      <Divider />

      <CardBody p={0}>
        <Alert status={type}>
          <AlertIcon />
          <AlertTitle>{text}</AlertTitle>
        </Alert>
      </CardBody>

      <Divider />

      <CardFooter p={2}>
        <Text color={"white"} casing={"uppercase"} fontSize={"xs"}>
          received at {time}
        </Text>
      </CardFooter>
    </Card>
  );
}

export default QuestNotificationComponent;
