import { QuestSubgraphWithId } from "@worldwidewebb/client-quests";
import { NodeType } from "@worldwidewebb/quest-shared/dist/editor/nodeType";
import { useCallback } from "react";
import { useLoaderData } from "react-router";
import { Node, useReactFlow, XYPosition } from "reactflow";
import { ulid } from "ulid";
import { useReactFlowProvider } from "../../../export/reactflow";
import { useCopyAndPaste } from "../../../hooks/useCopyAndPaste";
import useInitialNodeTypes from "../../../hooks/useInitialNodeTypes";
import useNodeTypeNameLookup from "../../../hooks/useNodeTypeNameLookup";
import { EdgeType } from "../../../models/edgeType";
import { createHandleId } from "../../../utils/handleId";

// TODO: VERIFICATION PENDING

export default function useCreateQuestNode() {
  const { setNodes } = useReactFlow<NodeType, EdgeType>();

  const { subgraphs } = useLoaderData() as {
    subgraphs: QuestSubgraphWithId[];
  };

  const initialNodeTypes = useInitialNodeTypes(subgraphs);

  const { getLatestType } = useNodeTypeNameLookup();
  const { paste } = useCopyAndPaste();

  const { screenToFlowPosition } = useReactFlow<NodeType, EdgeType>();

  const createQuestNode = useCallback(
    (nodeName: string, { x, y }: XYPosition) => {
      const nodeType = initialNodeTypes.find((nodeType) => nodeType.nodeName === nodeName);

      if (nodeType == null) {
        return;
      }

      nodeType.targetHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId ??= createHandleId(nodeHandle);
      });

      nodeType.sourceHandles?.forEach((nodeHandle) => {
        nodeHandle.handleId ??= createHandleId(nodeHandle);
      });

      if (nodeType == null) {
        throw new Error("node type not found");
      }

      const type = getLatestType(nodeType, nodeName);

      const data = structuredClone(nodeType);

      const node: Node = {
        id: ulid(),
        type,
        data,
        position: screenToFlowPosition({ x, y }),
      };

      const nodes = [node];

      paste(nodes).then(({ nodes }) => {
        const [createdNode] = nodes;

        setNodes((nodes: Node[]) => [...nodes, createdNode]);
      });
    },
    [initialNodeTypes, screenToFlowPosition]
  );

  return {
    createQuestNode,
  };
}
