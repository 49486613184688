import { Status as QuestStatus } from "@worldwidewebb/client-quests";
import { QuestEdge, QuestNode } from "../store/quests";

export interface FilterOptions {
  includeDraft: boolean;
  includeDev: boolean;
  includeLive: boolean;
}

export function filterNodesAndEdges(
  nodes: QuestNode[],
  edges: QuestEdge[],
  { includeDraft, includeDev, includeLive }: FilterOptions
) {
  const selectedStatuses: QuestStatus[] = [];

  if (includeDraft) {
    selectedStatuses.push("draft");
  }

  if (includeDev) {
    selectedStatuses.push("dev");
  }

  if (includeLive) {
    selectedStatuses.push("live");
  }

  if (selectedStatuses.length === Object.keys(QuestStatus).length) {
    return { filteredNodes: nodes, filteredEdges: edges };
  }

  const nodeIds = nodes.map(({ id }) => id);
  const rootNodeIds = nodeIds.filter((nodeId) => !edges.map(({ target }) => target).includes(nodeId));

  const validNodeIds: Set<string> = new Set();

  rootNodeIds.forEach((rootNodeId) => {
    const rootNode = nodes.find(({ id }) => id === rootNodeId);

    if (rootNode && selectedStatuses.includes(rootNode.data.nodeData.questStatus)) {
      validNodeIds.add(rootNodeId);
    }

    const visited = new Set<string>();

    traverseAndCollectSelected(rootNodeId, nodes, edges, validNodeIds, visited, selectedStatuses);
  });

  const connectedNodes = new Set<string>();

  edges.forEach(({ source, target }) => {
    if (validNodeIds.has(source) && validNodeIds.has(target)) {
      connectedNodes.add(source);
      connectedNodes.add(target);
    }
  });

  rootNodeIds.forEach((rootNodeId) => {
    if (validNodeIds.has(rootNodeId)) {
      connectedNodes.add(rootNodeId);
    }
  });

  const filteredNodes = nodes.filter(({ id }) => connectedNodes.has(id));

  const filteredEdges = edges.filter(
    (edge) => connectedNodes.has(edge.source) && connectedNodes.has(edge.target) && isValidEdge(edge, filteredNodes)
  );

  return { filteredNodes, filteredEdges };
}

function traverseAndCollectSelected(
  nodeId: string,
  nodes: QuestNode[],
  edges: QuestEdge[],
  validNodeIds: Set<string>,
  visited: Set<string>,
  selectedStatuses: QuestStatus[]
) {
  if (visited.has(nodeId)) {
    return;
  }

  visited.add(nodeId);

  const currentNode = nodes.find(({ id }) => id === nodeId);

  if (currentNode == null) {
    return;
  }

  const {
    data: {
      nodeData: { questStatus },
    },
  } = currentNode;

  const isStatusSelected = selectedStatuses.includes(questStatus);

  if (!isStatusSelected) {
    return;
  }

  validNodeIds.add(nodeId);

  const connectedEdges = edges.filter(({ source }) => source === nodeId);

  connectedEdges.forEach(({ target }) =>
    traverseAndCollectSelected(target, nodes, edges, validNodeIds, visited, selectedStatuses)
  );
}

function isValidEdge({ source, sourceHandle, target, targetHandle }: QuestEdge, nodes: QuestNode[]): boolean {
  const sourceNode = nodes.find(({ id }) => id === source);
  const targetNode = nodes.find(({ id }) => id === target);

  if (sourceNode == null) {
    return false;
  }

  if (sourceHandle == null) {
    return false;
  }

  if (targetNode == null) {
    return false;
  }

  if (targetHandle == null) {
    return false;
  }

  const {
    data: { sourceHandles = [] },
  } = sourceNode;

  const {
    data: { targetHandles = [] },
  } = targetNode;

  const sourceHandleValid = sourceHandles.some(({ handleId }) => handleId === sourceHandle);
  const targetHandleValid = targetHandles.some(({ handleId }) => handleId === targetHandle);

  return sourceHandleValid && targetHandleValid;
}
