import React, { memo, useCallback } from "react";
import { Stack } from "@chakra-ui/react";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { NodeData, NodeType } from "../../../models/nodeType";
import SelectDoor from "../../base/SelectDoor";
import { BaseNodeWithChildren } from "./base/BaseNode";
import { InputError } from "../../base/InputError";

export interface DoorData extends NodeData {
  doorId: string;
  worldId: string;
}

const DoorNode: React.FC<NodeProps<NodeType<DoorData>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData: door },
  } = props;

  const { updateNodeData } = useUpdateNodeData<DoorData>(nodeId);

  const handleUpdateDoor = useCallback(
    ({ doorId, worldId }: DoorData) => {
      updateNodeData({
        doorId,
        worldId,
      });
    },
    [updateNodeData]
  );

  const doorId = door?.doorId;

  return (
    <BaseNodeWithChildren {...props}>
      <Stack p={2}>
        <SelectDoor color={color} value={door} setValue={handleUpdateDoor} />

        {!doorId && <InputError errorMessage="Please select a door" />}
      </Stack>
    </BaseNodeWithChildren>
  );
};

export default memo(DoorNode);
