import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { NodeProps } from "reactflow";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { ObservableEvent } from "../../../models/api/observableEvent";
import { NodeType } from "../../../models/nodeType";
import SelectObservableEvent from "../../base/SelectObservableEvent";
import { BaseNodeWithChildren } from "./base/BaseNode";

interface PlayerActionCount extends ObservableEvent {
  requiredCount: number;
}

const PlayerActionCountNode: React.FC<NodeProps<NodeType<PlayerActionCount>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const requiredCount = nodeData?.requiredCount ?? 1;

  const { updateNodeData } = useUpdateNodeData<PlayerActionCount>(nodeId);

  const handleUpdateObservableEvent = useCallback(
    (observableEvent: ObservableEvent) => {
      updateNodeData(observableEvent);
    },
    [updateNodeData]
  );

  const handleUpdateRequiredCount = useCallback(
    ({ requiredCount }: Omit<PlayerActionCount, keyof ObservableEvent>) => {
      updateNodeData({ requiredCount });
    },
    [updateNodeData]
  );

  return (
    <BaseNodeWithChildren {...props}>
      <Stack p={2}>
        <SelectObservableEvent
          color={color}
          observableEvent={nodeData}
          setObservableEvent={handleUpdateObservableEvent}
        />

        <FormControl>
          <FormLabel>
            <Text color={color} casing={"uppercase"}>
              Required Count
            </Text>
          </FormLabel>

          <NumberInput
            step={1}
            defaultValue={1}
            min={1}
            value={requiredCount}
            onChange={(value) => handleUpdateRequiredCount({ requiredCount: Number(value) })}
          >
            <NumberInputField color={color} />
            <NumberInputStepper>
              <NumberIncrementStepper color={color} />
              <NumberDecrementStepper color={color} />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </Stack>
    </BaseNodeWithChildren>
  );
};

export default memo(PlayerActionCountNode);
