import React from "react";
import {
  Center,
  Flex,
  Heading,
  Icon,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CiWarning } from "react-icons/ci";
import { LiaHandPaperSolid, LiaHandPointer } from "react-icons/lia";

interface ControlSchemeInfoProps {
  isOpen: boolean;
  onClose: () => void;
}

const ControlSchemeInfo: React.FC<ControlSchemeInfoProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />

      <ModalContent bg={"gray.800"} borderWidth={2} borderColor={"indigo.600"}>
        <ModalHeader bg={"indigo.600"}>
          <Heading size={"md"}>
            <Text color={"white"}>Controls</Text>
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack py={2}>
            <Center>
              <Flex alignItems={"center"} gap={1}>
                <Icon color={"yellow.200"} as={CiWarning} />
                <Text color={"yellow.200"} casing={"uppercase"}>
                  This list is not exhaustive
                </Text>
              </Flex>
            </Center>

            <Stack>
              <Heading size={"sm"} display={"flex"} alignItems={"center"} gap={1}>
                <Icon as={LiaHandPointer} />
                <Text color={"white"} casing={"uppercase"}>
                  Primary
                </Text>
              </Heading>

              <TableContainer>
                <Table variant={"simple"}>
                  <TableCaption>Provides controls for the primary control scheme</TableCaption>

                  <Thead>
                    <Tr>
                      <Th>Control</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>left click</Kbd> + <Kbd>drag</Kbd>
                        </Text>
                      </Td>
                      <Td>Select a group of nodes</Td>
                    </Tr>
                    <Tr>
                      <Td />
                      <Td>Move selected group of nodes</Td>
                    </Tr>
                    <Tr>
                      <Td />
                      <Td>Move hovered over node</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>shift</Kbd> + <Kbd>left click</Kbd> + <Kbd>drag</Kbd>
                        </Text>
                      </Td>
                      <Td>Pan around the canvas</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>middle click</Kbd> + <Kbd>drag</Kbd>
                        </Text>
                      </Td>
                      <Td>Pan around the canvas</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>scroll</Kbd>
                        </Text>
                      </Td>
                      <Td>Zoom in and out</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>

            <Stack>
              <Heading size={"sm"} display={"flex"} alignItems={"center"} gap={1}>
                <Icon as={LiaHandPaperSolid} />
                <Text color={"white"} casing={"uppercase"}>
                  Secondary (Trackpad)
                </Text>
              </Heading>

              <TableContainer>
                <Table variant={"simple"}>
                  <TableCaption>Provides controls for the secondary control scheme</TableCaption>

                  <Thead>
                    <Tr>
                      <Th>Control</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>left click</Kbd> + <Kbd>drag</Kbd>
                        </Text>
                      </Td>
                      <Td>Pan around the canvas</Td>
                    </Tr>
                    <Tr>
                      <Td />
                      <Td>Move selected group of nodes</Td>
                    </Tr>
                    <Tr>
                      <Td />
                      <Td>Move hovered over node</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>Control</Kbd> + <Kbd>left click</Kbd> + <Kbd>drag</Kbd>
                        </Text>
                      </Td>
                      <Td>Select a group of nodes</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>middle click</Kbd> + <Kbd>drag</Kbd>
                        </Text>
                      </Td>
                      <Td>Pan around the canvas</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>scroll</Kbd>
                        </Text>
                      </Td>
                      <Td>Pan up and down</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>shift</Kbd> + <Kbd>scroll</Kbd>
                        </Text>
                      </Td>
                      <Td>Pan left and right</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Text color={"white"} casing={"uppercase"}>
                          <Kbd>control</Kbd> + <Kbd>scroll</Kbd>
                        </Text>
                      </Td>
                      <Td>Zoom in and out</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ControlSchemeInfo;
