import { useQuery } from "@tanstack/react-query";
import { ItemIntrinsicWithImage, Sprite } from "@worldwidewebb/client-assets";
import { getItem } from "../../api/items/items";
import { getSprite } from "../../api/sprites/sprites";

export default function useItem(itemName: string) {
  const {
    isLoading: isLoadingItem,
    isFetching: isFetchingItem,
    error: errorItem,
    data: item,
  } = useQuery<ItemIntrinsicWithImage>({
    queryKey: ["item", itemName],
    queryFn: () => getItem(itemName),
  });

  const spriteId = item?.spriteId;

  const {
    isLoading: isLoadingSprite,
    isFetching: isFetchingSprite,
    error: errorSprite,
    data: sprite,
  } = useQuery<Sprite | null>({
    queryKey: ["sprite", spriteId],
    queryFn: () => getSprite(spriteId),
    enabled: !!spriteId,
  });

  const isLoading = isLoadingItem || isLoadingSprite;
  const isFetching = isFetchingItem || isFetchingSprite;
  const error = errorItem || errorSprite;

  return {
    isLoading: isLoading || isFetching,
    error,
    item,
    sprite,
  };
}
