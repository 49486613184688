import { ComponentType } from "react";
import { EdgeProps } from "reactflow";
import { StartEdge } from "./StartEdge";
import { EndEdge } from "./EndEdge";
import { DataEdge } from "./DataEdge";
import { HookEdge } from "./HookEdge";
import { FlowEdge } from "./FlowEdge";
import { QuestIdEdge } from "./QuestIdEdge";
import { TaskIdEdge } from "./TaskIdEdge";
import { VendorIdEdge } from "./VendorIdEdge";
import { CraftingStationIdEdge } from "./CraftingStationIdEdge";

export const edgeTypes: Record<string, ComponentType<EdgeProps>> = {
  HookEdge,
  StartEdge,
  EndEdge,
  FlowEdge,
  DataEdge,
  QuestIdEdge,
  TaskIdEdge,
  VendorIdEdge,
  CraftingStationIdEdge,
};

export const edgeTypeDictionary: Record<string, ComponentType<EdgeProps>> = Object.fromEntries(
  Object.entries(edgeTypes).map(([edgeTypeName, edgeType]) => [edgeTypeName, edgeType])
);
