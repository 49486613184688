import { useCallback, useState } from "react";
import { Edge, getConnectedEdges, Node } from "reactflow";
import { ulid } from "ulid";
import { NodeType } from "../models/nodeType";
import { createHandleId } from "../utils/handleId";
import { DialogSpeakerPassageWithId } from "@worldwidewebb/shared-messages/quests";
import { EdgeType } from "../models/edgeType";
import { QuestData } from "../models/api/quest";
import { StartConditionWithId } from "../store/quests";

async function copyToClipBoard(nodes: Node<NodeType>[], edges: Edge<EdgeType>[]) {
  try {
    await navigator.clipboard.writeText(
      JSON.stringify({
        nodes,
        edges,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

async function pasteFromClipboard(): Promise<Partial<QuestData>> {
  try {
    const questData = JSON.parse(await navigator.clipboard.readText());

    if (!questData.nodes) {
      throw new Error("nodes undefined");
    }

    if (!questData.edges) {
      throw new Error("edges undefined");
    }

    return questData;
  } catch (error) {
    return {
      nodes: undefined,
      edges: undefined,
    };
  }
}

export const useCopyAndPaste = () => {
  const [pasteCount, setPasteCount] = useState<number>(0);

  const copy = useCallback(
    async (nodes: Node<NodeType>[], edges: Edge[]) => {
      const selectedNodes = nodes.filter(({ selected }) => selected);
      const selectedEdges = getConnectedEdges(selectedNodes, edges).filter(({ source, target }) => {
        const isExternalSource = selectedNodes.every(({ id }) => id !== source);
        const isExternalTarget = selectedNodes.every(({ id }) => id !== target);

        return !(isExternalSource || isExternalTarget);
      });

      await copyToClipBoard(selectedNodes, selectedEdges);

      setPasteCount(0);
    },
    [getConnectedEdges]
  );

  const paste = useCallback(
    async (nodes?: Node<NodeType>[], edges?: Edge[], isSubgraph?: boolean) => {
      const { nodes: clipboardNodes, edges: clipboardEdges } = await pasteFromClipboard();

      const nodesToDuplicate = nodes ?? clipboardNodes ?? [];
      const edgesToDuplicate = edges ?? clipboardEdges ?? [];

      if (nodesToDuplicate.length === 0 && edgesToDuplicate.length === 0) {
        return {
          nodes: [],
          edges: [],
          oldToNewNodeHandleIds: {},
        };
      }

      const oldToNewNodeIds: Record<string, string> = {};
      const oldToNewNodeHandleIds: Record<string, string> = {};
      const pasteOffset: number = 100 * (pasteCount + 1);

      const newNodes = nodesToDuplicate.map((node) => {
        const oldNodeId = node.id;
        const newNodeId = ulid();

        oldToNewNodeIds[oldNodeId] = newNodeId;

        return {
          ...node,
          data: {
            ...structuredClone(node.data),
            sourceHandles: node.data.sourceHandles?.map((sourceHandle) => {
              if (sourceHandle.handleId == null) {
                return;
              }

              const newHandleId = createHandleId(sourceHandle);

              oldToNewNodeHandleIds[sourceHandle.handleId] = newHandleId;

              return {
                ...sourceHandle,
                handleId: newHandleId,
              };
            }),
            targetHandles: node.data.targetHandles?.map((targetHandle) => {
              if (targetHandle.handleId == null) {
                return;
              }

              const newHandleId = createHandleId(targetHandle);

              oldToNewNodeHandleIds[targetHandle.handleId] = newHandleId;

              return {
                ...targetHandle,
                handleId: newHandleId,
              };
            }),
          },
          id: newNodeId,
          selected: !isSubgraph,
          position: {
            x: node.position.x + pasteOffset,
            y: node.position.y + pasteOffset,
          },
        };
      });

      const newEdges = edgesToDuplicate.map((edge) => {
        const newSource = oldToNewNodeIds[edge.source];
        const newSourceHandle = edge.sourceHandle ? oldToNewNodeHandleIds[edge.sourceHandle] : undefined;
        const newTarget = oldToNewNodeIds[edge.target];
        const newTargetHandle = edge.targetHandle ? oldToNewNodeHandleIds[edge.targetHandle] : undefined;

        return {
          ...edge,
          id: ulid(),
          selected: !isSubgraph,
          source: newSource,
          sourceHandle: newSourceHandle,
          target: newTarget,
          targetHandle: newTargetHandle,
        };
      });

      // TODO: abstract custom behaviours
      await Promise.all(
        newNodes.map(async ({ data: { nodeName, nodeClass, nodeCategory, nodeData } }) => {
          if (nodeName === "dialog") {
            nodeData.speakerPassages = nodeData.speakerPassages.map((speakerPassage: DialogSpeakerPassageWithId) => ({
              ...speakerPassage,
              passageId: ulid(),
            }));
          }

          if (nodeClass === "start") {
            nodeData.allStartConditions = nodeData.allStartConditions.map((startCondition: StartConditionWithId) => ({
              ...startCondition,
              id: ulid(),
            }));

            nodeData.anyStartConditions = nodeData.anyStartConditions.map((startCondition: StartConditionWithId) => ({
              ...startCondition,
              id: ulid(),
            }));
          }

          if (nodeCategory === "Subgraph" || nodeCategory === "Subgraph Template") {
            const {
              nodes,
              edges,
              oldToNewNodeHandleIds: oldToNewNodeHandleIdsSubgraph,
            } = await paste(nodeData.nodes, nodeData.edges, true);

            // align internal and external handle IDs
            nodeData.nodes = nodes.map((node: Node<NodeType>) => {
              const {
                data: { sourceHandles, targetHandles },
              } = node;

              node.data.sourceHandles = sourceHandles?.map((sourceHandle) => {
                const [oldSourceHandleId] =
                  Object.entries(oldToNewNodeHandleIdsSubgraph).find(
                    ([_, newHandleId]) => newHandleId === sourceHandle.handleId
                  ) ?? [];

                if (oldSourceHandleId == null) {
                  return sourceHandle;
                }

                if (oldToNewNodeHandleIds[oldSourceHandleId] == null) {
                  return sourceHandle;
                }

                // set internal handleId to current external handleId to preserve the link
                // nothing else needs to be done because there are no edges internally connected to this handle
                sourceHandle.handleId = oldToNewNodeHandleIds[oldSourceHandleId];

                return sourceHandle;
              });

              node.data.targetHandles = targetHandles?.map((targetHandle) => {
                const [oldTargetHandleId] =
                  Object.entries(oldToNewNodeHandleIdsSubgraph).find(
                    ([_, newHandleId]) => newHandleId === targetHandle.handleId
                  ) ?? [];

                if (oldTargetHandleId == null) {
                  return targetHandle;
                }

                if (oldToNewNodeHandleIds[oldTargetHandleId] == null) {
                  return targetHandle;
                }

                // set internal handleId to current external handleId to preserve the link
                // nothing else needs to be done because there are no edges internally connected to this handle
                targetHandle.handleId = oldToNewNodeHandleIds[oldTargetHandleId];

                return targetHandle;
              });

              return node;
            });

            nodeData.edges = edges;
          }
        })
      );

      if (!isSubgraph) {
        setPasteCount((pasteCount) => pasteCount + 1);
      }

      return {
        nodes: newNodes,
        edges: newEdges,
        oldToNewNodeHandleIds,
      };
    },
    [pasteCount]
  );

  const createDuplicates = useCallback(
    async (nodes: Node<NodeType>[], edges: Edge[]) => {
      const { nodes: duplicatedNodes, edges: duplicatedEdges } = await paste(nodes, edges);

      return {
        nodes: duplicatedNodes.map((node) => ({ ...node, selected: false })),
        edges: duplicatedEdges.map((edge) => ({ ...edge, selected: false })),
      };
    },
    [paste]
  );

  return {
    copy,
    paste,
    createDuplicates,
  };
};
