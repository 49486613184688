import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteQuestById as deleteQuestAsync } from "../../api/quests/quests";

export default function useDeleteQuest() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isPending: isDeleting, mutate: deleteQuest } = useMutation({
    mutationFn: (questId: string) => deleteQuestAsync(questId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["quest"],
      });

      toast({ title: "Quest delete successful", status: "success" });
    },
    onError: (error) => {
      toast({ title: "Quest delete unsuccessful", status: "error", description: error.message });
    },
  });

  return {
    isDeleting,
    deleteQuest,
  };
}
