import { Command } from "./index";
import { Icon } from "@chakra-ui/react";
import { MdUndo } from "react-icons/md";
import { undo as handleUndo } from "@codemirror/commands";

export const undo: Command = {
  category: "general",
  icon: <Icon as={MdUndo} />,
  name: "undo",
  tooltip: "undo",
  execute: ({ view }) => view && handleUndo(view),
};
