import { QuestEditorApi, QuestEditorFolder, QuestEditorFolderWithId } from "@worldwidewebb/client-quests";
import { axiosInstance, buildQuestClientBasePath } from "..";

const questEditorApi = new QuestEditorApi(undefined, buildQuestClientBasePath(), axiosInstance);

export async function getQuestFolders(): Promise<QuestEditorFolderWithId[]> {
  try {
    const { data: folders } = await questEditorApi.getFolders();

    return folders.sort(({ displayName: a }, { displayName: b }) => (a < b ? -1 : a > b ? 1 : 0));
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createQuestFolder(questEditorFolder: QuestEditorFolderWithId): Promise<QuestEditorFolderWithId> {
  try {
    const { data: folder } = await questEditorApi.createFolder(questEditorFolder);

    return folder;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateQuestFolder(
  questEditorFolderId: string,
  questEditorFolder: QuestEditorFolder
): Promise<QuestEditorFolderWithId> {
  try {
    const { data: folder } = await questEditorApi.updateFolder(questEditorFolderId, questEditorFolder);

    return folder;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteQuestFolder(questEditorFolderId: string): Promise<void> {
  try {
    await questEditorApi.deleteFolder(questEditorFolderId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
