import React, { memo, useMemo } from "react";
import { NodeProps } from "reactflow";
import { NodeData, NodeType } from "../../../models/nodeType";
import { useForm } from "react-hook-form";
import { FlowNodeWithChildren } from "./FlowNode";
import { useUpdateNodeData } from "../../../hooks/useUpdateNodeData";
import { Checkbox, FormControl, FormLabel, Input, Stack, Text } from "@chakra-ui/react";

interface GiveItem extends NodeData {
  notifyPlayer: boolean;
  wipeIfFlagIsSet: string;
  wipeIfFlagIsNotSet: string;
}

const GiveItemNode: React.FC<NodeProps<NodeType<GiveItem>>> = (props) => {
  const {
    id: nodeId,
    data: { color, nodeData },
  } = props;

  const notifyPlayer = nodeData?.notifyPlayer ?? true;
  const wipeIfFlagIsSet = nodeData?.wipeIfFlagIsSet ?? "";
  const wipeIfFlagIsNotSet = nodeData?.wipeIfFlagIsNotSet ?? "";

  const { register, handleSubmit } = useForm<GiveItem>({
    defaultValues: useMemo(
      () => ({
        notifyPlayer,
        wipeIfFlagIsSet,
        wipeIfFlagIsNotSet,
      }),
      [notifyPlayer, wipeIfFlagIsSet, wipeIfFlagIsNotSet]
    ),
    mode: "onChange",
  });

  const { updateNodeData } = useUpdateNodeData<GiveItem>(nodeId);

  return (
    <>
      <FlowNodeWithChildren {...props}>
        <form className={"nodrag"} onSubmit={handleSubmit(updateNodeData)} onBlur={handleSubmit(updateNodeData)}>
          <Stack>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Notify Player
                </Text>
              </FormLabel>
              <Checkbox color={color} {...register("notifyPlayer")} />
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Wipe if flag is set
                </Text>
              </FormLabel>
              <Input color={color} {...register("wipeIfFlagIsSet")} />
            </FormControl>
            <FormControl>
              <FormLabel>
                <Text casing={"uppercase"} color={color}>
                  Wipe if flag is not set
                </Text>
              </FormLabel>
              <Input color={color} {...register("wipeIfFlagIsNotSet")} />
            </FormControl>
          </Stack>
        </form>
      </FlowNodeWithChildren>
    </>
  );
};

export default memo(GiveItemNode);
