import { AssetsApi, Vendor } from "@worldwidewebb/client-assets";
import { axiosInstance, buildApiClientBasePath } from "..";

const assetsApi = new AssetsApi(undefined, buildApiClientBasePath("assets"), axiosInstance);

export async function getVendors(searchQuery?: string): Promise<Vendor[]> {
  try {
    const { data: vendors } = await assetsApi.getVendorsByQuery(searchQuery);

    return vendors.sort(({ vendorId: a }, { vendorId: b }) => a.localeCompare(b));
  } catch (error) {
    console.error(error);
    throw error;
  }
}
